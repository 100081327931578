import Cookies from "js-cookie";
import { BASE_URL } from "../constants";
import localstorage, { localstorageGetItem, } from "@warda/library-ui/localstorage";
import { closeWebSocket } from "../components/webSocket";
import { PRODUCT_ID } from "../constantsKey";
import { AUTHORIZATION_TOKEN, GROUP_ID, JWT_KEY, LOGIN_DATE, REFRESH_TOKEN, TENANT_ID, USER_ID, } from "../constantsLocalStorage";
import { getPathCurrentUrl, goToBaseUrl, } from "@warda/library-ui/auth/Login";
const removeAllCookies = () => {
    Cookies.remove(REFRESH_TOKEN, {
        secure: true,
    });
    Cookies.remove(AUTHORIZATION_TOKEN, {
        secure: true,
        domain: ".wardacloud.com",
    });
};
const setCookieAuthToken = (idToken, expiresIn) => {
    Cookies.set(AUTHORIZATION_TOKEN, idToken, {
        expires: expiresIn / (60 * 60 * 24),
        secure: true,
        domain: ".wardacloud.com",
    });
};
export const requestTokenWithRefreshToken = async () => {
    const body = JSON.stringify({
        refreshToken: Cookies.get(REFRESH_TOKEN),
        applicationId: "seecommerce",
        userId: localstorage.getItem(USER_ID),
        groupId: localstorage.getItem(GROUP_ID),
        tenantId: localstorage.getItem(TENANT_ID),
    });
    const response = await fetch(`${BASE_URL}/users/token/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
    });
    const { status } = response;
    if (status >= 400)
        return { status, response };
    const res = await response.json();
    const { idToken, expiresIn } = res;
    setCookieAuthToken(idToken, expiresIn);
    return { status, response };
};
export const fetchCookieJwtWithRefreshToken = async ({ url, method = "GET", jsonBody = {}, }) => {
    try {
        const options = {
            credentials: "include",
            method,
            headers: { "Content-Type": "application/json" },
        };
        if (method !== "GET" && url !== "HEAD") {
            options.body = JSON.stringify(jsonBody);
        }
        const response = await fetch(url, options);
        const { status } = response;
        if (status === 401) {
            const refToken = await requestTokenWithRefreshToken();
            if (refToken.status >= 400)
                throw refToken;
            const response = await fetch(url, options);
            const { status } = response;
            if (status >= 400)
                throw { status, response };
            return await response.json();
        }
        if (status >= 400)
            throw { status, response };
        return await response.json();
    }
    catch (err) {
        throw err;
    }
};
export const onLogin = async ({ tenantId, username, password, }) => {
    const res = await fetch(process.env.BASE_URL_GATEWAY + "/users/token/user", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            email: username,
            password,
            applicationId: PRODUCT_ID,
            tenantId,
        }),
    });
    const status = res.status;
    const { idToken, refreshToken, expiresIn, error, code } = await res.json();
    if (!error) {
        setCookieAuthToken(idToken, expiresIn);
        Cookies.set(REFRESH_TOKEN, refreshToken, {
            expires: 0.5,
            secure: true,
        });
        location.reload();
    }
    return { status, errorCode: code, errorText: error };
};
export const onChoosePassword = async ({ authTmpCode, authGroupId, username, password, }) => {
    let url;
    let jsonBody;
    if (getPathCurrentUrl() === "choosepassword") {
        url = process.env.BASE_URL_GATEWAY + "/users/token/user/activate";
        jsonBody = {
            email: username,
            temporaryPassword: authTmpCode,
            newPassword: password,
            groupId: authGroupId,
            applicationId: PRODUCT_ID,
        };
    }
    else {
        url = process.env.BASE_URL_GATEWAY + "/users/confirmForgotPassword";
        jsonBody = {
            email: username,
            confirmationCode: authTmpCode,
            newPassword: password,
            groupId: authGroupId,
            applicationId: PRODUCT_ID,
        };
    }
    const res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(jsonBody),
    });
    const status = res.status;
    const { error, code } = await res.json();
    return { status, errorCode: code, errorText: error };
};
export const onDemandPassword = async ({ tenantId, username, }) => {
    const url = process.env.BASE_URL_GATEWAY + "/users/forgotPassword";
    const res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            email: username,
            tenantId,
            applicationId: PRODUCT_ID,
        }),
    });
    const status = res.status;
    const { error, code } = await res.json();
    return { status, errorCode: code, errorText: error };
};
export const onLogout = async () => {
    await fetch(process.env.BASE_URL_GATEWAY + "/users/token/user/logout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            applicationId: PRODUCT_ID,
            refreshToken: Cookies.get(REFRESH_TOKEN) || undefined,
            groupId: localstorageGetItem(GROUP_ID),
        }),
    });
    removeAllCookies();
    removeUserData();
    closeWebSocket();
    console.log("[logout]");
    goToBaseUrl();
};
const removeUserData = () => {
    localstorage.removeItem(USER_ID);
    localstorage.removeItem(GROUP_ID);
    localstorage.removeItem(TENANT_ID);
    localstorage.removeItem(LOGIN_DATE);
    localstorage.removeItem(JWT_KEY);
};
export const saveUserData = (u) => {
    localstorage.setItem(USER_ID, u.sub);
    localstorage.setItem(GROUP_ID, u.groupId);
    localstorage.setItem(TENANT_ID, u.tenantId);
    if (!localstorage.getItem(LOGIN_DATE)) {
        localstorage.setItem(LOGIN_DATE, new Date().getTime());
    }
};
export const switchTenant = async (newTenant) => {
    const res = await fetch(`${BASE_URL}/users/user/tenant/switch`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            refreshToken: Cookies.get(REFRESH_TOKEN),
            tenantId: newTenant,
            applicationId: "seecommerce",
        }),
    });
    const resJson = await res.json();
    const { idToken, expiresIn } = resJson;
    setCookieAuthToken(idToken, expiresIn);
    localstorage.setItem(TENANT_ID, newTenant);
    return resJson;
};
