import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Btn from "@warda/library-ui/core/Btn";
import CircularProgress from "@warda/library-ui/core/CircularProgress";
import Text from "@warda/library-ui/core/Text";
import { createUseStyles } from "react-jss";
import mixColors from "@warda/library-ui/utils/mixColors";
import { ACTION, reducer, reducerInitState } from "./reducer";
import getPropagation from "./getPropagation";
import classnames from "classnames";
import { PropagationStatus } from "../../interfaces";
import { CtxDispatchMain } from "../contexts";
import { ACTION_MAIN } from "../reducer";
const DELAY = 10000;
const useStyles = createUseStyles({
    propagation: {
        borderRadius: getTheme().borderRadius,
        padding: "5px 15px 5px 10px",
        margin: "0 5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    propagationFinished: {
        backgroundColor: mixColors(0.7, "#fff", getTheme().colors.msgSucc),
    },
    propagationUpdating: {
        backgroundColor: mixColors(0.7, "#fff", getTheme().colors.msgWarn),
    },
    progress: {
        color: getTheme().colors.msgWarn,
    },
    label: {
        marginLeft: 10,
    },
});
const Propagation = ({ status }) => {
    const dispatchMain = React.useContext(CtxDispatchMain);
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { checkin, updateTime, itemsTotal, itemsCount } = state;
    const finished = !!itemsTotal && itemsCount === itemsTotal;
    const onRefresh = React.useCallback(() => {
        location.reload();
    }, []);
    React.useEffect(() => {
        (async () => {
            if (status === PropagationStatus.FETCHING && !checkin) {
                if (finished) {
                    dispatchMain({ type: ACTION_MAIN.PROPAGATION_FINISH });
                    dispatch({ type: ACTION.RESET });
                }
                else {
                    dispatch({ type: ACTION.CHECKIN });
                    console.log("[Propagation] check ", { updateTime }); // need to refresh count
                    await new Promise((resolve) => setTimeout(resolve, DELAY));
                    const { count, total } = await getPropagation();
                    dispatch({ type: ACTION.UPDATE_SUCC, count, total });
                }
            }
        })();
    }, [checkin, dispatchMain, finished, status, updateTime]);
    if (status === PropagationStatus.NONE)
        return null;
    return status === PropagationStatus.COMPLETE ? (React.createElement("div", { className: classnames([classes.propagation, classes.propagationFinished]) },
        React.createElement(Btn, { color: getTheme().colors.msgSucc, icon: "refresh", onClick: onRefresh, style: { margin: 0 } }),
        React.createElement(Text, { weight: "bolder", className: classes.label, children: "New configurations applied" }))) : (React.createElement("div", { className: classnames([classes.propagation, classes.propagationUpdating]) },
        React.createElement(CircularProgress, { className: classes.progress, size: 28 }),
        React.createElement(Text, { weight: "bolder", className: classes.label, children: `Updating new configurations${!itemsTotal ? "" : ` ${itemsCount} / ${itemsTotal}`}` })));
};
export default Propagation;
