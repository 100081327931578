import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
const useStyles = createUseStyles({
    field: {
        display: "inline-flex",
        verticalAlign: "middle",
        alignItems: "stretch",
        flexDirection: "column",
        minWidth: 110,
        margin: 5,
        textAlign: "left",
    },
    fieldTitle: {
        marginLeft: 5,
    },
    fieldInput: {
        border: `1px solid ${getTheme().colors.grayBorder}`,
        borderRadius: 50,
        padding: 4,
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
    },
    fieldInputCircle: {
        borderRadius: 50,
        height: 15,
        width: 15,
        marginRight: 5,
    },
});
const ThemePreviewField = ({ label, color }) => {
    const classes = useStyles({});
    return (React.createElement("div", { className: classes.field },
        React.createElement(Text, { className: classes.fieldTitle, size: 0, children: label }),
        React.createElement("div", { className: classes.fieldInput, style: {
                backgroundColor: hexToRgbA(color, 0.2),
            } },
            React.createElement("div", { className: classes.fieldInputCircle, style: { backgroundColor: color } }),
            React.createElement(Text, { size: 0, children: color }))));
};
export default ThemePreviewField;
