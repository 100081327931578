import { FILTERS_STICKY } from "../constantsKey";
import getAttributeKey from "../components/AreaProducts/getAttributeKey";
import { FilterTypeCategory, FilterTypeDictionary, FilterTypeUser, FilterTypeSingleSelection, FilterTypeMultiSelection, FilterTypeDate, FilterTypeBoolean, FilterTypeSimpleSelection, } from "../components/PanelFilters";
import { missingKey } from "@warda/library-ui/interfaces";
const terms = ({ field, missing, include, nested, min_doc_count = 1, size = 30, type, }) => {
    const order = type === FilterTypeUser ? undefined : { _key: "asc" };
    if (nested) {
        return {
            nested: { path: nested.path },
            aggs: {
                [nested.id]: {
                    terms: {
                        field,
                        size,
                        min_doc_count,
                        missing,
                        order,
                        include,
                    },
                },
            },
        };
    }
    return {
        field,
        size,
        min_doc_count,
        missing,
        order,
        include,
    };
};
const getAggs = ({ filterAggregations = [], include, catalogId, languageId, }) => {
    return (filterAggregations || [])
        .filter((filter) => {
        const { multiCatalog, multiLanguage } = filter;
        const checkMultiCatalog = multiCatalog ? !!catalogId : true;
        const checkMultiLanguage = multiLanguage ? !!languageId : true;
        return checkMultiCatalog && checkMultiLanguage;
    })
        .reduce((acc, filter) => {
        const { id, type, sortId, keyword, nested } = filter;
        const attributeKey = getAttributeKey(filter, catalogId, languageId);
        if (FILTERS_STICKY.has(id)) {
            acc[id] = {
                terms: terms({
                    type,
                    field: id,
                    missing: missingKey,
                    include,
                }),
                aggs: {
                    [sortId]: {
                        terms: {
                            field: sortId,
                        },
                    },
                },
            };
        }
        switch (type) {
            case FilterTypeDictionary:
            case FilterTypeCategory:
            case FilterTypeSimpleSelection:
            case FilterTypeMultiSelection:
            case FilterTypeSingleSelection:
            case FilterTypeUser:
                if (nested) {
                    acc[id] = terms({
                        type,
                        field: keyword ? `${attributeKey}.keyword` : attributeKey,
                        missing: missingKey,
                        include,
                        nested: {
                            id,
                            path: id.split(".")[0],
                        },
                    });
                    break;
                }
                acc[id] = {
                    terms: terms({
                        type,
                        field: keyword ? `${attributeKey}.keyword` : attributeKey,
                        missing: missingKey,
                        include,
                    }),
                };
                break;
            case FilterTypeBoolean:
                acc[id] = {
                    terms: terms({
                        type,
                        field: attributeKey,
                        missing: undefined,
                        include,
                    }),
                };
                break;
            case FilterTypeDate:
                acc[id] = {
                    terms: terms({
                        type,
                        field: attributeKey,
                        size: 10000,
                    }),
                };
                break;
            default:
                break;
        }
        return acc;
    }, {});
};
export default getAggs;
