import * as React from "react";
import UploadItemStatus from "../uploadItemStatus/UploadItemStatus";
import { ACTIONS, reducer, reducerInitState } from "./reducer";
import ModalUploads from "../ModalUpload/ModalUploads";
import { createUseStyles } from "react-jss";
import getMimeType from "../utils/getMimeType";
import apiUrls from "../../../api/apiUrls";
import { createFile, createFolder, getSignedUrl, mcrConfirm, } from "../../../api/fetchesApi";
import { getParentId, getPathArray, SEPARATOR, CURRENT_FOLDER_TO_UPLOAD, } from "../utils/pathUtils";
import { Service } from "@warda/library-ui/interfaces";
const useStyles = createUseStyles({
    multiUploads: {
        bottom: 75,
    },
});
const UploadsDA = ({ uploads }) => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { expanded, items, msgStopUpload, relativePathsFolderId, mapFileIdSaved, } = state;
    /**
     * Alla conclusione di tutti i file da caricare ricarico il content
     * solo se mi trovo in una cartella contenente almeno un file appena caricato.
     */
    const onFinish = React.useCallback(() => {
        /* DEPRECATED refreshTime in reducer main
        const currentFolder = getPathId(pathname);
        const setFileFolderIds = new Set(
          relativePathsFolderId
            .filter((r) => r.folderId !== undefined)
            .map(({ folderId }) =>
              folderId === ROOT_MYFILE_ID ? FileSection.MY_FILES : folderId
            )
        );
        if (setFileFolderIds.has(currentFolder))
          dispatchMain({
            type: ACTION_MAIN.REFRESH_TIME,
            time: new Date().getTime(),
          });
        */
    }, []);
    /**
     * Alla conclusione del file caricato su S3,
     * se il suo (id+sessionUploadId) non è presente nella mappa mapFileIdSaved
     * dei file già caricati in DA, allora lo creo
     */
    const onPutS3File = React.useCallback(async (ifile, fileId) => {
        const { id, file, sessionUploadId } = ifile;
        const { type, name, webkitRelativePath } = file;
        if (!mapFileIdSaved[id + sessionUploadId]) {
            await mcrConfirm(fileId, Service.DA, ifile);
            const mimeType = getMimeType(name, type);
            const relPathFile = webkitRelativePath === ""
                ? CURRENT_FOLDER_TO_UPLOAD
                : getPathArray(webkitRelativePath).join(SEPARATOR);
            const { folderId } = relativePathsFolderId.find(({ name: relFolder, sessionUploadId: sessFolder }) => (relPathFile === CURRENT_FOLDER_TO_UPLOAD ||
                relFolder === relPathFile) &&
                sessFolder === sessionUploadId);
            await createFile({
                file,
                fileId,
                fileMimeType: mimeType,
                folderId,
            });
            dispatch({ type: ACTIONS.SAVED_IN_DA, id: id + sessionUploadId });
        }
    }, [relativePathsFolderId, mapFileIdSaved]);
    const isUploading = !!items.find((el) => {
        const fSet = new Set([
            UploadItemStatus.S3Uploading,
            UploadItemStatus.Waiting,
        ]);
        return fSet.has(el.status);
    });
    const onStopUploads = React.useCallback(() => {
        dispatch({ type: ACTIONS.RESET });
        /* DEPRECATED refreshTime in reducer main
        dispatchMain({
          type: ACTION_MAIN.REFRESH_TIME,
          time: new Date().getTime(),
        });
        */
    }, []);
    const onContinue = React.useCallback(() => {
        dispatch({ type: ACTIONS.MSG_CLOSE });
    }, []);
    const onUpdateFileStatus = React.useCallback((item) => {
        dispatch({
            type: ACTIONS.SET_ITEMS,
            item,
        });
    }, []);
    const onCloseUploads = React.useCallback(() => {
        if (isUploading) {
            dispatch({ type: ACTIONS.MSG_OPEN });
        }
        else {
            dispatch({ type: ACTIONS.RESET });
        }
    }, [isUploading]);
    const toggleExpanded = React.useCallback(() => {
        dispatch({ type: ACTIONS.EXPANDED });
    }, []);
    // creazione delle sottocartelle
    React.useEffect(() => {
        (async () => {
            const sortFolderWithoutFolderIds = relativePathsFolderId
                .filter(({ folderId }) => folderId === undefined)
                .sort((a, b) => a.name.split(SEPARATOR).length - b.name.split(SEPARATOR).length);
            if (sortFolderWithoutFolderIds.length > 0) {
                // PER ORA CREO UNA CARTELLA ALLA VOLTA
                const { name: nameFolderToCreate, sessionUploadId: sessionUploadIdToCreate, } = sortFolderWithoutFolderIds[0];
                const aFirstF = nameFolderToCreate.split(SEPARATOR);
                const nameFolder = aFirstF[aFirstF.length - 1];
                const parentId = getParentId(aFirstF, sessionUploadIdToCreate, relativePathsFolderId);
                const { id } = await createFolder({ name: nameFolder, parentId });
                dispatch({
                    type: ACTIONS.UPDATE_FOLDER_ID_AND_FILE_UPLOADING,
                    nameFolderToCreate,
                    folderId: id,
                });
            }
        })();
    }, [relativePathsFolderId]);
    // effetto per aggiungere ulteriori file da caricare
    React.useEffect(() => {
        var _a;
        if (!!((_a = uploads === null || uploads === void 0 ? void 0 : uploads.ifiles) === null || _a === void 0 ? void 0 : _a.length)) {
            dispatch({
                type: ACTIONS.ADD_ITEMS,
                uploadsDA: uploads,
            });
        }
    }, [uploads]);
    if (!items.length)
        return null;
    return (React.createElement(ModalUploads, { className: classes.multiUploads, items: items, getSignedUrl: getSignedUrl(apiUrls.newUploadDA), onPutS3File: onPutS3File, uploadRequestHeaders: {}, expanded: expanded, onCloseToolbar: onCloseUploads, onExpand: toggleExpanded, onUpdateFileStatus: onUpdateFileStatus, msgStopUpload: msgStopUpload, onContinue: onContinue, onStopUploads: onStopUploads, onFinish: onFinish }));
};
export default UploadsDA;
