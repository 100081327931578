import * as React from "react";
import { useAsyncAbortable } from "react-async-hook";
import useConstant from "use-constant";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { SEARCH_ES_EMPTY } from "./ISearch";
import searchEsDatas from "./searchEsDatas";
const useSearchEs = (a) => {
    const [searchTime, setSearchTime] = React.useState(null);
    const debounceSearchDocs = useConstant(() => AwesomeDebouncePromise(searchEsDatas, 300));
    const searchRes = useAsyncAbortable(async (abortSignal, argDocs) => {
        if (argDocs === null)
            return SEARCH_ES_EMPTY;
        return debounceSearchDocs(a);
    }, [searchTime]);
    return {
        searchTime,
        setSearchTime,
        searchRes,
    };
};
export default useSearchEs;
