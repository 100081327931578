import Btn from "@warda/library-ui/core/Btn";
import * as React from "react";
import { createUseStyles } from "react-jss";
import Card from "@warda/library-ui/core/Card";
import { ACT_VPORT, SORTS } from "../reducer";
import { ISortOrder } from "@warda/library-ui/interfaces";
import { CtxDispatchViewport } from "../contexts";
const useStyles = createUseStyles({
    sorting: {
        position: "absolute",
        top: 7,
        right: 15,
        "z-index": 2,
        padding: 5,
    },
});
const Sorting = ({ sort }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const classes = useStyles({});
    const onSortOrderChange = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.SORT_ORDER });
    }, [dispatchViewport]);
    const onSortIdChange = React.useCallback((event, id) => {
        dispatchViewport({ type: ACT_VPORT.SORT_ID, id });
    }, [dispatchViewport]);
    return (React.createElement(Card, { className: classes.sorting, elevation: 2 },
        React.createElement(Btn, { label: sort.label, menu: {
                items: SORTS.map(({ id, label }) => ({
                    id,
                    label,
                    onClick: onSortIdChange,
                })),
            } }),
        React.createElement(Btn, { icon: sort.order === ISortOrder.ASC ? "arrow_upward" : "arrow_downward", onClick: onSortOrderChange })));
};
export default Sorting;
