import * as React from "react";
import ListItem from "@warda/library-ui/core/ListItem";
import { NavLink } from "react-router-dom";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { getTheme } from "@warda/library-ui/theme";
const WindowSections = ({ view, text, icon, selected, onClose, }) => {
    return (React.createElement(NavLink, { style: {
            display: "block",
            textDecoration: "none",
            color: "inherit",
        }, to: `/${view}/` },
        React.createElement(ListItem, { key: view, id: view, label: text, icon: icon, style: {
                background: selected
                    ? hexToRgbA(getTheme().colors.theme1, 0.15)
                    : undefined,
            }, selected: selected, onClick: onClose, clickPropagation: true })));
};
export default WindowSections;
