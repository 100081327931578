import * as React from "react";
import AssetPreview from "../AssetPreview_DEPRECATED";
import apiUrls from "../../../api/apiUrls";
import getFileIcon from "../../Viewer/getFileIcon";
import { ACT_VPORT } from "../reducer";
import BadgeGetLink from "../Content/BadgeGetLink";
import BadgeSharedWith, { getLabel } from "../Content/BadgeSharedWith";
import BadgeExpirationDate from "../Content/BadgeExpirationDate";
import { SheetLayout } from "../../../interfaces";
import { CtxDispatchViewport } from "../contexts";
import Text from "@warda/library-ui/core/Text";
import getThumbRendition from "../../../utils/getThumbRendition";
export const getCellHeight = () => 180;
export const getCellHeader = (total) => `Files (${total})`;
const getFileExtension = (fileName) => /(?:\.([^.]+))?$/.exec(fileName)[1];
const CellFile = ({ data, color, selected, disabled, cutted, contextmenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const { id, name, documentRepoId, mimeType, sharedWith } = data;
    const fileExtension = getFileExtension(name);
    const onClick = React.useCallback((event, { keyDownCtrl, keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.ITEMS_SELECT,
            itemId: id,
            keyDownCtrl,
            keyDownMeta,
        });
    }, [dispatchViewport, id]);
    const onContextMenu = React.useCallback(() => {
        if (!selected) {
            dispatchViewport({ type: ACT_VPORT.ITEMS_SELECT, itemId: id });
        }
    }, [dispatchViewport, id, selected]);
    const onDoubleClick = React.useCallback(() => {
        dispatchViewport({
            type: ACT_VPORT.SHEET_LAYOUT,
            layout: SheetLayout.MEDIA_ZOOM,
            itemId: id,
        });
    }, [dispatchViewport, id]);
    const onClickBadgeShareWith = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.MODAL_SHARE_PRIVATE, ids: [id] });
    }, [dispatchViewport, id]);
    const icon = getFileIcon(mimeType);
    const srcUrl = apiUrls.downloadMediaDA.url(documentRepoId, getThumbRendition(1, mimeType));
    return (React.createElement(AssetPreview, { style: { borderStyle: cutted ? "dashed" : "solid" }, previewHeight: 130, previewWidth: 160, color: color, disabled: disabled, selected: selected, placeholderIcon: icon, placeholderLabel: icon || !fileExtension ? "" : fileExtension.toUpperCase(), srcUrl: srcUrl, mimeType: mimeType, onClick: onClick, onDoubleClick: onDoubleClick, onContextMenu: onContextMenu, contextmenu: contextmenu },
        React.createElement(Text, { ellipsis: true, style: { flex: 1 }, children: name }),
        React.createElement(BadgeGetLink, { data: data, style: { position: "absolute", top: 6, right: 6 } }),
        React.createElement(BadgeExpirationDate, { data: data, style: { position: "absolute", bottom: 38, right: 6 } }),
        !sharedWith.length ? null : (React.createElement(BadgeSharedWith, { style: { position: "absolute", top: 6, left: 6 }, sharedWith: sharedWith, tooltip: getLabel(sharedWith), onClick: onClickBadgeShareWith }))));
};
export default CellFile;
