import { FiltersCondition, } from "@warda/library-ui/core/Filter";
import concat from "lodash-es/concat";
import isEmpty from "lodash-es/isEmpty";
import { FILTERS_STICKY } from "../../../constantsKey";
import getAttributeKey from "../getAttributeKey";
import escapeKeyEs from "../../../utils/escapeKeyEs";
import { FilterTypeCategory, FilterTypeDictionary, FilterTypeUser, FilterTypeSingleSelection, FilterTypeMultiSelection, FilterTypeText, FilterTypeDate, FilterTypeBoolean, FilterTypePercentage, FilterTypeSimpleSelection, } from "../../PanelFilters";
import { missingKey } from "@warda/library-ui/interfaces";
export const concantValues = ({ key, values, wildcard, caseInsensitive = false, }) => {
    const w = wildcard ? "*" : "";
    return values.map((v) => ({
        wildcard: {
            [key]: {
                value: `${w}${v}${w}`,
                case_insensitive: caseInsensitive,
            },
        },
    }));
};
const getEsConditionFilterSelection = (key, value, nested) => {
    let itemNoValueIsSelected = false;
    const itemsSelectedKey = (value || []).reduce((acc, item) => {
        const { id } = item;
        if (id === missingKey) {
            itemNoValueIsSelected = true;
        }
        else {
            acc.push(id);
        }
        return acc;
    }, []);
    if (isEmpty(itemsSelectedKey) && !itemNoValueIsSelected)
        return null;
    let valuesConditions = [];
    if (!!nested) {
        itemsSelectedKey.forEach((k) => {
            valuesConditions.push({
                nested: {
                    path: nested.path,
                    query: { term: { [nested.key]: k } },
                },
            });
        });
        if (itemNoValueIsSelected) {
            valuesConditions.push({
                bool: {
                    must_not: {
                        nested: {
                            path: nested.path,
                            query: {
                                wildcard: {
                                    [nested.key]: {
                                        value: "*",
                                        case_insensitive: true,
                                    },
                                },
                            },
                        },
                    },
                },
            });
        }
    }
    else {
        valuesConditions = concantValues({ key, values: itemsSelectedKey });
        if (itemNoValueIsSelected) {
            valuesConditions.push({
                bool: { must_not: { exists: { field: key } } },
            });
        }
    }
    return { bool: { should: valuesConditions } };
};
export const getEsConditionFilter = (filter, catalogId = "", languageId = "") => {
    const { type, value, nested, switchCaseSensitive, switchExactValue, switchNoValue, } = filter;
    const key = getAttributeKey(filter, catalogId, languageId);
    switch (type) {
        case FilterTypeText: {
            if (switchNoValue) {
                return {
                    bool: {
                        should: [
                            { wildcard: { [key]: { value: "", case_insensitive: false } } },
                            { bool: { must_not: { exists: { field: key } } } },
                        ],
                    },
                };
            }
            if (isEmpty(value))
                return null;
            return {
                bool: {
                    should: concantValues({
                        key,
                        values: concat(typeof value === "string"
                            ? value
                            : value.filter((x) => !!x)),
                        wildcard: !switchExactValue,
                        caseInsensitive: !switchCaseSensitive,
                    }),
                },
            };
        }
        case FilterTypeDate: {
            if (!value || value.startDate === null)
                return null;
            const filterDateValue = value;
            return {
                range: {
                    [key]: {
                        gte: filterDateValue.startDate,
                        lte: filterDateValue.endDate,
                    },
                },
            };
        }
        case FilterTypePercentage: {
            if (!value)
                return null;
            const defaultCondition = {
                range: {
                    [key]: {
                        gte: value[0],
                        lte: value[1],
                    },
                },
            };
            if (!!value[0])
                return defaultCondition;
            return {
                bool: {
                    should: [
                        defaultCondition,
                        { bool: { must_not: { exists: { field: key } } } },
                    ],
                },
            };
        }
        case FilterTypeBoolean: {
            if (typeof value !== "boolean")
                return null;
            return {
                bool: {
                    must: [
                        {
                            query_string: {
                                query: `(${escapeKeyEs(key)}:${value})`,
                            },
                        },
                    ],
                },
            };
        }
        case FilterTypeDictionary: {
            return getEsConditionFilterSelection(key, value);
        }
        case FilterTypeCategory: {
            return getEsConditionFilterSelection(key, value);
        }
        case FilterTypeUser:
        case FilterTypeSimpleSelection:
        case FilterTypeSingleSelection:
        case FilterTypeMultiSelection: {
            const n = !nested
                ? undefined
                : {
                    key,
                    path: key.split(".")[0],
                };
            return getEsConditionFilterSelection(key, value, n);
        }
        default:
            return null;
    }
};
export const getQuery = ({ conditionsAdditional = [], filtersCondition = FiltersCondition.AND, filters = [], itemsId = [], indexIds = [], slcCtlg = "", slcLang = "", } = {}) => {
    // condizioni di ricerca per array di id di documenti
    const conditionsItemsId = isEmpty(itemsId)
        ? []
        : [concantValues({ key: "id", values: itemsId })];
    // condizioni di ricerca con i filtri sticky e categorie
    const condFiltersSticky = filters
        .filter((f) => {
        const isSticky = FILTERS_STICKY.has(f.id);
        const isCategory = f.type === FilterTypeCategory;
        return isSticky || isCategory;
    })
        .reduce((acc, f) => {
        const condition = getEsConditionFilter(f, slcCtlg, slcLang);
        if (condition)
            acc.push(condition);
        return acc;
    }, []);
    // condizioni di ricerca con i filtri attributi
    const condFiltersValue = filters
        .filter((f) => {
        const isSticky = FILTERS_STICKY.has(f.id);
        const isCategory = f.type === FilterTypeCategory;
        return !isSticky && !isCategory;
    })
        .reduce((acc, f) => {
        const condition = getEsConditionFilter(f, slcCtlg, slcLang);
        if (condition)
            acc.push(condition);
        return acc;
    }, []);
    const condFiltersKey = filtersCondition === FiltersCondition.AND ? "must" : "should";
    const condFilters = isEmpty(condFiltersValue)
        ? []
        : condFiltersValue.length === 1
            ? condFiltersValue[0]
            : [{ bool: { [condFiltersKey]: condFiltersValue } }];
    // condizione per carcare per _id del documento
    const conditionsIndexIds = isEmpty(indexIds)
        ? []
        : [{ terms: { _id: indexIds } }];
    // concateno tutte le condizioni di ricerca
    const conditions = concat(conditionsItemsId, condFiltersSticky, condFilters, conditionsAdditional, conditionsIndexIds);
    if (isEmpty(conditions))
        return undefined;
    return { bool: { must: conditions } };
};
