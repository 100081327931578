import itemsAdd from "./itemsAdd";
import itemsUpdateUploading from "./itemsUpdateUploading";
const itemsAddAndUploading = ({ area, items, filesToAdd, maxSize, numParallelUpload = 1, relativePathsFolderId, }) => {
    const itemsAdded = itemsAdd({
        items,
        filesToAdd,
        maxSize,
    });
    const result = itemsUpdateUploading({
        area,
        items: itemsAdded,
        numParallelUpload,
        relativePathsFolderId,
    });
    return result;
};
export default itemsAddAndUploading;
