import * as React from "react";
import { createUseStyles } from "react-jss";
import { CtxTenantM2ms, CtxTenantUsers } from "../../contexts";
import Avatar from "@warda/library-ui/core/Avatar";
import { getUser } from "@warda/library-ui/utils/getUser";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    cardAvatar: {
        marginRight: 10,
        backgroundColor: getTheme().colors.background,
        border: ({ color }) => `1px solid ${color}`,
        "& *": {
            color: ({ color }) => color,
        },
    },
});
const CardAvatar = ({ icon, text, color = getTheme().colors.msgWarn, userId, }) => {
    const classes = useStyles({ color });
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const user = getUser(userId, users, m2ms);
    return (React.createElement(Avatar, { className: classes.cardAvatar, size: 28, tooltip: !!userId ? user.name : undefined, src: !!userId ? user.avatar : undefined, text: !!userId ? user.avatarText : text, icon: !!userId ? user.avatarIcon : icon }));
};
export default CardAvatar;
