import * as React from "react";
import { FileSection } from "../../../../interfaces";
import Btn from "@warda/library-ui/core/Btn";
import { useHistory } from "react-router-dom";
import { AREA_FILES, TYPE_FOLDER } from "../../../../constants";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
    btnfile: {
        "align-self": "baseline",
        "min-height": 22,
        margin: "3px 0",
    },
    btnfileIcon: {
        "font-size": "12px !important",
        "margin-left": 5,
    },
    btnfileLabel: {
        "font-weight": 300,
        "text-align": "left",
    },
});
const BtnFile = ({ data }) => {
    const classes = useStyles({});
    const history = useHistory();
    const { id, name, mimeType } = data;
    const isFolder = mimeType === TYPE_FOLDER;
    const onClick = React.useCallback(() => {
        if (isFolder) {
            history.push(`/${AREA_FILES}/${id}`);
        }
        else {
            history.push(`/${AREA_FILES}/${FileSection.SHARES_PRIVATE}`);
        }
    }, [history, id, isFolder]);
    return (React.createElement(Btn, { className: classes.btnfile, variant: "bold", icon: isFolder ? "folder" : "insert_drive_file", iconClassName: classes.btnfileIcon, label: name, labelClassName: classes.btnfileLabel, onClick: onClick }));
};
export default BtnFile;
