import { SEPARATOR, CURRENT_FOLDER_TO_UPLOAD, getPathArray } from "./pathUtils";
import UploadItemStatus from "../uploadItemStatus/UploadItemStatus";
import isUploadingState from "../uploadItemStatus/isUploadingState";
import { UploadArea } from "../UploadArea";
const itemsUpdateUploading = ({ area, items, numParallelUpload = 1, relativePathsFolderId, }) => {
    const newItems = [].concat(items);
    const countUplading = newItems.filter(({ status }) => isUploadingState(status)).length;
    newItems
        .filter(({ status, file }) => {
        var _a;
        if (status !== UploadItemStatus.Waiting)
            return false;
        else if (area == UploadArea.da) {
            const isAvailableFolderId = ((_a = relativePathsFolderId.find(({ name: folderName }) => {
                const c1 = file.webkitRelativePath === "";
                const c2 = folderName === CURRENT_FOLDER_TO_UPLOAD;
                const folderPath = getPathArray(file.webkitRelativePath).join(SEPARATOR);
                return (c1 && c2) || folderName === folderPath;
            })) === null || _a === void 0 ? void 0 : _a.folderId) !== undefined;
            return isAvailableFolderId;
        }
        else {
            return true;
        }
    })
        .slice(0, numParallelUpload > countUplading ? numParallelUpload - countUplading : 0)
        .forEach((itemToAdd) => {
        itemToAdd.status = UploadItemStatus.ReadyToUpload;
    });
    return newItems;
};
export default itemsUpdateUploading;
