import { AttributeFamily, } from "../../../interfaces";
import { getAttributeId } from "../getAttributeKey";
import getCategoryData from "../../AreaCategories/getCategoryData";
export const decorateAttributesCategory = (item, columns, categories) => {
    const obj = {};
    const attributesKey = Object.keys(item).filter((attrKey) => {
        const { attrId } = getAttributeId(attrKey);
        const column = columns.find((c) => c.id === attrId);
        return (!!item[attrKey] &&
            !!column &&
            column.attributeFamily === AttributeFamily.CATEGORIES);
    });
    attributesKey.forEach((attrKey) => {
        const { ctlgId, langId } = getAttributeId(attrKey);
        obj[attrKey] = item[attrKey]
            .map((categoryDataId) => {
            const category = getCategoryData({
                categoryDataId,
                categoryDataCatalog: ctlgId,
                categories,
            });
            return category;
        })
            .filter((c) => {
            return !!c;
        })
            .map((c) => {
            return {
                path: c.data.id,
                label: c.data.labels[langId],
            };
        });
    });
    return obj;
};
