import { missingKey } from "@warda/library-ui/interfaces";
export const HTML_ID_DRAWERS = "portalIdDrawers";
export const DRAWER_WIDTH = 400;
export var ViewMode;
(function (ViewMode) {
    ViewMode["NAVIGATION"] = "NAVIGATION";
    ViewMode["SEARCH"] = "SEARCH";
})(ViewMode || (ViewMode = {}));
export const FileTypeId = {
    FOLDER: missingKey,
    DOCUMENT: "DOCUMENT",
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    OTHER: "OTHER",
};
export const FileTypeIcon = {
    [FileTypeId.FOLDER]: "folder",
    [FileTypeId.DOCUMENT]: "insert_drive_file",
    [FileTypeId.IMAGE]: "image",
    [FileTypeId.VIDEO]: "videocam",
    [FileTypeId.OTHER]: "insert_drive_file",
};
export const FileTypeText = {
    [FileTypeId.FOLDER]: "Folders",
    [FileTypeId.DOCUMENT]: "Documents",
    [FileTypeId.IMAGE]: "Images",
    [FileTypeId.VIDEO]: "Videos",
    [FileTypeId.OTHER]: "Other",
};
export const getAccessCode = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("token");
};
export const getTenantId = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("tenantId");
};
export var ATTRIBUTES_KEY;
(function (ATTRIBUTES_KEY) {
    ATTRIBUTES_KEY["NAME"] = "name";
    ATTRIBUTES_KEY["SIZE"] = "sizeInBytes";
    ATTRIBUTES_KEY["TYPE"] = "mimeType";
    ATTRIBUTES_KEY["CREATED"] = "created";
})(ATTRIBUTES_KEY || (ATTRIBUTES_KEY = {}));
export const ATTRIBUTES = [
    {
        id: ATTRIBUTES_KEY.NAME,
        label: "Name",
        width: 250,
        sortable: true,
    },
    {
        id: ATTRIBUTES_KEY.CREATED,
        label: "Created",
        width: 150,
        sortable: true,
    },
    {
        id: ATTRIBUTES_KEY.SIZE,
        label: "Size",
        width: 150,
        sortable: false,
    },
    {
        id: ATTRIBUTES_KEY.TYPE,
        label: "Extension",
        width: 80,
        sortable: false,
    },
];
