import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Icon from "@warda/library-ui/core/Icon";
import Text from "@warda/library-ui/core/Text";
import BtnBase from "@warda/library-ui/core/BtnBase";
import MenuSections from "./WindowSections";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { createUseStyles } from "react-jss";
import { CtxPermissions } from "../contexts";
import getSections from "../../utils/getSections";
const useStyles = createUseStyles({
    btnSections: {
        padding: "0 5px",
        "min-height": 30,
        display: "flex",
        "align-items": "center",
        "border-radius": 5,
        transition: "all 350ms",
        margin: 0,
        color: getTheme().colors.theme1,
        "background-color": ({ menuOpen }) => !menuOpen ? "transparent" : hexToRgbA(getTheme().colors.theme1, 0.15),
        "&:hover": {
            "background-color": ({ disabled }) => disabled ? "transparent" : hexToRgbA(getTheme().colors.theme1, 0.15),
        },
    },
    label: {
        "line-height": "inherit",
        margin: "0 5px",
    },
    iconApp: {
        margin: "0px 5px",
    },
    iconDrop: {
        margin: "0px 5px",
        display: ({ disabled }) => (disabled ? "none" : "block"),
    },
});
const BtnWindowSections = ({ title, viewId }) => {
    const permissions = React.useContext(CtxPermissions);
    const sections = getSections(permissions);
    const menuRef = React.useRef(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenuOpen(true);
    }, []);
    const onMenuClose = React.useCallback(() => {
        setMenuOpen(false);
    }, []);
    const disabled = sections.length < 2;
    const classes = useStyles({ disabled, menuOpen });
    const section = sections.find((s) => s.view === viewId);
    const sectionLabel = section ? section.text : title;
    return (React.createElement(React.Fragment, null,
        React.createElement(BtnBase, { ref: menuRef, className: classes.btnSections, onClick: disabled ? undefined : onMenuOpen },
            React.createElement(Icon, { className: classes.iconApp, children: "apps" }),
            React.createElement(Text, { className: classes.label, weight: "bolder", children: sectionLabel }),
            React.createElement(Icon, { className: classes.iconDrop, children: "keyboard_arrow_down" })),
        React.createElement(MenuSections, { anchorEl: menuRef.current, open: menuOpen, onClose: onMenuClose, viewId: viewId, sections: sections })));
};
export default BtnWindowSections;
