import Cookies from "js-cookie";
import { AUTHORIZATION_TOKEN, REFRESH_TOKEN } from "../constantsLocalStorage";
let webSocketInst;
let connectionId;
const registeredCallbacks = [];
export const openWebSocket = (url, anonymous = false) => {
    const wsClosed = !webSocketInst || webSocketInst.readyState === WebSocket.CLOSED;
    const needToOpen = anonymous
        ? wsClosed
        : Cookies.get(AUTHORIZATION_TOKEN) &&
            Cookies.get(REFRESH_TOKEN) &&
            wsClosed;
    if (needToOpen) {
        webSocketInst = new WebSocket(url);
        webSocketInst.onmessage = (e) => {
            if (e.data) {
                const data = JSON.parse(e.data);
                if (data.connectionUUID !== undefined) {
                    connectionId = data.connectionUUID;
                }
                if (!data.connectionUUID && !connectionId) {
                    webSocketInst.send("GetConnectionUUID");
                }
                if (Array.isArray(data)) {
                    registeredCallbacks.forEach((x) => x.callback(data, x.dispatch));
                }
            }
        };
        webSocketInst.onopen = () => {
            console.log("[websocket] ✅ opened");
        };
        webSocketInst.onclose = () => {
            console.log("[websocket] ❌ closed");
            openWebSocket(url);
        };
    }
};
export const closeWebSocket = () => {
    if (!!webSocketInst)
        webSocketInst.close();
};
export const getWebSocketConnectionId = () => {
    const ret = connectionId;
    return ret;
};
export const registerWebSocketCallback = (callback) => {
    const found = registeredCallbacks.find((x) => x.id === callback.id) !== undefined;
    if (found)
        return false;
    registeredCallbacks.push(callback);
    return true;
};
export const unregisterWebSocketCallback = (callbackId) => {
    const foundIndex = registeredCallbacks.findIndex((x) => x.id === callbackId);
    if (foundIndex < 0)
        return false;
    registeredCallbacks.splice(foundIndex, 1);
    return true;
};
