import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import { Initialize } from "@warda/library-ui/interfaces";
import { searchFiles } from "../useSearchEs";
import TextLoading from "@warda/library-ui/core/TextLoading";
import { CtxDispatchViewport } from "../contexts";
import { ACT_VPORT } from "../reducer";
import { getTheme } from "@warda/library-ui/theme";
var ACTION;
(function (ACTION) {
    ACTION["RESET"] = "RESET";
    ACTION["LOAD_STARTED"] = "INIT_CONFIRM_STARTED";
    ACTION["LOAD_LOADING"] = "INIT_CONFIRM_LOADING";
    ACTION["LOAD_STOPPED"] = "INIT_CONFIRM_STOPPED";
})(ACTION || (ACTION = {}));
const reducerInitState = {
    page: 2,
    initLoad: Initialize.NONE,
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.RESET:
            return reducerInitState;
        case ACTION.LOAD_STARTED:
            newState.initLoad = Initialize.START;
            return newState;
        case ACTION.LOAD_LOADING:
            newState.initLoad = Initialize.LOADING;
            return newState;
        case ACTION.LOAD_STOPPED:
            newState.page = newState.page + 1;
            newState.initLoad = Initialize.NONE;
            return newState;
        default:
            return state;
    }
};
const ViewLoadMore = ({ color = getTheme().colors.theme1, className, style, type, hrch, prnt, sortKey, sortOrd, filters, filtersCondition, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { page, initLoad } = state;
    const loading = initLoad === Initialize.START || initLoad === Initialize.LOADING;
    const cbOnLoadClik = React.useCallback(() => {
        dispatch({ type: ACTION.LOAD_STARTED });
    }, []);
    React.useEffect(() => {
        (async () => {
            if (initLoad === Initialize.START) {
                dispatch({ type: ACTION.LOAD_LOADING });
                const { files } = await searchFiles({
                    type,
                    hrch,
                    prnt,
                    sortKey,
                    sortOrd,
                    filters,
                    filtersCondition,
                    paginationCurr: page,
                });
                dispatch({ type: ACTION.LOAD_STOPPED });
                dispatchViewport({ type: ACT_VPORT.LOADED_MORE_FILES, files });
            }
        })();
    }, [
        dispatchViewport,
        filters,
        filtersCondition,
        hrch,
        initLoad,
        page,
        prnt,
        sortKey,
        sortOrd,
        type,
    ]);
    return (React.createElement("div", { className: className, style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${color}`,
            boxSizing: "border-box",
            height: 32,
            width: 120,
            borderRadius: getTheme().borderRadius,
            backgroundColor: getTheme().colors.background,
            ...style,
        } }, loading ? (React.createElement(TextLoading, { color: color })) : (React.createElement(Btn, { style: { margin: 0, flex: 1, textAlign: "center" }, color: color, label: "LOAD MORE", labelStyle: { color }, tooltip: "Click to load more results", onClick: cbOnLoadClik }))));
};
export default ViewLoadMore;
