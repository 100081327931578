import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Btn from "@warda/library-ui/core/Btn";
import Window from "./Window";
import websocketCallbacks from "./websocketCallbacks";
import { registerWebSocketCallback, unregisterWebSocketCallback, } from "../webSocket";
import { deleteNotifJob, getAllNotifJob, getOneNotifJob, } from "../../api/fetchesApiNotifications";
import { reducer, reducerInitState, ACTION } from "./reducer";
const LABEL = "Bulk Edit Notifications";
const BtnWindow = () => {
    const btnRef = React.useRef(null);
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, windowOpen, jobItems, jobNewIdsToGet, jobDeletingAll, } = state;
    const onOpen = React.useCallback(() => {
        dispatch({ type: ACTION.WINDOW_OPEN, open: true });
    }, []);
    // add websocket listener
    React.useEffect(() => {
        websocketCallbacks.forEach((x) => {
            registerWebSocketCallback({
                id: x.id,
                callback: x.callback,
                dispatch,
            });
        });
        return () => {
            websocketCallbacks.forEach((x) => {
                unregisterWebSocketCallback(x.id);
            });
        };
    }, []);
    // get all JOB
    React.useEffect(() => {
        (async () => {
            if (!initialize) {
                const job = await getAllNotifJob();
                dispatch({ type: ACTION.INITIALIZE, job });
            }
        })();
    }, [initialize]);
    // delete all JOB
    React.useEffect(() => {
        (async () => {
            if (jobDeletingAll) {
                await deleteNotifJob();
                dispatch({ type: ACTION.JOB_DELETE_ALL });
            }
        })();
    }, [jobDeletingAll]);
    // after WS notifications, show new cards JOB
    React.useEffect(() => {
        (async () => {
            if (!!jobNewIdsToGet.length) {
                const jobId = jobNewIdsToGet[0];
                try {
                    const newItem = await getOneNotifJob(jobId);
                    dispatch({ type: ACTION.JOB_NEWITEMS_LOADED, newItem });
                }
                catch {
                    dispatch({ type: ACTION.JOB_NEWITEMS_REMOVE, jobId });
                }
            }
        })();
    }, [jobNewIdsToGet]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { cmpRef: btnRef, disabled: !initialize, icon: "speaker_notes", tooltip: LABEL, onClick: onOpen, selected: windowOpen, badge: !!jobItems.length, badgeColor: getTheme().colors.msgSucc }),
        React.createElement(Window, { dispatch: dispatch, open: windowOpen, anchorEl: btnRef.current, title: LABEL, items: jobItems })));
};
export default BtnWindow;
