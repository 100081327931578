import * as React from "react";
import AssetPreview from "../../AreaFiles_DEPRECATED/AssetPreview_DEPRECATED";
import apiUrls from "../../../api/apiUrlsAnonymous";
import getFileIcon from "../../Viewer/getFileIcon";
import { ACT_VPORT } from "../reducer";
import { useLocation } from "react-router-dom";
import { getItemActions, MAP_TYPE } from "../actions";
import { SheetLayout } from "../../../interfaces";
import { CtxDispatchViewport } from "../contexts";
import Text from "@warda/library-ui/core/Text";
import getThumbRendition from "../../../utils/getThumbRendition";
export const getCellHeight = () => 180;
export const getCellHeader = (total) => `Files (${total})`;
const getFileExtension = (fileName) => /(?:\.([^.]+))?$/.exec(fileName)[1];
const CellFile = ({ items, itemsIdSelected, data, selected, detailOpen, color, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const searchString = useLocation().search;
    const query = new URLSearchParams(searchString);
    const link = query.get("link");
    const tenantId = query.get("tenantId");
    const { id, name, documentRepoId, mimeType } = data;
    const fileExtension = getFileExtension(name);
    const onClick = React.useCallback((event, { keyDownCtrl, keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.ITEMS_SELECT,
            itemId: id,
            keyDownCtrl,
            keyDownMeta,
        });
    }, [dispatchViewport, id]);
    const onContextMenu = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.ITEMS_CONTEXT, id });
    }, [dispatchViewport, id]);
    const onDoubleClick = React.useCallback(() => {
        dispatchViewport({
            type: ACT_VPORT.SHEET_LAYOUT,
            layout: SheetLayout.MEDIA_ZOOM,
            itemId: id,
        });
    }, [dispatchViewport, id]);
    const onActionClick = React.useCallback((event, id) => dispatchViewport({ type: MAP_TYPE[id] }), [dispatchViewport]);
    const icon = getFileIcon(mimeType);
    const srcUrl = apiUrls.getRenditionPublic.url(link, documentRepoId, getThumbRendition(2, mimeType));
    return (React.createElement(AssetPreview, { previewHeight: 130, previewWidth: 160, color: color, selected: selected, placeholderIcon: icon, placeholderLabel: icon || !fileExtension ? "" : fileExtension.toUpperCase(), srcUrl: srcUrl, mimeType: mimeType, onClick: onClick, onDoubleClick: onDoubleClick, onContextMenu: onContextMenu, contextmenu: getItemActions({
            tenantId,
            contextmenu: true,
            detailOpen,
            items,
            itemsIdSelected,
        }).map((a) => ({ ...a, onClick: onActionClick })) },
        React.createElement(Text, { ellipsis: true, style: { flex: 1 }, children: name })));
};
export default CellFile;
