import * as React from "react";
import ViewBlockAsset from "@warda/library-ui/core/View/ViewBlockAsset";
import { CtxDispatchViewport } from "../../contexts";
import { getAssetPropsFolder } from "../utils";
const hTitle = 50;
const hMargin = 10;
export const blockHeightFolder = hTitle + hMargin * 2;
const ViewBlockAssetFolder = ({ id, color, size, data, selected, selectedIds, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, onContextMenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const props = React.useMemo(() => {
        return getAssetPropsFolder({
            dispatchViewport,
            color,
            id,
            data,
            selected,
            selectedIds,
            onContextMenu,
            contextmenuOriginAnchor,
            contextmenuOriginTransf,
            contextmenuPosizionZone,
        });
    }, [
        color,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        data,
        dispatchViewport,
        id,
        onContextMenu,
        selected,
        selectedIds,
    ]);
    return React.createElement(ViewBlockAsset, { ...props, width: size[0] - hMargin * 2 });
};
export default ViewBlockAssetFolder;
