import { TYPE_FOLDER } from "../../constants";
import * as MIMEType from "whatwg-mimetype";
import { FileType } from "../../interfaces";
const getFileIcon = (mimeType, fileType) => {
    try {
        if (TYPE_FOLDER === mimeType)
            return "folder";
        if (fileType === FileType.FOLDER)
            return "folder";
        if (fileType === FileType.TOUCHPOINT)
            return "cast_connected";
        const type = new MIMEType(mimeType).type;
        if (type === "image")
            return "image";
        if (type === "video")
            return "videocam";
        if (type === "application")
            return "insert_drive_file";
        if (type === "audio")
            return "music_note";
        if (type === "text")
            return "code";
        throw "NO_TYPE";
    }
    catch {
        return "insert_drive_file";
    }
};
export default getFileIcon;
