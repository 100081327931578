import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Popover from "@warda/library-ui/core/Popover";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import Divider from "@warda/library-ui/core/Divider";
import Btn from "@warda/library-ui/core/Btn";
import { areFiltersUseful, FilterTypeCategory, clearFiltersAttr, } from "../utils";
import getFilterChanged from "./getFilterChanged";
import MenuFiltersValuedTitle from "./MenuFiltersValuedTitle";
import MenuFiltersValuedItem from "./MenuFiltersValuedItem";
import { FilterCount } from "@warda/library-ui/core/Filter";
import List from "@warda/library-ui/core/List";
const originAnchor = {
    vertical: "bottom",
    horizontal: "left",
};
const originTransf = {
    vertical: "top",
    horizontal: "left",
};
const MENU_WIDTH = 230;
const useStyle = createUseStyles({
    menuContent: {
        padding: "0 10px",
        maxWidth: MENU_WIDTH,
        minWidth: MENU_WIDTH,
        width: MENU_WIDTH,
        maxHeight: "70vh",
    },
    menuToolbar: {
        padding: "0px 10px",
    },
    menuTitle: {
        marginLeft: 5,
    },
    menuDivider: {
        margin: "0 10px",
    },
    filterTitle: {
        margin: "10px 5px",
    },
});
const MenuFiltersValued = ({ className, style, color = getTheme().colors.theme1, filters, onFiltersChange, open, setOpen, title = "Filters applied", }) => {
    const classes = useStyle({});
    const menuRef = React.useRef(null);
    const setMenuOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const setMenuClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    const filtersValued = filters.filter((f) => areFiltersUseful([f]));
    const filtersValuedAttrs = filtersValued.filter((f) => f.type !== FilterTypeCategory);
    const filtersValuedCtgrs = filtersValued.filter((f) => f.type === FilterTypeCategory);
    const count = filtersValued.length;
    const onClearAll = React.useCallback(() => {
        onFiltersChange(clearFiltersAttr(filters));
    }, [filters, onFiltersChange]);
    const onClear = React.useCallback((filtersToClear) => {
        const set = new Set(filtersToClear.map((f) => f.id));
        onFiltersChange(filters.map((f) => (set.has(f.id) ? clearFiltersAttr([f])[0] : f)));
    }, [filters, onFiltersChange]);
    const onClickChips = React.useCallback((filterId, chipId, chipValue) => {
        const newFilters = filters.map((f) => {
            if (f.id !== filterId)
                return f;
            return getFilterChanged(f, chipId, chipValue);
        });
        onFiltersChange(newFilters);
    }, [filters, onFiltersChange]);
    React.useEffect(() => {
        if (!count && open)
            setOpen(false);
    }, [count, open, setOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterCount, { className: className, style: style, color: color, open: !!count, cmpRef: menuRef, label: count, tooltip: title, onClick: setMenuOpen }),
        React.createElement(Popover, { open: !!count && open, onClose: setMenuClose, anchorEl: menuRef.current, originAnchor: originAnchor, originTransf: originTransf },
            React.createElement(Toolbar, { className: classes.menuToolbar },
                React.createElement(Text, { size: 2, weight: "bolder", className: classes.menuTitle, children: title }),
                React.createElement("div", { style: { flex: 1, width: 20 } }),
                React.createElement(Btn, { selected: true, variant: "bold", label: "Clear all", onClick: onClearAll, small: true, color: color })),
            React.createElement(Divider, { className: classes.menuDivider }),
            React.createElement(List, { className: classes.menuContent },
                !filtersValuedAttrs.length || !filtersValuedCtgrs.length ? null : (React.createElement(MenuFiltersValuedTitle, { icon: "tune", label: "Attributes", filters: filtersValuedAttrs, onClear: onClear, color: color })),
                filtersValuedAttrs.map((filter) => (React.createElement(MenuFiltersValuedItem, { key: filter.id, filter: filter, onClickChips: onClickChips, onClickClear: onClear, color: color }))),
                !filtersValuedAttrs.length || !filtersValuedCtgrs.length ? null : (React.createElement(Divider, null)),
                !filtersValuedAttrs.length || !filtersValuedCtgrs.length ? null : (React.createElement(MenuFiltersValuedTitle, { icon: "category", label: "Categories", filters: filtersValuedCtgrs, onClear: onClear, color: color })),
                filtersValuedCtgrs.map((filter) => (React.createElement(MenuFiltersValuedItem, { key: filter.id, filter: filter, onClickChips: onClickChips, onClickClear: onClear, color: color })))))));
};
export default MenuFiltersValued;
