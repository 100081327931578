import UploadItemStatus from "../uploadItemStatus/UploadItemStatus";
import isEmpty from "lodash-es/isEmpty";
import itemsUpdateUploading from "./itemsUpdateUploading";
import isFinalState from "../uploadItemStatus/isFinalState";
const itemsUpdate = ({ area, items = [], item, numParallelUpload = 1, relativePathsFolderId, }) => {
    if (isEmpty(items))
        return [];
    if (item == null)
        return items;
    items
        .filter((actualItem) => actualItem.id === item.id &&
        actualItem.sessionUploadId === item.sessionUploadId &&
        !isFinalState(actualItem.status))
        .slice(0, 1)
        .forEach((actualItem) => {
        actualItem.status = item.status;
        if (item.status === UploadItemStatus.Error) {
            actualItem.tooltip = item.tooltip || actualItem.tooltip;
        }
    });
    return itemsUpdateUploading({
        area,
        items,
        numParallelUpload,
        relativePathsFolderId,
    });
};
export default itemsUpdate;
