import isEmpty from "lodash-es/isEmpty";
const getAggrItems = (aggr, facetKey) => {
    var _a;
    const items1 = (_a = aggr[facetKey][facetKey]) === null || _a === void 0 ? void 0 : _a.buckets;
    if (!isEmpty(items1))
        return items1;
    const items2 = aggr[facetKey].buckets;
    if (!isEmpty(items2))
        return items2;
    return [];
};
const getAggregations = (aggr) => {
    return Object.keys(aggr).map((id) => {
        const { doc_count_error_upper_bound, sum_other_doc_count, range } = aggr[id];
        return {
            id,
            items: getAggrItems(aggr, id),
            range: !!range ? range.buckets : [],
            doc_count_error_upper_bound,
            sum_other_doc_count,
        };
    });
};
const getElasticSrcAggregations = (elasticSrc) => {
    const aggr = elasticSrc.aggregations;
    if (isEmpty(elasticSrc.aggregations))
        return [];
    return getAggregations(aggr);
};
export const normalizeElasticSrc = (elasticSrc) => {
    if (!elasticSrc) {
        return {
            aggregations: [],
            itemsTotal: 0,
            items: [],
        };
    }
    if (elasticSrc.error) {
        console.warn("⚠️normalizeElasticSrc", { elasticSrc });
        return {
            aggregations: [],
            itemsTotal: 0,
            items: [],
        };
    }
    return {
        aggregations: getElasticSrcAggregations(elasticSrc),
        itemsTotal: elasticSrc.hits.total.value,
        items: elasticSrc.hits.hits.map((o) => ({
            id: o._id,
            data: o._source,
        })),
    };
};
/**
 * aggregazione per la query su ElasticSearch del sorting
 */
export const getAggrElasticSorts = (sorts) => sorts.map((s) => ({
    [s.keyword ? `${s.id}.keyword` : s.id]: {
        order: s.order,
    },
}));
