import concat from "lodash-es/concat";
import without from "lodash-es/without";
export var ACTION;
(function (ACTION) {
    ACTION["INITIALIZE"] = "INITIALIZE";
    ACTION["WINDOW_OPEN"] = "WINDOW_OPEN";
    //
    ACTION["DRC_BADGE_UNVIEW"] = "DRC_BADGE_UNVIEW";
    ACTION["DRC_MARKREAD_ONE"] = "DRC_MARKREAD_ONE";
    ACTION["DRC_MARKREAD_ALL"] = "DRC_MARKREAD_ALL";
    ACTION["DRC_DELETE_ALL"] = "DRC_DELETE_ALL";
    ACTION["DRC_DELETE_ONE"] = "DRC_DELETE_ONE";
    ACTION["DRC_NEWITEMS_TO_GET"] = "DRC_NEWITEMS_TO_GET";
    ACTION["DRC_NEWITEMS_LOADED"] = "DRC_NEWITEMS_LOADED";
})(ACTION || (ACTION = {}));
export const reducerInitState = {
    initialize: false,
    windowOpen: false,
    drcItems: [],
    drcNewIdsToGet: [],
    drcBadgeUnview: 0,
    drcDeletingAll: false,
    drcMarkReadAll: false,
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.INITIALIZE:
            newState.initialize = true;
            newState.drcBadgeUnview = action.unview;
            newState.drcItems = action.items;
            return newState;
        case ACTION.DRC_DELETE_ONE: {
            const items = Array.from(newState.drcItems);
            const itemToRemove = items.find((n) => n.id === action.id);
            newState.drcItems = without(items, itemToRemove);
            return newState;
        }
        case ACTION.DRC_MARKREAD_ONE: {
            const newItems = Array.from(newState.drcItems);
            const itemIndex = newItems.findIndex((n) => n.id === action.id);
            newItems.splice(itemIndex, 1, {
                ...newItems[itemIndex],
                markedAsRead: action.mark,
            });
            newState.drcItems = newItems;
            return newState;
        }
        case ACTION.DRC_MARKREAD_ALL:
            newState.drcMarkReadAll = !!action.toFetch;
            if (action.toFetch) {
                newState.drcItems = newState.drcItems.map((n) => ({
                    ...n,
                    markedAsRead: true,
                }));
            }
            return newState;
        case ACTION.DRC_DELETE_ALL:
            newState.drcDeletingAll = !!action.toFetch;
            if (action.toFetch) {
                newState.drcItems = reducerInitState.drcItems;
            }
            return newState;
        case ACTION.DRC_NEWITEMS_TO_GET: {
            const ids = action.ids;
            if (!newState.windowOpen) {
                const array = Array.from(newState.drcNewIdsToGet);
                ids.forEach((j) => array.push(j));
                newState.drcNewIdsToGet = array;
            }
            newState.drcBadgeUnview = newState.drcBadgeUnview + ids.length;
            return newState;
        }
        case ACTION.DRC_NEWITEMS_LOADED: {
            const itemToAdd = action.newItem;
            const array = Array.from(newState.drcNewIdsToGet);
            const index = array.findIndex((i) => i === itemToAdd.id);
            array.splice(index, 1);
            newState.drcNewIdsToGet = array;
            newState.drcItems = concat([itemToAdd], newState.drcItems);
            return newState;
        }
        case ACTION.WINDOW_OPEN:
            newState.windowOpen = action.open;
            return newState;
        case ACTION.DRC_BADGE_UNVIEW:
            newState.drcBadgeUnview = action.value;
            return newState;
        default:
            return newState;
    }
};
