import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
const BtnAction = ({ id, icon, label, hidden, onClick }) => {
    const onClickCb = React.useCallback((event) => {
        onClick(event, id);
    }, [id, onClick]);
    if (hidden)
        return null;
    return React.createElement(Btn, { icon: icon, tooltip: label, onClick: onClickCb });
};
export default BtnAction;
