import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import Modal from "@warda/library-ui/core/Modal";
import TextLoading from "@warda/library-ui/core/TextLoading";
import { getTheme } from "@warda/library-ui/theme";
const DialogModal = ({ className, style, open, loading, title, buttons = [], onClose, children, }) => {
    const color = getTheme().colors.theme1;
    return (React.createElement(Modal, { open: open, loading: loading, title: title, onClose: onClose, content: children, contentClassName: className, contentStyle: { padding: 0, ...style }, actions: buttons.length === 0 ? undefined : (React.createElement(React.Fragment, null,
            !loading ? null : React.createElement(TextLoading, null),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Btn, { style: { borderRadius: 100 }, onClick: onClose, label: "Cancel", labelStyle: { color }, color: color }),
            buttons.map((b, i) => (React.createElement(Btn, { key: `${i}_button`, ...b, style: { ...b.style, borderRadius: 100 }, icon: undefined, labelStyle: { color: b.color || color }, color: b.color || color }))))) }));
};
export default DialogModal;
