import * as React from "react";
import { FieldText } from "@warda/library-ui/core/Field";
import getFileIcon from "../../Viewer/getFileIcon";
import { createUseStyles } from "react-jss";
import { CtxTenantM2ms, CtxTenantUsers } from "../../contexts";
import formatHoursMinutes from "../../../utils/formatHoursMinutes";
import getFileExtension from "../../AreaFiles_DEPRECATED/getFileExtension";
import bytesFormat from "@warda/library-ui/utils/bytesFormat";
import { getUser } from "@warda/library-ui/utils/getUser";
const useStyles = createUseStyles({
    mediainfo: {
        flex: 1,
        display: "flex",
        "flex-direction": "column",
        margin: "10px 20px",
    },
    field: {
        margin: "35px 0 0",
    },
    avatar: {
        position: "relative",
        display: "inline-block",
        "vertical-align": "middle",
        "margin-right": 5,
        "background-color": "#ddd",
        height: 24,
        width: 24,
    },
});
const InfoGeneral = ({ assetData, mediaData }) => {
    const classes = useStyles({});
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const { name, mimeType, createdBy, createdOn } = assetData;
    const fields = [
        {
            label: "Name",
            value: name,
        },
        {
            label: "Type",
            value: getFileExtension(mimeType),
            adornmentIcon: getFileIcon(mimeType),
            adornmentIconTooltip: mimeType,
        },
    ];
    if (!!createdBy) {
        const user = getUser(createdBy, users, m2ms);
        fields.push({
            label: "Created by",
            value: user.name,
            adornmentAvatar: user.avatar,
            adornmentAvatarText: user.avatarText,
            adornmentAvatarIcon: user.avatarIcon,
        });
    }
    if (!!createdOn) {
        fields.push({
            label: "Upload Date",
            value: formatHoursMinutes(createdOn),
        });
    }
    if (!!mediaData) {
        fields.push({
            label: "Size",
            value: bytesFormat((mediaData === null || mediaData === void 0 ? void 0 : mediaData.sizeInBytes) || 0),
        });
        fields.push({
            label: "Dimensions",
            value: (mediaData === null || mediaData === void 0 ? void 0 : mediaData.dimensions) || "",
        });
    }
    return (React.createElement("div", { className: classes.mediainfo }, fields.map((f, i) => (React.createElement(FieldText, { key: i, readOnly: true, ...f, className: classes.field })))));
};
export default InfoGeneral;
