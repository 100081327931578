import * as dizExtensions from "@warda/library-ui/utils/extensions.json";
const OCTET_STREAM_MIMETYPE = "application/octet-stream";
export const getExtension = (name) => {
    const r = name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
    return r ? r[1].toLowerCase() : undefined;
};
const getMimeType = (name, type) => {
    if (!!type && type !== OCTET_STREAM_MIMETYPE)
        return type;
    try {
        const ext = getExtension(name);
        return dizExtensions[ext].mime;
    }
    catch {
        return OCTET_STREAM_MIMETYPE;
    }
};
export default getMimeType;
