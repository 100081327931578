import * as React from "react";
import CardTitle from "../CardTitle";
const CardAssignment = ({ cardClasses, dispatch, data }) => {
    const { payload } = data;
    const { assignedViews } = payload;
    const entityIds = [];
    const viewNames = [];
    assignedViews.forEach(({ entityId, viewName }) => {
        if (!new Set(entityIds).has(entityId))
            entityIds.push(entityId);
        viewNames.push(viewName);
    });
    return (React.createElement(CardTitle, { classes: cardClasses, dispatch: dispatch, title: viewNames.length === 1
            ? `Assigned you view ${viewNames[0]}`
            : `Assigned you ${viewNames.length} views`, data: data, entityIds: entityIds }));
};
export default CardAssignment;
