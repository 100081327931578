import * as React from "react";
import ViewListAsset from "@warda/library-ui/core/View/ViewListAsset";
import { CtxDispatchViewport } from "../../contexts";
import ViewListAssetChildren from "./ViewListAssetChildren";
import { getAssetPropsFolder } from "../utils";
const ViewListAssetFolder = ({ id, color, size, data, columns, columnsShow, selected, selectedIds, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, onContextMenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const { created } = data;
    const props = React.useMemo(() => {
        return getAssetPropsFolder({
            dispatchViewport,
            color,
            id,
            data,
            selected,
            selectedIds,
            onContextMenu,
            contextmenuOriginAnchor,
            contextmenuOriginTransf,
            contextmenuPosizionZone,
        });
    }, [
        color,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        data,
        dispatchViewport,
        id,
        onContextMenu,
        selected,
        selectedIds,
    ]);
    return (React.createElement(ViewListAsset, { ...props, style: { paddingRight: 15 }, width: size[0], height: size[1], children: React.createElement(ViewListAssetChildren, { columns: columns, columnsShow: columnsShow, created: created }) }));
};
export default ViewListAssetFolder;
