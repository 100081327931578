import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import CardTitle from "../CardTitle";
import CardContent from "../CardContent";
import BtnFile from "./BtnFile";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import { TYPE_FOLDER } from "../../../../constants";
const useStyles = createUseStyles({
    box: {
        display: "flex",
        "flex-direction": "column",
        "overflow-y": "overlay",
    },
    btnOpen: {
        "align-self": "baseline",
        "margin-right": 3,
    },
});
const CardSharedFiles = ({ cardClasses, dispatch, data }) => {
    const classes = useStyles({});
    const { payload } = data;
    const { assetDatas } = payload;
    const nFiles = assetDatas.filter((c) => c.mimeType !== TYPE_FOLDER).length;
    const nFolders = assetDatas.filter((c) => c.mimeType === TYPE_FOLDER).length;
    const [open, setOpen] = React.useState(assetDatas.length === 1);
    const onOpen = React.useCallback(() => setOpen(!open), [open]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CardTitle, { classes: cardClasses, dispatch: dispatch, title: "Shared with you", data: data }),
        React.createElement(CardContent, { classes: cardClasses }, assetDatas.length === 1 ? (React.createElement(BtnFile, { data: assetDatas[0] })) : (React.createElement(React.Fragment, null,
            React.createElement(Btn, { small: true, 
                // variant="bold"
                className: classes.btnOpen, icon: open ? "keyboard_arrow_up" : "keyboard_arrow_down", onClick: onOpen }),
            React.createElement("div", { className: classes.box }, !open ? (React.createElement(Text, { size: 0, children: React.createElement(React.Fragment, null,
                    !nFolders ? null : (React.createElement("span", { children: `${nFolders} folders` })),
                    !!(nFolders && nFiles) ? (React.createElement("span", { children: " and " })) : null,
                    !nFiles ? null : React.createElement("span", { children: `${nFiles} files` })) })) : (assetDatas.map((data) => React.createElement(BtnFile, { key: data.id, data: data })))))))));
};
export default CardSharedFiles;
