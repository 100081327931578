import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Icon from "@warda/library-ui/core/Icon";
import Tooltip from "@warda/library-ui/core/Tooltip";
import classnames from "classnames";
const BUTTON_SIZE = 30;
const useStyles = createUseStyles({
    btnExpand: {
        position: "absolute",
        top: 0,
        height: "-webkit-fill-available",
        cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
        transition: "all 250ms",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 4,
    },
    line: {
        transition: "all 250ms",
        position: "absolute",
        height: "100%",
        width: 0,
        borderStyle: "solid",
        borderWidth: "0 1px 0 0",
        borderColor: "transparent",
    },
    lineHover: {
        borderColor: ({ color }) => color,
    },
    circle: {
        transition: "all 250ms",
        border: `1px solid ${getTheme().colors.grayBorder}`,
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
        maxWidth: BUTTON_SIZE,
        maxHeight: BUTTON_SIZE,
        minWidth: BUTTON_SIZE,
        minHeight: BUTTON_SIZE,
        boxSizing: "border-box",
        top: 10,
        borderRadius: 30,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getTheme().colors.background,
    },
    circleHover: {
        backgroundColor: ({ color }) => color,
        borderColor: ({ color }) => color,
    },
    circleIcon: {
        transition: "all 250ms",
        color: getTheme().colors.typography,
        fontSize: "15px !important",
    },
    circleIconDisabled: {
        color: getTheme().colors.disable,
    },
    circleIconHover: {
        color: getTheme().colors.background,
    },
    click: {
        height: "100%",
        position: "absolute",
        left: ({ direction }) => (direction === "left" ? 0 : undefined),
        right: ({ direction }) => (direction === "right" ? 0 : undefined),
    },
});
const BtnExpand = ({ color = getTheme().colors.theme1, direction, collapsed, onClick, icon, iconClassName, iconStyle, tooltipOpened, tooltipClosed, tooltipDisabled, disabled, component, widthClose = 0, }) => {
    const classes = useStyles({ color, direction, collapsed, disabled });
    const [hover, setHover] = React.useState(false);
    const onMouseLeave = React.useCallback(() => {
        setHover(false);
    }, []);
    const onMouseEnter = React.useCallback(() => {
        setHover(true);
    }, []);
    return (React.createElement("div", { role: "presentation", className: classes.btnExpand, onClick: disabled ? undefined : onClick, onMouseEnter: disabled ? undefined : onMouseEnter, onMouseLeave: disabled ? undefined : onMouseLeave },
        !collapsed ? null : (React.createElement("div", { style: { width: widthClose }, className: classes.click })),
        React.createElement("div", { className: classnames({
                [classes.line]: true,
                [classes.lineHover]: hover && !disabled,
            }) }),
        !!component ? (component({
            collapsed,
            mousehover: hover,
            color,
        })) : (React.createElement(Tooltip, { title: disabled
                ? tooltipDisabled
                : collapsed
                    ? tooltipClosed || "Open panel"
                    : tooltipOpened || "Close panel" },
            React.createElement("div", { className: classnames({
                    [classes.circle]: true,
                    [classes.circleHover]: hover && !disabled,
                    [iconClassName]: !!iconClassName,
                }), style: iconStyle },
                React.createElement(Icon, { className: classnames({
                        [classes.circleIcon]: true,
                        [classes.circleIconDisabled]: disabled,
                        [classes.circleIconHover]: hover && !disabled,
                    }), children: collapsed && !!icon
                        ? icon
                        : direction === "left"
                            ? `chevron_${!collapsed ? "right" : "left"}`
                            : `chevron_${!collapsed ? "left" : "right"}` }))))));
};
export default BtnExpand;
