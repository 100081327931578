import { fetchCookieJwtWithRefreshToken } from "./fetchCookieJwt";
import PERMS from "../permissions";
import hasPerms from "../utils/hasPerms";
import { JsonstoreContext, PRODUCT_ID } from "../constantsKey";
import { getJSPref } from "./fetchesApiJsonStore";
import apiUrls from "./apiUrls";
const addUserName = (u) => ({
    ...u,
    name: `${u.profileData.firstName} ${u.profileData.lastName}`,
});
export const getUsersData = async (permissions) => {
    try {
        if (hasPerms([PERMS.users_admin_get_users_data], permissions)) {
            const { url, method } = apiUrls.getAdminUsersData;
            const { users } = await fetchCookieJwtWithRefreshToken({
                url: url(),
                method,
            });
            return users.map(addUserName);
        }
        if (hasPerms([PERMS.users_get_user_data], permissions)) {
            const { url, method } = apiUrls.getUsersData;
            const { users } = await fetchCookieJwtWithRefreshToken({
                url: url(),
                method,
            });
            return users.map(addUserName);
        }
        throw "no_perms";
    }
    catch {
        return [];
    }
};
export const getApplications = async () => {
    try {
        const { url, method } = apiUrls.getTenant;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return {
            groupId: res.groupId,
            applications: res.applications.sort(),
            tenants: res.tenants
                .sort((a, b) => {
                if (a.label > b.label)
                    return 1;
                if (a.label < b.label)
                    return -1;
                return 0;
            })
                .map((t) => ({
                id: t.tenantId,
                label: t.label,
                active: t.tenantId === res.tenantId,
            })),
        };
    }
    catch {
        return {
            groupId: "",
            applications: [],
            tenants: [],
        };
    }
};
export const createUser = async ({ permissions, userData }) => {
    const isAdmin = hasPerms([PERMS.users_admin_manage_is_warda_flag], permissions);
    const { url, method } = isAdmin
        ? apiUrls.createUserAdmin
        : apiUrls.createUser;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            applications: userData.applications,
            email: userData.profileData.email,
            firstName: userData.profileData.firstName,
            lastName: userData.profileData.lastName,
            isWarda: userData.profileData.isWarda,
            roles: userData.roles.map((r) => r.roleId),
        },
    });
};
export const modifyUser = async ({ permissions, userId, userData, }) => {
    const isAdmin = hasPerms([PERMS.users_admin_manage_is_warda_flag], permissions);
    const { url, method } = isAdmin
        ? apiUrls.modifyUserAdmin
        : apiUrls.modifyUser;
    await fetchCookieJwtWithRefreshToken({
        url: url(userId),
        method,
        jsonBody: userData,
    });
};
export const enableTenantUser = async ({ email, applications, roles, }) => {
    const { url, method } = apiUrls.enableTenantUser;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            email,
            applications,
            roles,
        },
    });
};
export const getUsers = async () => {
    const { url, method } = apiUrls.getUsers;
    const data = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
    });
    return data;
};
export const getCurrentUser = async () => {
    const { url, method } = apiUrls.getUserProfile;
    const u = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
    });
    return addUserName(u);
};
export const getUserDatas = async () => {
    var _a, _b;
    const prefContext = JsonstoreContext.userPreferences;
    const [all, userProfile, jsMap] = await Promise.all([
        getUsers(),
        getCurrentUser(),
        getJSPref([prefContext]),
    ]);
    return {
        userProfile,
        preferencesJsId: (_a = jsMap[prefContext][0]) === null || _a === void 0 ? void 0 : _a.id,
        preferences: (_b = jsMap[prefContext][0]) === null || _b === void 0 ? void 0 : _b.payload,
        m2ms: all.m2m || [],
        users: (all.users || []).map(addUserName),
    };
};
export const resendUserEmail = async (email, groupId) => {
    const { url, method } = apiUrls.resendUserEmail;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: { email, groupId },
    });
};
export const updateUserEmail = async (email, userId) => {
    const { url, method } = apiUrls.updateUserEmail;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(userId),
        method,
        jsonBody: { email },
    });
    return res;
};
export const putTenantStatus = async ({ userId, status }) => {
    const { url, method } = apiUrls.putTenantStatus;
    await fetchCookieJwtWithRefreshToken({
        url: url(userId),
        method,
        jsonBody: { status },
    });
};
export const getPermissionsData = async (permissions) => {
    if (hasPerms([PERMS.users_admin_get_permissions], permissions)) {
        const { url, method } = apiUrls.getAdminPermissions;
        const items = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return items;
    }
    if (hasPerms([PERMS.users_get_permissions], permissions)) {
        const { url, method } = apiUrls.getPermissions;
        const items = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return items;
    }
    return [];
};
export const getM2ms = async (permissions) => {
    if (hasPerms([PERMS.users_admin_get_m2ms_data], permissions)) {
        const { url, method } = apiUrls.getAdminM2msData;
        const { m2m } = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return m2m;
    }
    if (hasPerms([PERMS.users_get_m2m_data], permissions)) {
        const { url, method } = apiUrls.getM2msData;
        const { m2m } = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return m2m;
    }
    return [];
};
const getRoleData = (array) => {
    return array.map((r) => {
        return {
            ...r,
            permissions: r.permissions.find(({ applicationId }) => applicationId === PRODUCT_ID).permissions,
        };
    });
};
export const getRoles = async (permissions) => {
    if (hasPerms([PERMS.users_admin_get_roles], permissions)) {
        const { url, method } = apiUrls.getAdminRoles;
        const items = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return getRoleData(items);
    }
    if (hasPerms([PERMS.users_get_roles], permissions)) {
        const { url, method } = apiUrls.getRoles;
        const items = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
        });
        return getRoleData(items);
    }
    return [];
};
export const getTenantConfig = async ({ tenantId, aggregate, }) => {
    const { url, method } = apiUrls.getTenantConfig;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(tenantId, aggregate),
        method,
    });
    return !res ? "" : JSON.stringify(res, null, "\t");
};
export const putTenantConfig = async (jsonString) => {
    let jsonBody = {};
    try {
        jsonBody = JSON.parse(jsonString);
    }
    catch {
        return {
            success: false,
            message: "Invalid JSON",
        };
    }
    try {
        const { url, method } = apiUrls.putTenantConfig;
        const { message } = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody,
        });
        return {
            success: true,
            message,
        };
    }
    catch (err) {
        const res = await err.response.json();
        return {
            success: false,
            message: String(res.error),
        };
    }
};
const getJsonRoleData = (d) => ({
    roleId: d.roleId,
    label: d.label,
    permissions: {
        [PRODUCT_ID]: d.permissions,
    },
    description: d.description,
    enabled: d.enabled,
    visibleInTenants: d.visibleInTenants,
});
export const putRoles = async (data, permissions) => {
    const jsonBody = getJsonRoleData(data);
    if (hasPerms([PERMS.users_admin_upsert_tenant_role], permissions)) {
        const { url, method } = apiUrls.putAdminRoles;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody,
        });
        return null;
    }
    if (hasPerms([PERMS.users_upsert_tenant_role], permissions)) {
        const { url, method } = apiUrls.putRoles;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody,
        });
        return null;
    }
};
export const postRoles = async (data, permissions) => {
    const jsonBody = getJsonRoleData(data);
    if (hasPerms([PERMS.users_admin_upsert_tenant_role], permissions)) {
        const { url, method } = apiUrls.postAdminRoles;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody,
        });
        return null;
    }
    if (hasPerms([PERMS.users_upsert_tenant_role], permissions)) {
        const { url, method } = apiUrls.postRoles;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody,
        });
        return null;
    }
};
export const deleteRoles = async (roleId) => {
    const { url, method } = apiUrls.deleteRoles;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: { roleId },
    });
};
export const patchPermission = async ({ id, label, description, }) => {
    const { url, method } = apiUrls.patchPermission;
    await fetchCookieJwtWithRefreshToken({
        url: url(id),
        method,
        jsonBody: { label, description },
    });
};
export const revokeToken = async (ids) => {
    const { url, method } = apiUrls.revokeToken;
    const res = await Promise.all(ids.map((userId) => fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: { userId },
    })));
    return res;
};
