import * as React from "react";
import { CtxDispatchFilters } from "./contexts";
import { ACT_PANEL } from "./reducer";
import BtnPulse from "@warda/library-ui/core/BtnPulse";
import { getActiveFilters, areFiltersUseful, getSlcSaveValues, } from "./utils";
import { MANAGER_GROUPS } from "../../constants";
import { CtxSetUserPref, CtxTenantM2ms, CtxTenantUsers, CtxUserData, CtxUserPref, } from "../contexts";
import WindowMngSets from "@warda/library-ui/core/WindowMngSets";
import { getItemsShared, onCreateSet, onRemoveSet, onSharedUpsert, onSharedRemove, onUpdateSet, } from "../../api/fetchesApiJsonStore";
import getMngSetsSelected from "../../utils/getMngSetsSelected";
import { getTheme } from "@warda/library-ui/theme";
import { PREF_KEY } from "../../constantsKey";
import { getUser } from "@warda/library-ui/utils/getUser";
const getManagerPosition = (managerRef) => {
    if (!managerRef.current)
        return [0, 0];
    const { left, top } = managerRef.current.getBoundingClientRect();
    return [left + 30, top];
};
const BtnManager = ({ open, filters, mngSets, context, onError, onChange, levels, enableShared, enableLevels, }) => {
    const userPref = React.useContext(CtxUserPref);
    const userData = React.useContext(CtxUserData);
    const dispatchPanel = React.useContext(CtxDispatchFilters);
    const setPreferences = React.useContext(CtxSetUserPref);
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const managerRef = React.useRef(null);
    const keepValue = getSlcSaveValues();
    const { slcSetId } = getMngSetsSelected(context, userPref, mngSets);
    const managerItems = filters.map((f) => ({
        ...f,
        groupId: f.attributeFamily,
    }));
    const filtersRendered = getActiveFilters({
        preferences: userPref,
        context,
        mngSets,
        filters,
    });
    const getManagerChip = React.useCallback((chip, setPayload, shared) => {
        const c = {
            id: chip.id,
            label: chip.label,
            tooltip: chip.tooltip,
            mandatory: chip.mandatory,
            color: getTheme().colors.theme1,
        };
        const chipFiltered = areFiltersUseful([
            {
                ...filters.find((f) => f.id === c.id),
                ...setPayload.items.find((s) => s.id === c.id),
            },
        ]);
        if (!shared && chipFiltered) {
            c.iconRight = "library_add_check";
            c.disabled = true;
            c.tooltip = "⚠️ Unable to remove while the filter is valued";
        }
        return c;
    }, [filters]);
    const onManagerToggle = React.useCallback(() => {
        dispatchPanel({ type: ACT_PANEL.OPEN_MANAGER, open: !open });
    }, [dispatchPanel, open]);
    const onSetsChanged = React.useCallback((context, slcId, sets) => {
        onChange({
            context,
            slcId,
            newId: slcSetId !== slcId,
            sets,
            keepValue,
            updateFilters: true,
        });
    }, [keepValue, onChange, slcSetId]);
    const [positionX, positionY] = getManagerPosition(managerRef);
    const onLevelsUpdate = React.useCallback(async (context, newLevels) => {
        setPreferences({
            [PREF_KEY.productsFiltersSetLevels]: newLevels,
        });
    }, [setPreferences]);
    const getItemsLevels = React.useCallback(async () => {
        return levels;
    }, [levels]);
    const getMngUser = React.useCallback((userId) => {
        return getUser(userId, users, m2ms);
    }, [m2ms, users]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BtnPulse, { pulse: !filtersRendered.length, cmpRef: managerRef, icon: "filter_list", tooltip: "Open your Filters Sets", onClick: onManagerToggle, selected: open }),
        React.createElement(WindowMngSets, { loading: false, titleText: "Your Filters Sets", titleInfo: [
                "Here you can customize what filters you see in your Filters Panel.",
                "You can create different Sets of Filters for different use cases.",
            ], selectedId: slcSetId, ownerId: userData.userId, context: context, open: open, chipMaxSlc: 25, chipValues: managerItems, chipGroups: MANAGER_GROUPS, sets: mngSets, positionX: positionX, positionY: positionY, onClose: onManagerToggle, getChip: getManagerChip, onSetsChanged: onSetsChanged, onCreateSet: onCreateSet, onRemoveSet: onRemoveSet, onUpdateSet: onUpdateSet, onError: onError, getItemsShared: getItemsShared, onSharedUpsert: onSharedUpsert, onSharedDelete: onSharedRemove, enableShared: enableShared, getItemsLevels: getItemsLevels, onLevelsUpdate: onLevelsUpdate, enableLevels: enableLevels, getUser: getMngUser })));
};
export default BtnManager;
