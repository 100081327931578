import without from "lodash-es/without";
export var ACTION;
(function (ACTION) {
    ACTION["INITIALIZE"] = "INITIALIZE";
    ACTION["WINDOW_OPEN"] = "WINDOW_OPEN";
    ACTION["WINDOW_CLOSE"] = "WINDOW_CLOSE";
    //
    ACTION["JOB_DELETE_ALL"] = "JOB_DELETE_ALL";
    ACTION["JOB_DELETE_ONE"] = "JOB_DELETE_ONE";
    ACTION["JOB_NEWITEMS_TO_GET"] = "JOB_NEWITEMS_TO_GET";
    ACTION["JOB_NEWITEMS_LOADED"] = "JOB_NEWITEMS_LOADED";
    ACTION["JOB_NEWITEMS_REMOVE"] = "JOB_NEWITEMS_REMOVE";
})(ACTION || (ACTION = {}));
export const reducerInitState = {
    initialize: false,
    windowOpen: false,
    jobItems: [],
    jobNewIdsToGet: [],
    jobDeletingAll: false,
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.JOB_DELETE_ALL:
            newState.jobDeletingAll = !!action.toFetch;
            if (action.toFetch) {
                newState.jobItems = reducerInitState.jobItems;
            }
            return newState;
        case ACTION.JOB_NEWITEMS_TO_GET: {
            const array = Array.from(newState.jobNewIdsToGet);
            action.ids.forEach((j) => {
                if (!new Set(array).has(j))
                    array.push(j);
            });
            newState.jobNewIdsToGet = array;
            return newState;
        }
        case ACTION.JOB_NEWITEMS_REMOVE: {
            const jobId = action.jobId;
            const array = Array.from(newState.jobNewIdsToGet);
            const index = array.findIndex((i) => i === jobId);
            array.splice(index, 1);
            newState.jobNewIdsToGet = array;
            return newState;
        }
        case ACTION.JOB_NEWITEMS_LOADED: {
            const itemToAdd = action.newItem;
            {
                const array = Array.from(newState.jobNewIdsToGet);
                const index = array.findIndex((i) => i === itemToAdd.jobId);
                array.splice(index, 1);
                newState.jobNewIdsToGet = array;
            }
            {
                const array = Array.from(newState.jobItems);
                const index = array.findIndex((i) => i.jobId === itemToAdd.jobId);
                if (index === -1) {
                    array.unshift(itemToAdd);
                }
                else {
                    array.splice(index, 1, itemToAdd);
                }
                newState.jobItems = array;
            }
            return newState;
        }
        case ACTION.JOB_DELETE_ONE: {
            const items = Array.from(newState.jobItems);
            const itemToRemove = newState.jobItems.find((n) => n.jobId === action.id);
            newState.jobItems = without(items, itemToRemove);
            return newState;
        }
        case ACTION.INITIALIZE:
            newState.initialize = true;
            newState.jobItems = action.job;
            return newState;
        case ACTION.WINDOW_OPEN:
            newState.windowOpen = action.open;
            return newState;
        default:
            return newState;
    }
};
