import * as React from "react";
import { createUseStyles } from "react-jss";
import Preview from "@warda/library-ui/core/Preview";
import getFileIcon from "../../Viewer/getFileIcon";
import apiUrls from "../../../api/apiUrlsAnonymous";
import { getTheme } from "@warda/library-ui/theme";
import { ACT_VPORT } from "../reducer";
import InfoGeneral from "./InfoGeneral";
import { useLocation } from "react-router-dom";
import { SheetLayout, SheetStatus, } from "../../../interfaces";
import { DrawerDetail } from "../../Drawer";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Divider from "@warda/library-ui/core/Divider";
import Text from "@warda/library-ui/core/Text";
import BtnZoom from "../../AreaFiles_DEPRECATED/PanelDetail/BtnZoom";
import PanelZoom from "./PanelZoom";
import { CtxDispatchViewport } from "../contexts";
import { TYPE_FOLDER } from "../../../constants";
import getThumbRendition from "../../../utils/getThumbRendition";
import { anonymousGetMediaInfoDA } from "../../../api/fetchesApiAnonymous";
const previewSize = 380;
const useStyles = createUseStyles({
    sheetDefault: {
        height: "100%",
        flex: 1,
        "overflow-x": "hidden",
        "overflow-y": "overlay",
        position: "relative",
        "padding-bottom": 50,
    },
});
const PanelDetail = ({ detailSheet, assetData }) => {
    const dispatch = React.useContext(CtxDispatchViewport);
    const searchString = useLocation().search;
    const query = new URLSearchParams(searchString);
    const link = query.get("link");
    const classes = useStyles({});
    const zoomOpen = detailSheet === SheetLayout.MEDIA_ZOOM;
    const [mediaData, setMediaData] = React.useState(null);
    const { documentRepoId, mimeType } = assetData || {};
    const icon = getFileIcon(mimeType);
    const srcUrl = apiUrls.getRenditionPublic.url(link, documentRepoId, getThumbRendition(2, mimeType));
    const onSheetReset = React.useCallback(() => {
        dispatch({ type: ACT_VPORT.ITEMS_DESELECT });
    }, [dispatch]);
    const onSheetChange = React.useCallback((layout) => {
        dispatch({ type: ACT_VPORT.SHEET_LAYOUT, layout });
    }, [dispatch]);
    const onZoomClose = React.useCallback(() => {
        dispatch({
            type: ACT_VPORT.SHEET_LAYOUT,
            layout: SheetLayout.OPEN,
        });
    }, [dispatch]);
    const onZoomOpen = React.useCallback(() => {
        dispatch({
            type: ACT_VPORT.SHEET_LAYOUT,
            layout: SheetLayout.MEDIA_ZOOM,
        });
    }, [dispatch]);
    // get full detail
    React.useEffect(() => {
        (async () => {
            try {
                if (!!documentRepoId && mimeType !== TYPE_FOLDER) {
                    const newMediaData = await anonymousGetMediaInfoDA(documentRepoId, link);
                    if (!newMediaData)
                        throw "ERROR";
                    setMediaData(newMediaData);
                }
                else {
                    throw "RESET";
                }
            }
            catch {
                setMediaData(null);
            }
        })();
    }, [documentRepoId, link, mimeType]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PanelZoom, { detailSheet: detailSheet, assetData: assetData, onClose: onZoomClose }),
        React.createElement(DrawerDetail, { layout: detailSheet, layoutOnChange: onSheetChange, onReset: onSheetReset, status: !assetData ? SheetStatus.PHOLDER : SheetStatus.VISIBLE, switchIconStyle: { top: 15 } },
            React.createElement(Toolbar, { style: {
                    backgroundColor: getTheme().colors.grayDrawer,
                    padding: "5px 10px 5px 25px",
                } },
                React.createElement(Text, { ellipsis: true, weight: "bolder", children: assetData && assetData.name }),
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(BtnZoom, { zoomOpen: zoomOpen, onZoomClose: onZoomClose, onZoomOpen: onZoomOpen })),
            React.createElement(Divider, null),
            React.createElement("div", { className: classes.sheetDefault },
                React.createElement(Preview, { color: getTheme().colors.theme1, srcUrl: srcUrl, mimeType: mimeType, placeholderIcon: icon, previewHeight: previewSize, previewWidth: previewSize, onClick: onZoomOpen }),
                React.createElement(Divider, null),
                React.createElement(InfoGeneral, { assetData: assetData, mediaData: mediaData })))));
};
export default PanelDetail;
