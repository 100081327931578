import * as React from "react";
import { createUseStyles } from "react-jss";
import BtnExpand from "./BtnExpand";
import classnames from "classnames";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
import { ErrorBoundary } from "react-error-boundary";
import PlaceholderError from "@warda/library-ui/core/PlaceholderError";
import { DRAWER_WIDTH } from "../../constants";
import { getTheme } from "@warda/library-ui/theme";
import Btn from "@warda/library-ui/core/Btn";
import emptyFn from "@warda/library-ui/utils/emptyFn";
const dBackdropOnClick = () => console.log("[Drawer] backdropOnClickd");
const dSwitchOnClick = () => console.log("[Drawer] switchOnClick");
const dOnReset = () => console.log("[Drawer] onReset");
const dOnError = (error, info) => console.log("[Drawer] onError", { error, info });
const timeout = 500;
const useStyles = createUseStyles({
    drawer: {
        zIndex: ({ zIndex }) => zIndex,
        minWidth: ({ widthClose }) => widthClose,
        width: 0,
        flex: 0,
        transition: `all ${timeout}ms`,
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        overflowY: "visible",
        overflowX: "visible",
        backgroundColor: getTheme().colors.background,
    },
    drawerOpen: {
        minWidth: ({ width }) => width,
    },
    drawerCursor: {
        cursor: "pointer",
    },
    drawerContent: {
        zIndex: ({ zIndex }) => zIndex,
        height: "100%",
        display: "flex",
        flex: 2,
        backgroundColor: getTheme().colors.grayDrawer,
        flexDirection: "column",
        alignItems: "stretch",
        boxSizing: "border-box",
        minWidth: ({ width }) => width,
        overflowY: ({ overflowY }) => (overflowY ? "overlay" : "hidden"),
        overflowX: ({ overflowX }) => (overflowX ? "overlay" : "hidden"),
        textAlign: "center",
    },
    backdrop: {
        zIndex: ({ zIndex }) => zIndex,
        backgroundColor: "transparent",
        position: "fixed",
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
    },
});
const Drawer = ({ className, style, open, children, width = DRAWER_WIDTH, widthClose = 0, onReset = dOnReset, onError = dOnError, overflowY = true, overflowX = true, border, borderShadow = true, switchHide = true, switchIcon, switchIconClassName, switchIconStyle, switchOnClick = dSwitchOnClick, switchColor, switchTooltipOpened, switchTooltipClosed, switchTooltipDisabled, switchDisabled, switchComponent, backdropOpen, backdropOnClick = dBackdropOnClick, backdropClassName, backdropStyle, drawerChildren, drawerClassName, drawerStyle, zIndex, onEnter = emptyFn, onEntered = emptyFn, onExit = emptyFn, onExited = emptyFn, }) => {
    const [rendered, setRendered] = React.useState(open);
    const refIsOpened = React.useRef(false);
    const classes = useStyles({
        width: width + 1,
        widthClose,
        overflowY,
        overflowX,
        zIndex,
    });
    const drawerClick = widthClose !== 0 && !open && !switchHide;
    const FallbackComponent = React.useCallback(() => (React.createElement(PlaceholderError, { messages: [
            "The error could be caused by data inconsistency.",
            "Please wait some minute and try again.",
        ], children: React.createElement(Btn, { color: getTheme().colors.theme1, variant: "bold", label: "RESET", onClick: onReset }) })), [onReset]);
    React.useEffect(() => {
        (async () => {
            const call = open !== refIsOpened.current;
            refIsOpened.current = open;
            if (open) {
                setRendered(true);
                if (call)
                    onEnter();
                await new Promise((resolve) => setTimeout(resolve, timeout));
                if (call)
                    onEntered();
            }
            else {
                if (call)
                    onExit();
                await new Promise((resolve) => setTimeout(resolve, timeout));
                if (call)
                    onExited();
                setRendered(false);
            }
        })();
    }, [onEnter, onEntered, onExit, onExited, open]);
    return (React.createElement(React.Fragment, null,
        !backdropOpen ? null : (React.createElement("div", { role: "presentation", className: classnames({
                [classes.backdrop]: true,
                [backdropClassName]: !!backdropClassName,
            }), style: backdropStyle, onClick: backdropOnClick })),
        React.createElement("div", { role: "presentation", className: classnames({
                [classes.drawer]: true,
                [classes.drawerOpen]: open,
                [classes.drawerCursor]: drawerClick,
                [drawerClassName]: !!drawerClassName,
            }), style: drawerStyle, onClick: drawerClick ? switchOnClick : undefined },
            switchHide ? null : (React.createElement(BtnExpand, { color: switchColor, direction: border, collapsed: !rendered, onClick: switchOnClick, icon: switchIcon, iconClassName: switchIconClassName, iconStyle: switchIconStyle, tooltipOpened: switchTooltipOpened, tooltipClosed: switchTooltipClosed, tooltipDisabled: switchTooltipDisabled, disabled: switchDisabled, component: switchComponent, widthClose: widthClose })),
            drawerChildren,
            !border ? null : (React.createElement(BorderShadow, { position: border, shadow: borderShadow })),
            !rendered ? null : (React.createElement("div", { style: { overflow: "hidden", width: "-webkit-fill-available" } },
                React.createElement(ErrorBoundary, { fallbackRender: FallbackComponent, onError: onError },
                    React.createElement("div", { className: classnames({
                            [classes.drawerContent]: true,
                            [className]: !!className,
                        }), style: style, children: children })))))));
};
export default Drawer;
