import * as React from "react";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import classnames from "classnames";
import BtnBase from "@warda/library-ui/core/BtnBase";
import Preview, { PreviewRenderPlaceholder, } from "@warda/library-ui/core/Preview";
import { getTheme } from "@warda/library-ui/theme";
import AssetCheckbox from "./AssetCheckbox";
import CircularProgress from "@warda/library-ui/core/CircularProgress";
import emptyFn from "@warda/library-ui/utils/emptyFn";
import PopoverList from "@warda/library-ui/core/PopoverList";
import mixColors from "@warda/library-ui/utils/mixColors";
const BADGE_SIZE = 20;
const useStyles = createUseStyles({
    asset: {
        width: "fit-content",
        height: "fit-content",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        overflow: "hidden",
        border: `1px solid ${getTheme().colors.grayBorder}`,
        backgroundColor: getTheme().colors.background,
        boxSizing: "content-box",
        borderRadius: getTheme().borderRadius,
        alignItems: "stretch",
        transition: "250ms all",
        "&:hover": {
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        },
    },
    assetSelected: {
        borderColor: ({ color }) => color,
        backgroundColor: ({ color }) => mixColors(0.2, getTheme().colors.background, color),
        boxShadow: "none !important",
    },
    assetDisabled: {
        opacity: 0.25,
    },
    children: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 40,
        boxSizing: "border-box",
        padding: "0 10px",
        width: ({ previewWidth }) => previewWidth,
        maxWidth: ({ previewWidth }) => previewWidth,
        minWidth: ({ previewWidth }) => previewWidth,
    },
    extension: {
        position: "absolute",
        zIndex: 1,
        top: 10,
        right: 10,
        color: getTheme().colors.typography,
        backgroundColor: "rgba(250, 250, 250, 0.8)",
        borderRadius: getTheme().borderRadius,
        padding: "0 5px",
        height: BADGE_SIZE,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    srcLoading: {
        height: ({ previewHeight }) => previewHeight,
        width: ({ previewWidth }) => previewWidth,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
const AssetPreview = ({ className, style, color = getTheme().colors.theme1, contextmenu = [], onSelect = emptyFn, onClick = emptyFn, onDoubleClick = emptyFn, onContextMenu = emptyFn, onMouseEnter = emptyFn, onMouseLeave = emptyFn, placeholderIcon, placeholderLabel, disabled, selected, srcUrl, mimeType, srcLoading, previewHeight, previewWidth, children, extension, selectable, }) => {
    const classes = useStyles({ color, previewHeight, previewWidth });
    const [mouseHover, setMouseHover] = React.useState(false);
    const [contextCoord, setContextCoord] = React.useState([0, 0]);
    const cbMouseEnter = React.useCallback((event) => {
        setMouseHover(true);
        onMouseEnter(event);
    }, [onMouseEnter]);
    const cbMouseLeave = React.useCallback((event) => {
        setMouseHover(false);
        onMouseLeave(event);
    }, [onMouseLeave]);
    const cbMenuOpen = React.useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!!contextmenu.length) {
            setContextCoord([event.clientX, event.clientY]);
        }
        onContextMenu(event, {
            keyDownCtrl: false,
            keyDownMeta: false,
            isMobile: false,
        });
    }, [contextmenu.length, onContextMenu]);
    const cbMenuClose = React.useCallback(() => {
        setContextCoord([0, 0]);
    }, []);
    return (React.createElement(BtnBase, { style: style, className: classnames({
            [classes.asset]: true,
            [classes.assetSelected]: selected,
            [classes.assetDisabled]: disabled,
            [className]: !!className,
        }), onClick: onClick, onDoubleClick: onDoubleClick, onMouseEnter: cbMouseEnter, onMouseLeave: cbMouseLeave, onContextMenu: cbMenuOpen },
        !srcUrl ? (React.createElement("div", { className: classes.srcLoading }, srcLoading ? (React.createElement(CircularProgress, null)) : (React.createElement(PreviewRenderPlaceholder, { size: Math.min(previewWidth, previewHeight), icon: placeholderIcon, label: placeholderLabel })))) : (React.createElement(Preview, { color: color, mousehover: mouseHover, placeholderIcon: placeholderIcon, placeholderLabel: placeholderLabel, srcUrl: srcUrl, mimeType: mimeType, previewHeight: previewHeight, previewWidth: previewWidth, onClick: onClick, onDoubleClick: onDoubleClick })),
        !selectable ? null : (React.createElement(AssetCheckbox, { size: BADGE_SIZE, color: color, selected: selected, onClick: onSelect })),
        !extension ? null : (React.createElement("div", { className: classes.extension },
            React.createElement(Text, { children: extension.toLocaleUpperCase() }))),
        !children ? null : (React.createElement("div", { className: classes.children, children: children })),
        React.createElement(PopoverList, { open: !!contextCoord[0] || !!contextCoord[1], onClose: cbMenuClose, anchorReference: "anchorPosition", anchorPosition: {
                top: contextCoord[1],
                left: contextCoord[0],
            }, actions: contextmenu })));
};
export default AssetPreview;
