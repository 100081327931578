import * as queryString from "query-string";
import { BASE_URL } from "../constants";
const apiUrls = {
    delJsonstorePref: {
        url: () => `${BASE_URL}/jsonstore/user-preferences`,
        method: "DELETE",
    },
    addJsonstorePref: {
        url: () => `${BASE_URL}/jsonstore/user-preferences`,
        method: "POST",
    },
    putJsonstorePref: {
        url: () => `${BASE_URL}/jsonstore/user-preferences`,
        method: "PUT",
    },
    getJsonstorePref: {
        url: (contexts, app) => `${BASE_URL}/jsonstore/user-preferences?${queryString.stringify({
            contexts: contexts.join(),
            app,
        })}`,
        method: "GET",
    },
    getJsonstoreShared: {
        url: (context, app) => `${BASE_URL}/jsonstore/share-preferences?app=${app}&contexts=${context}`,
        method: "GET",
    },
    upsertJsonstoreShared: {
        url: () => `${BASE_URL}/jsonstore/share-preferences`,
        method: "POST",
    },
    deleteJsonstoreShared: {
        url: () => `${BASE_URL}/jsonstore/share-preferences`,
        method: "DELETE",
    },
};
export default apiUrls;
