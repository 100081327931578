import * as React from "react";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { typeDocument, typeImage, typeVideo, } from "@warda/library-ui/utils/mimeTypes";
import ViewerVideo from "@warda/library-ui/core/ViewerVideo";
import ViewerBox from "../../ViewerBox";
import apiUrls from "../../../api/apiUrlsAnonymous";
import { RENDITIONS, } from "../../../interfaces";
import getFileIcon from "../../Viewer/getFileIcon";
import PyramidZoom from "@warda/library-ui/core/PyramidZoom";
import { getViewerBoxData, getViewerImgSrcFirmed, } from "../../../api/fetchesApiAnonymous";
const Viewer = ({ file }) => {
    const { fileId, mimeType } = file.data;
    if (new Set(typeVideo).has(mimeType)) {
        return (React.createElement(ViewerVideo, { src: apiUrls.mediaDownload.url(fileId, RENDITIONS.video_HQ) }));
    }
    if (new Set(typeImage).has(mimeType)) {
        return (React.createElement(PyramidZoom, { src: apiUrls.mediaZoom.url(fileId), getSrcFirmed: getViewerImgSrcFirmed }));
    }
    if (new Set(typeDocument).has(mimeType)) {
        return React.createElement(ViewerBox, { fileId: fileId, getData: getViewerBoxData });
    }
    return (React.createElement("div", { style: {
            position: "relative",
            flex: 1,
            display: "flex",
            alignItems: "stretch",
            overflow: "hidden",
        } },
        React.createElement(Placeholder, { icon: getFileIcon(mimeType), iconStyle: { marginBottom: 10 }, label: "Zoom unavailable" })));
};
export default Viewer;
