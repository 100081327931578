import Icon from "@warda/library-ui/core/Icon";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import * as React from "react";
import isEmpty from "lodash-es/isEmpty";
import BtnBase from "@warda/library-ui/core/BtnBase";
import Text from "@warda/library-ui/core/Text";
import emptyFn from "@warda/library-ui/utils/emptyFn";
import mixColors from "@warda/library-ui/utils/mixColors";
import { getTheme } from "@warda/library-ui/theme";
import PopoverList from "@warda/library-ui/core/PopoverList";
const useStyles = createUseStyles({
    asset: {
        "min-height": ({ assetHeight }) => assetHeight,
        "max-height": ({ assetHeight }) => assetHeight,
        height: ({ assetHeight }) => assetHeight,
        "min-width": ({ assetWidth }) => assetWidth,
        "max-width": ({ assetWidth }) => assetWidth,
        width: ({ assetWidth }) => assetWidth,
        display: "flex",
        "flex-direction": "row",
        cursor: "pointer",
        overflow: "hidden",
        border: `1px solid ${getTheme().colors.grayBorder}`,
        "background-color": getTheme().colors.background,
        "align-items": "center",
        "border-radius": getTheme().borderRadius,
        "box-sizing": "content-box",
        transition: "250ms all",
        "&:hover": {
            "box-shadow": "0px 0px 10px rgba(0, 0, 0, 0.25)",
        },
    },
    assetSelected: {
        "border-color": ({ color }) => color,
        "background-color": ({ color }) => mixColors(0.2, getTheme().colors.background, color),
        "box-shadow": "none !important",
    },
    assetDisabled: {
        opacity: 0.25,
    },
    label: {
        margin: "0 10px 0 0",
        "user-select": "none",
    },
    icon: {
        "font-size": 20,
        margin: "0 8px",
        color: getTheme().colors.typography,
    },
});
const AssetFolder = ({ className, style, contextmenu = [], label, labelClassName, labelStyle, icon = "folder_open", iconClassName, iconStyle, onClick = emptyFn, onDoubleClick = emptyFn, onContextMenu = emptyFn, color = getTheme().colors.theme1, selected, disabled, children, assetHeight, assetWidth, }) => {
    const classes = useStyles({ color, assetHeight, assetWidth });
    const [contextCoord, setContextCoord] = React.useState([0, 0]);
    const cbMenuClose = React.useCallback(() => {
        setContextCoord([0, 0]);
    }, []);
    const cbMenuOpen = React.useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!isEmpty(contextmenu)) {
            setContextCoord([event.clientX, event.clientY]);
        }
        onContextMenu(event, {
            keyDownCtrl: false,
            keyDownMeta: false,
            isMobile: false,
        });
    }, [contextmenu, onContextMenu]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BtnBase, { style: style, className: classnames({
                [classes.asset]: true,
                [classes.assetSelected]: selected,
                [classes.assetDisabled]: disabled,
                [className]: !!className,
            }), onClick: onClick, onDoubleClick: onDoubleClick, onContextMenu: cbMenuOpen },
            React.createElement(Icon, { className: classnames({
                    [classes.icon]: true,
                    [className]: !!iconClassName,
                }), style: {
                    color: selected ? color : undefined,
                    ...iconStyle,
                }, children: icon }),
            React.createElement(Text, { ellipsis: true, className: classnames({
                    [classes.label]: true,
                    [className]: !!labelClassName,
                }), style: labelStyle, children: label }),
            React.createElement("div", { style: { flex: 1 } }),
            children),
        React.createElement(PopoverList, { open: !!contextCoord[0] || !!contextCoord[1], onClose: cbMenuClose, anchorReference: "anchorPosition", anchorPosition: {
                top: contextCoord[1],
                left: contextCoord[0],
            }, actions: contextmenu })));
};
export default AssetFolder;
