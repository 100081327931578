import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import Icon from "@warda/library-ui/core/Icon";
const useStyles = createUseStyles({
    placeholder: {
        margin: "30px 0 50px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
    },
});
const WindowPlaceholder = () => {
    const classes = useStyles({});
    return (React.createElement("div", { className: classes.placeholder },
        React.createElement(Icon, { style: { fontSize: 80, color: getTheme().colors.disable }, children: "notifications" }),
        React.createElement(Text, { style: { color: getTheme().colors.disable }, children: "you have no notifications" })));
};
export default WindowPlaceholder;
