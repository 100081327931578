import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import BtnBase from "@warda/library-ui/core/BtnBase";
import { ACTION } from "../reducer";
import { markAsReadNotifDrc } from "../../../api/fetchesApiNotifications";
const color = getTheme().colors.msgInfo;
const useStyles = createUseStyles({
    markread: {
        marginRight: 10,
        padding: 6,
        borderRadius: 50,
        backgroundColor: ({ markedAsRead }) => markedAsRead ? "transparent" : color,
        border: ({ markedAsRead }) => `1px solid ${markedAsRead ? getTheme().colors.disable : color}`,
    },
});
const BtnMarkRead = ({ dispatch, id, markedAsRead }) => {
    const classes = useStyles({ markedAsRead });
    const [fetch, setFetch] = React.useState(false);
    const [click, setClick] = React.useState(false);
    const onClick = React.useCallback(() => setClick(true), []);
    React.useEffect(() => {
        (async () => {
            if (click && !fetch) {
                try {
                    setFetch(true);
                    const mark = !markedAsRead;
                    dispatch({ type: ACTION.DRC_MARKREAD_ONE, id, mark });
                    await markAsReadNotifDrc(mark, id);
                }
                catch (err) {
                    console.log("[BtnMarkRead] error", err);
                }
                setClick(false);
                setFetch(false);
            }
        })();
    }, [click, dispatch, fetch, id, markedAsRead]);
    return (React.createElement(BtnBase, { className: classes.markread, onClick: onClick, tooltip: markedAsRead ? "Mark as unread" : "Mark as read" }));
};
export default BtnMarkRead;
