import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import TextLoading from "@warda/library-ui/core/TextLoading";
import { createUseStyles } from "react-jss";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import Portal from "@warda/library-ui/core/Portal";
import Divider from "@warda/library-ui/core/Divider";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
import classnames from "classnames";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    "@keyframes enter": {
        "0%": {
            transform: "translateX(0px) translateY(100vh)",
        },
        "100%": {
            transform: "translateX(0px) translateY(0px)",
        },
    },
    fullscreen: {
        background: ({ dark }) => dark ? "#000" : getTheme().colors.background,
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        zIndex: getTheme().zIndex.modal,
        display: "flex",
        flexDirection: "column",
        transform: "translateX(0px) translateY(0px)",
        animation: "$enter 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards",
    },
    title: {
        color: ({ dark }) => (dark ? "#fff" : undefined),
        flex: 1,
        marginLeft: 10,
    },
    buttonIcon: {
        color: ({ dark }) => (dark ? "#fff" : undefined),
    },
    divider: {
        backgroundColor: ({ dark }) => (dark ? "#333" : undefined),
    },
    fullscreenToolbar: {
        padding: "0 10px",
    },
    fullscreenContent: {
        position: "relative",
        padding: 10,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
    },
    fullscreenShadow: {
        top: "50px !important",
    },
});
const DialogFullscreen = ({ className, style, open, dark, loading, title, buttons, onClose, children, iconOnClose = "close", }) => {
    const scrollbarRef = React.useRef(null);
    const [scrolled, setScrolled] = React.useState(false);
    const classes = useStyles({ dark });
    const cbOnScroll = React.useCallback((e) => {
        setScrolled(e.target.scrollTop !== 0);
    }, []);
    React.useEffect(() => {
        if (!!children && !!scrollbarRef.current) {
            setScrolled(false);
            scrollbarRef.current.scrollLeft = 0;
        }
    }, [children]);
    if (!open)
        return null;
    return (React.createElement(Portal, null,
        React.createElement("div", { style: style, className: classnames({
                [classes.fullscreen]: true,
                [className]: !!className,
            }) },
            React.createElement(Toolbar, { className: classes.fullscreenToolbar },
                React.createElement(Btn, { icon: iconOnClose, iconClassName: classes.buttonIcon, onClick: onClose }),
                React.createElement(Text, { weight: "bolder", className: classes.title, ellipsis: true, children: title }),
                loading ? (React.createElement(TextLoading, null)) : ((buttons || []).map((b, i) => (React.createElement(Btn, { key: `${i}_button`, ...b, iconClassName: classnames({
                        [classes.buttonIcon]: true,
                        [b.iconClassName]: !!b.iconClassName,
                    }), label: undefined }))))),
            React.createElement(Divider, { className: classes.divider }),
            React.createElement(BorderShadow, { className: classes.fullscreenShadow, open: scrolled, position: "top" }),
            React.createElement("div", { ref: scrollbarRef, className: classes.fullscreenContent, onScroll: cbOnScroll, children: children }))));
};
export default DialogFullscreen;
