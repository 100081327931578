import { KEY_ATTRIBUTE_SETS, KEY_ENTITY_STRUCTURE_ID, KEY_READY_ATTRS, } from "../../../constantsKey";
import getAttributeKey from "../getAttributeKey";
export const sortColumns = (array) => {
    return array.sort((a, b) => {
        if (a.label > b.label)
            return 1;
        if (a.label < b.label)
            return -1;
        return 0;
    });
};
export const isLangInCtlgs = (catalogs, ctlgId, langId) => {
    var _a;
    if (!langId)
        return true;
    return (_a = catalogs
        .find((a) => a.id === ctlgId)) === null || _a === void 0 ? void 0 : _a.languages.some((l) => l === langId);
};
export const inScope = (column, ctlgsId) => {
    const scope = column.scope || [];
    if (!scope.length)
        return true;
    // almeno uno dei ctlg selezionati rientra nello scope
    const c = new Set(ctlgsId);
    return !!scope.find((s) => c.has(s));
};
export const getCtlgsInScopeSet = (column, catalogs, selectedCtlgs, langId) => {
    const setExistCtlgsId = new Set(catalogs.map((c) => c.id));
    const a = selectedCtlgs
        .filter((cId) => {
        return setExistCtlgsId.has(cId);
    })
        .filter((cId) => {
        return inScope(column, [cId]);
    })
        .filter((cId) => {
        if (!column.multiLanguage || !langId)
            return true;
        return isLangInCtlgs(catalogs, cId, langId);
    });
    return new Set(a);
};
export const inEntityStruct = (column, assetdata) => {
    const strIdCol = column.entityStructureId;
    const strIdAss = assetdata[KEY_ENTITY_STRUCTURE_ID];
    return !strIdCol || strIdAss === strIdCol;
};
export const inAttributeSet = (column, assetdata) => {
    const setsCol = column.attributeSets || [];
    const setsAss = assetdata[KEY_ATTRIBUTE_SETS] || [];
    if (!setsCol.length)
        return true;
    return !!setsAss.find((s) => new Set(setsCol).has(s));
};
export const inSearchInput = (column, searchInput) => {
    const label = column.label.toLocaleUpperCase();
    const input = searchInput.toLocaleUpperCase();
    return label.includes(input);
};
export const inMultiCatalog = (column, languages) => {
    const { multiCatalog, multiLanguage } = column;
    if (multiCatalog && multiLanguage) {
        return !!languages.length;
    }
    return true;
};
export const isValued = (column, assetdata, ctlgId, langId) => {
    const attrKey = getAttributeKey(column, ctlgId, langId);
    return assetdata[attrKey] != undefined;
};
export const isAlreadyReady = (column, assetdata, ctlgId, langId) => {
    const readys = assetdata[KEY_READY_ATTRS] || [];
    const { multiCatalog, multiLanguage, attributeName } = column;
    const readyAttr = readys
        .filter((r) => {
        return r.contentId === attributeName;
    })
        .filter((r) => {
        if (!multiCatalog)
            return true;
        if (!ctlgId)
            return false;
        return r.catalog === ctlgId;
    })
        .filter((r) => {
        if (!multiLanguage)
            return true;
        if (!langId)
            return false;
        return r.language === langId;
    });
    return readyAttr.length > 0;
};
