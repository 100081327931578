import * as React from "react";
import { createUseStyles } from "react-jss";
import { CtxDispatchViewport } from "../contexts";
import Btn from "@warda/library-ui/core/Btn";
import SheetBottom from "../SheetBottom";
import { getTheme } from "@warda/library-ui/theme";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { SelectType } from "@warda/library-ui/core/Checkbox";
import { ACT_VPORT } from "../reducer";
import { FileTypeIcon, FileTypeText } from "../constants";
import classnames from "classnames";
const TITLE = "File Type";
const TOOLTIP = "Filter file type";
const originTransf = {
    horizontal: "left",
    vertical: "top",
};
const originAnchor = {
    horizontal: "left",
    vertical: "bottom",
};
const getLabel = (value) => {
    if (value.length === 0)
        return TITLE;
    const first = FileTypeText[value[0].id];
    if (value.length === 1)
        return first;
    return first + " +" + (value.length - 1);
};
const useStyles = createUseStyles({
    filetype: {
        border: `1px solid ${getTheme().colors.grayBorder}`,
        backgroundColor: getTheme().colors.background,
    },
    filetypeSelected: {
        borderColor: getTheme().colors.theme1,
        backgroundColor: hexToRgbA(getTheme().colors.theme1, 0.2),
    },
});
const FilterFileType = ({ className, style, value = [], options, }) => {
    const classes = useStyles({});
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const [dialog, setDialog] = React.useState(false);
    const cbOnDialogShow = React.useCallback(() => {
        setDialog(true);
    }, []);
    const cbOnDialogHide = React.useCallback(() => {
        setDialog(false);
    }, []);
    const cbOnClear = React.useCallback(() => {
        dispatchViewport({
            type: ACT_VPORT.FILTER,
            clearAll: false,
            index: 1,
            value: undefined,
        });
        setDialog(false);
    }, [dispatchViewport]);
    const cbOnChange = React.useCallback((event, newKey) => {
        const newValue = Array.from(value);
        const indexToRemove = newValue.findIndex((i) => i.id === newKey);
        if (indexToRemove !== -1) {
            newValue.splice(indexToRemove, 1);
        }
        else {
            newValue.push({ id: newKey });
        }
        dispatchViewport({
            type: ACT_VPORT.FILTER,
            clearAll: false,
            index: 1,
            value: newValue,
        });
        setDialog(false);
    }, [dispatchViewport, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { className: classnames({
                [classes.filetype]: true,
                [classes.filetypeSelected]: !!value && value.length > 0,
                [className]: !!className,
            }), style: style, tooltip: TOOLTIP, label: getLabel(value), icon: value.length > 0 ? "check" : undefined, onClick: isMobile() ? cbOnDialogShow : undefined, menu: {
                title: TITLE,
                icon: true,
                originTransf,
                originAnchor,
                items: isMobile()
                    ? []
                    : options.map((o) => ({
                        id: o.id,
                        label: o.label,
                        labelStyle: { color: getTheme().colors.typography },
                        selected: value.some((x) => x.id === o.id),
                        selectType: SelectType.CHECK,
                        onClick: cbOnChange,
                    })),
            } }),
        React.createElement(SheetBottom, { open: dialog, onClose: cbOnDialogHide, titleText: TITLE, titleNode: value.length === 0 ? undefined : (React.createElement(Btn, { label: "Clear", labelStyle: { color: getTheme().colors.theme1 }, onClick: cbOnClear })), actions: options.map((o) => {
                const selected = value.some((x) => x.id === o.id);
                const data = {
                    id: o.id,
                    label: o.label,
                    labelStyle: { color: getTheme().colors.typography },
                    icon: FileTypeIcon[o.id],
                    style: selected
                        ? { backgroundColor: hexToRgbA(getTheme().colors.theme1, 0.15) }
                        : undefined,
                    onClick: cbOnChange,
                    selected,
                    selectType: SelectType.CHECK,
                    selectPosition: "end",
                };
                return data;
            }) })));
};
export default FilterFileType;
