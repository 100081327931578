import * as React from "react";
import { createUseStyles } from "react-jss";
import { getTheme } from "@warda/library-ui/theme";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import classnames from "classnames";
import Btn from "@warda/library-ui/core/Btn";
import IconDrag from "@warda/library-ui/core/IconDrag";
import isFinalState from "../../uploadItemStatus/isFinalState";
import isCompletedState from "../../uploadItemStatus/isCompletedState";
const useStyles = createUseStyles({
    toolbar: {
        cursor: "move",
        padding: "0 10px",
        transition: "250ms all",
        backgroundColor: getTheme().colors.background,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
    toolbarButton: {
        margin: "0 !important",
    },
});
const UpToolbar = ({ className, expanded, files, i18n, onClose, onExpand, }) => {
    const classes = useStyles({});
    const fileProcessed = files.reduce((acc, cur) => {
        if (isFinalState(cur.status))
            acc += 1;
        return acc;
    }, 0);
    const fileCompleted = files.reduce((acc, cur) => {
        if (isCompletedState(cur.status))
            acc += 1;
        return acc;
    }, 0);
    const allFileProcessed = fileProcessed === files.length;
    const textAllProcessed = `${fileCompleted} ${i18n.filesUploaded}`;
    const textNotProcessed = `${fileProcessed}/${files.length} ${i18n.filesProcessed}`;
    const [info, setInfo] = React.useState(false);
    const onInfoShow = React.useCallback(() => setInfo(true), []);
    const onInfoHide = React.useCallback(() => setInfo(false), []);
    return (React.createElement(Toolbar, { className: classnames([className, classes.toolbar]), onMouseEnter: onInfoShow, onMouseLeave: onInfoHide },
        React.createElement(IconDrag, { open: info }),
        React.createElement(Text, { size: 2, weight: "bolder", style: { marginLeft: 10 }, children: allFileProcessed ? textAllProcessed : textNotProcessed }),
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(Btn, { className: classes.toolbarButton, onClick: onExpand, icon: expanded ? "expand_less" : "expand_more" }),
        React.createElement(Btn, { className: classes.toolbarButton, onClick: onClose, icon: "close" })));
};
export default UpToolbar;
