const sortLangs = (langs) => {
    return langs
        .sort((a, b) => {
        const pA = (a === null || a === void 0 ? void 0 : a.displayOrder) || 0;
        const pB = (b === null || b === void 0 ? void 0 : b.displayOrder) || 0;
        if (pA > pB)
            return +1;
        if (pA < pB)
            return -1;
        return 0;
    })
        .map((l) => {
        if (typeof l === "string")
            return l;
        return l.id;
    });
};
const sortCtlgs = (ctlgs) => {
    return ctlgs.sort((a, b) => {
        const pA = (a === null || a === void 0 ? void 0 : a.displayOrder) || 0;
        const pB = (b === null || b === void 0 ? void 0 : b.displayOrder) || 0;
        if (pA > pB)
            return +1;
        if (pA < pB)
            return -1;
        return 0;
    });
};
const normalizeElasticCatalog = (res) => {
    try {
        const ctlgs = res.hits.hits.map(({ _id, _source }) => {
            const { displayName, displayOrder, languages, fallback } = _source;
            return {
                id: _id,
                displayOrder,
                displayName,
                fallback,
                languages: sortLangs(!!languages.length ? languages : [{ displayOrder: 1, id: fallback }]),
            };
        });
        return sortCtlgs(ctlgs);
    }
    catch {
        return [];
    }
};
export default normalizeElasticCatalog;
