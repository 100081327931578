import * as React from "react";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import { THEME_COLORS_SOFT, THEME_COLORS_DARK } from "@warda/library-ui/theme";
import classnames from "classnames";
const useStyles = createUseStyles({
    needTypeBlack: {
        color: THEME_COLORS_SOFT.typography,
    },
    needTypeWhite: {
        color: THEME_COLORS_DARK.typography,
    },
    needBorderBlack: {
        border: `1px solid ${THEME_COLORS_SOFT.typography}`,
    },
    needBorderWhite: {
        border: `1px solid ${THEME_COLORS_DARK.typography}`,
    },
});
const BtnBlack = (p) => {
    const classes = useStyles({});
    return (React.createElement(Btn, { ...p, className: classnames({
            [classes.needBorderWhite]: p.border && p.isDark === true,
            [classes.needBorderBlack]: p.border && p.isDark === false,
            [p.className]: !!p.className,
        }), iconClassName: classnames({
            [classes.needTypeWhite]: p.isDark === true,
            [classes.needTypeBlack]: p.isDark === false,
        }), labelClassName: classnames({
            [classes.needTypeWhite]: p.isDark === true,
            [classes.needTypeBlack]: p.isDark === false,
        }) }));
};
export default BtnBlack;
