import { multiselectCtrl, multiselectMeta, } from "@warda/library-ui/utils/multiselect";
import last from "@warda/library-ui/utils/last";
const updateFilesSlcId = ({ files, filesSlcId, id, keyDownCtrl, keyDownMeta, }) => {
    if (keyDownMeta) {
        return multiselectMeta(filesSlcId, files.map((i) => i.id), files.findIndex((i) => i.id === last(filesSlcId)), files.findIndex((i) => i.id === id));
    }
    if (keyDownCtrl) {
        return multiselectCtrl(filesSlcId, id);
    }
    return [id];
};
export default updateFilesSlcId;
