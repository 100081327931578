import { ISortOrder, DATE_FORMAT } from "@warda/library-ui/interfaces";
import { FilterTypeMultiSelection, FilterTypeText, FilterTypeDate, } from "../PanelFilters";
export const ROOT_MYFILE_ID = "root";
export const ROOT_MYFILE_LABEL = "My Files";
export const ROOT_SHARED_ID = "privateshares";
export const ROOT_SHARED_LABEL = "Shared with Me";
export const ORGANIZATION_ID = "all-internal";
export const FOLDER_MYFILE = [
    {
        name: ROOT_MYFILE_LABEL,
        id: ROOT_MYFILE_ID,
        owner: "",
        sharedWith: [],
    },
];
export const FOLDER_SHARED = [
    {
        name: ROOT_SHARED_LABEL,
        id: ROOT_SHARED_ID,
        owner: "",
        sharedWith: [],
    },
];
export var FIELD_ID;
(function (FIELD_ID) {
    FIELD_ID["NAME"] = "name";
    FIELD_ID["TAGS"] = "tags.name";
    FIELD_ID["CREATE"] = "createdOn";
    FIELD_ID["LAST_MODIFIED"] = "lastModified";
    FIELD_ID["EXPIRATION"] = "expiration";
})(FIELD_ID || (FIELD_ID = {}));
export const FILTERS = [
    {
        keyword: true,
        label: "Name",
        id: FIELD_ID.NAME,
        type: FilterTypeText,
    },
    {
        keyword: false,
        label: "Creation Date",
        id: FIELD_ID.CREATE,
        type: FilterTypeDate,
        dateFormat: DATE_FORMAT,
    },
    {
        keyword: true,
        label: "Tags",
        id: FIELD_ID.TAGS,
        type: FilterTypeMultiSelection,
    },
    {
        keyword: false,
        label: "Copyright expiration",
        id: FIELD_ID.EXPIRATION,
        type: FilterTypeDate,
        dateFormat: DATE_FORMAT,
    },
];
export const PAGINATIONS = [250, 500];
export const SORTS = [
    {
        id: FIELD_ID.NAME,
        label: "Name",
        order: ISortOrder.ASC,
        keyword: true,
    },
    {
        id: FIELD_ID.CREATE,
        label: "Creation Date",
        order: ISortOrder.ASC,
        keyword: false,
    },
    {
        id: FIELD_ID.LAST_MODIFIED,
        label: "Last modified Date",
        order: ISortOrder.ASC,
        keyword: false,
    },
];
export const DIFFERENT_VALUES = "___DIFFERENT_VALUES";
export const LIST_COLUMNS_WIDTH = 200;
