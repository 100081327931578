import { getTheme } from "@warda/library-ui/theme";
import { KEY_ASSIGNMENTS, KEY_ATTRIBUTE_SETS, KEY_BUSINESS_IDS, KEY_CARRYOVER_PARENT, KEY_CATALOG, KEY_EDITED_ATTRIBUTES, KEY_ENTITY_ID, KEY_ENTITY_STRUCTURE_ID, KEY_ENTITY_TYPE, KEY_MEDIA, KEY_PUBLC_ATTRS, KEY_PUBLC_MEDIA, KEY_READY_ATTRS, KEY_READY_MEDIA, KEY_ROOT_DOCUMENT_ID, KEY_VIEWS_EDITING_DOCUMENT, KEY_VIEWS_EDITING_MEDIA, KEY_VIEWS_EDITING_PERFORMED, KEY_VIEW_CHECK, KEY_VIEW_DATA, KEY_VIEW_STATUS, PREF_KEY, } from "./constantsKey";
export var CellType;
(function (CellType) {
    CellType["FOLDER"] = "folder";
    CellType["FILE"] = "file";
})(CellType || (CellType = {}));
export var FileSection;
(function (FileSection) {
    FileSection["MY_FILES"] = "myfiles";
    FileSection["SHARES_PRIVATE"] = "privateshares";
})(FileSection || (FileSection = {}));
export var ArchiverStatus;
(function (ArchiverStatus) {
    ArchiverStatus["STARTED"] = "Started";
    ArchiverStatus["COMPLETED"] = "Completed";
})(ArchiverStatus || (ArchiverStatus = {}));
export var AttributeType;
(function (AttributeType) {
    AttributeType["SYSTEM"] = "SYSTEM";
    AttributeType["USER"] = "USER";
    AttributeType["MASTER"] = "MASTER";
})(AttributeType || (AttributeType = {}));
export var ContentType;
(function (ContentType) {
    ContentType["MEDIA"] = "Media";
    ContentType["ATTRIBUTE"] = "Attribute";
})(ContentType || (ContentType = {}));
export var AnnotationsRes;
(function (AnnotationsRes) {
    AnnotationsRes["NONE"] = "NONE";
    AnnotationsRes["RESOLVED"] = "RESOLVED";
    AnnotationsRes["UNRESOLVED"] = "UNRESOLVED";
})(AnnotationsRes || (AnnotationsRes = {}));
export var ViewStatus;
(function (ViewStatus) {
    ViewStatus["DEFAULT"] = "DEFAULT";
    ViewStatus["REVIEW"] = "REVIEW";
})(ViewStatus || (ViewStatus = {}));
export var ViewCheck;
(function (ViewCheck) {
    ViewCheck["NO_CHECK"] = "NO_CHECK";
    ViewCheck["CHECK"] = "CHECK";
    ViewCheck["CHECK_TO_VERIFY"] = "CHECK_TO_VERIFY";
})(ViewCheck || (ViewCheck = {}));
export var MediaType;
(function (MediaType) {
    MediaType["DOCUMENT"] = "DOCUMENT";
    MediaType["IMAGE_SH"] = "SHOOTING";
    MediaType["IMAGE_PP"] = "POST_PRODUCED";
    MediaType["VIDEO"] = "VIDEO";
})(MediaType || (MediaType = {}));
export const MediaTypeLabel = {
    [MediaType.IMAGE_SH]: "Shooting",
    [MediaType.IMAGE_PP]: "Post Production",
    [MediaType.VIDEO]: "Video",
};
export var XlsType;
(function (XlsType) {
    XlsType["UPLOAD_XLS_EDITOR"] = "editor";
    XlsType["UPLOAD_XLS_PRO"] = "pro";
})(XlsType || (XlsType = {}));
export var ViewOpt;
(function (ViewOpt) {
    ViewOpt["OPTIONAL"] = "OPTIONAL";
    ViewOpt["MANDATORY"] = "MANDATORY";
})(ViewOpt || (ViewOpt = {}));
export var ViewStatusAnnotated;
(function (ViewStatusAnnotated) {
    ViewStatusAnnotated["ANNOTATION_NOT_RESOLVED"] = "ANNOTATION_NOT_RESOLVED";
    ViewStatusAnnotated["ANNOTATION_RESOLVED"] = "ANNOTATION_RESOLVED";
    ViewStatusAnnotated["NOT_ANNOTATED"] = "NOT_ANNOTATED";
})(ViewStatusAnnotated || (ViewStatusAnnotated = {}));
export var EditFieldType;
(function (EditFieldType) {
    EditFieldType["TextField"] = "TextField";
    EditFieldType["DateField"] = "DateField";
    EditFieldType["BooleanField"] = "BooleanField";
    EditFieldType["SelectField"] = "SelectField";
    EditFieldType["TextAreaField"] = "TextAreaField";
    EditFieldType["TimestampField"] = "TimestampField";
    EditFieldType["MultiStringField"] = "MultiStringField";
    EditFieldType["CategoryField"] = "CategoryField";
    EditFieldType["MultiLinkField"] = "MultiLinkField";
    EditFieldType["HtmlField"] = "HtmlField";
})(EditFieldType || (EditFieldType = {}));
export var ATypeColumn;
(function (ATypeColumn) {
    ATypeColumn["links"] = "links";
    ATypeColumn["link"] = "link";
    ATypeColumn["date"] = "date";
    ATypeColumn["text"] = "text";
    ATypeColumn["string"] = "string";
    ATypeColumn["strings"] = "strings";
    ATypeColumn["boolean"] = "boolean";
    ATypeColumn["timestamp"] = "timestamp";
    ATypeColumn["dictionaryEntry"] = "dictionaryEntry";
    ATypeColumn["dictionaryEntries"] = "dictionaryEntries";
    ATypeColumn["categories"] = "categories";
    ATypeColumn["passThrough"] = "passThrough";
})(ATypeColumn || (ATypeColumn = {}));
export var ViewType;
(function (ViewType) {
    ViewType["MEDIA"] = "media";
    ViewType["DOCUMENT"] = "document";
})(ViewType || (ViewType = {}));
// LINK https://wardafactory.atlassian.net/wiki/spaces/SEEC/pages/1241939989/Dizionari
export var DictionaryType;
(function (DictionaryType) {
    DictionaryType["A"] = "plain";
    DictionaryType["B"] = "entriesByCatalog";
    DictionaryType["C"] = "byCatalog";
    DictionaryType["D"] = "byCatalogMultiLanguage";
    DictionaryType["E"] = "multiLanguageEntries";
})(DictionaryType || (DictionaryType = {}));
export var IAssignmentType;
(function (IAssignmentType) {
    IAssignmentType["DEFAULT"] = "DEFAULT";
})(IAssignmentType || (IAssignmentType = {}));
export var IAssignmentStatus;
(function (IAssignmentStatus) {
    IAssignmentStatus["DEFAULT"] = "DEFAULT";
})(IAssignmentStatus || (IAssignmentStatus = {}));
export var IAssignmentContentType;
(function (IAssignmentContentType) {
    IAssignmentContentType["VIEW"] = "VIEW";
    IAssignmentContentType["ATTRIBUTE"] = "ATTRIBUTE";
    IAssignmentContentType["ENTITY"] = "ENTITY";
})(IAssignmentContentType || (IAssignmentContentType = {}));
export var RequestTypeBulkEditing;
(function (RequestTypeBulkEditing) {
    RequestTypeBulkEditing["ATTRIBUTE_EDITING"] = "ATTRIBUTE_EDITING";
    RequestTypeBulkEditing["ASSIGNMENT"] = "ASSIGNMENT";
    RequestTypeBulkEditing["VIEW_EDITING"] = "VIEW_EDITING";
    RequestTypeBulkEditing["VIEW_RESET"] = "VIEW_RESET";
    RequestTypeBulkEditing["READY"] = "READY";
    RequestTypeBulkEditing["PUBLISHED"] = "PUBLISHED";
})(RequestTypeBulkEditing || (RequestTypeBulkEditing = {}));
export var RequestType;
(function (RequestType) {
    RequestType["READY"] = "READY";
    RequestType["READY_MEDIA"] = "READY_MEDIA";
    RequestType["READY_ATTRIBUTE"] = "READY_ATTRIBUTE";
    RequestType["ATTRIBUTE_EDITING"] = "ATTRIBUTE_EDITING";
    RequestType["ASSIGNMENT"] = "ASSIGNMENT";
    RequestType["VIEW_EDITING"] = "VIEW_EDITING";
    RequestType["VIEW_RESET"] = "VIEW_RESET";
    RequestType["PUBLISHED"] = "PUBLISHED";
})(RequestType || (RequestType = {}));
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["error"] = "error";
    RequestStatus["pending"] = "pending";
    RequestStatus["done"] = "done";
    RequestStatus["todo"] = "todo";
})(RequestStatus || (RequestStatus = {}));
export var Category;
(function (Category) {
    Category["DEFAULT"] = "DEFAULT";
    Category["STILL_LIFE"] = "STILL-LIFE";
    Category["WORN"] = "WORN";
})(Category || (Category = {}));
export var TagType;
(function (TagType) {
    TagType[TagType["SIMPLE"] = 1] = "SIMPLE";
    TagType[TagType["MARKETING"] = 2] = "MARKETING";
    TagType[TagType["PRODUCT"] = 3] = "PRODUCT";
})(TagType || (TagType = {}));
export const TagColor = {
    [TagType.SIMPLE]: getTheme().colors.msgInfo,
    [TagType.MARKETING]: "#E96E8F",
    [TagType.PRODUCT]: getTheme().colors.theme1,
};
export var CdnPublishedStatus;
(function (CdnPublishedStatus) {
    CdnPublishedStatus[CdnPublishedStatus["NOT_PUBLISHED"] = 0] = "NOT_PUBLISHED";
    CdnPublishedStatus[CdnPublishedStatus["IN_PUBLISHING"] = 1] = "IN_PUBLISHING";
    CdnPublishedStatus[CdnPublishedStatus["PUBLISHED"] = 2] = "PUBLISHED";
    CdnPublishedStatus[CdnPublishedStatus["IN_UNPUBLISHING"] = 3] = "IN_UNPUBLISHING";
})(CdnPublishedStatus || (CdnPublishedStatus = {}));
export var AttributeFamily;
(function (AttributeFamily) {
    AttributeFamily["ALL_ATTRIBUTES"] = "ALL ATTRIBUTES";
    AttributeFamily["CATEGORIES"] = "CATEGORIES";
    AttributeFamily["MEDIA"] = "MEDIA";
    AttributeFamily["MASTER"] = "MASTER";
    AttributeFamily["PLANNING"] = "PLANNING";
    AttributeFamily["EDITORIAL"] = "EDITORIAL";
    AttributeFamily["ASSOCIATION"] = "ASSOCIATION";
    AttributeFamily["MONITORING"] = "MONITORING";
    AttributeFamily["OTHERS"] = "OTHERS";
    AttributeFamily["DOCUMENTS"] = "DOCUMENTS";
    AttributeFamily["GLOBAL"] = "GLOBAL";
    AttributeFamily["METADATA"] = "METADATA";
})(AttributeFamily || (AttributeFamily = {}));
export var SheetLayout;
(function (SheetLayout) {
    SheetLayout["HIDE"] = "hide";
    SheetLayout["OPEN"] = "open";
    SheetLayout["MEDIA_ZOOM"] = "media-zoom";
    SheetLayout["MEDIA_ATTR"] = "media-attr";
})(SheetLayout || (SheetLayout = {}));
export var SheetStatus;
(function (SheetStatus) {
    SheetStatus["PHOLDER"] = "pholder";
    SheetStatus["LOADING"] = "loading";
    SheetStatus["VISIBLE"] = "visible";
})(SheetStatus || (SheetStatus = {}));
export var SharedRole;
(function (SharedRole) {
    SharedRole[SharedRole["VIEWER"] = 0] = "VIEWER";
    SharedRole[SharedRole["EDITOR"] = 1] = "EDITOR";
    // ↓ need to UI
    SharedRole[SharedRole["OWNER"] = 9000] = "OWNER";
    SharedRole[SharedRole["VARIES"] = 9001] = "VARIES";
    SharedRole[SharedRole["TO_REMOVE_PRIVATE"] = 9002] = "TO_REMOVE_PRIVATE";
    SharedRole[SharedRole["TO_REMOVE_ORGANIZ"] = 9003] = "TO_REMOVE_ORGANIZ";
})(SharedRole || (SharedRole = {}));
export var AreaType;
(function (AreaType) {
    AreaType["AREA"] = "AREA";
    AreaType["CONF"] = "CONF";
})(AreaType || (AreaType = {}));
export var PermissionType;
(function (PermissionType) {
    PermissionType["M2MS"] = "M2M_PERMISSION";
    PermissionType["USER"] = "USER_PERMISSION";
})(PermissionType || (PermissionType = {}));
export var GridMedia;
(function (GridMedia) {
    GridMedia["IN_READY"] = "IN_READY";
    GridMedia["LAST_UPLOAD"] = "LAST_UPLOAD";
    GridMedia["UPLOAD_AFTER_READY_SH"] = "UPLOAD_AFTER_READY_SH";
    GridMedia["UPLOAD_AFTER_READY_PP"] = "UPLOAD_AFTER_READY_PP";
})(GridMedia || (GridMedia = {}));
export var PropagationStatus;
(function (PropagationStatus) {
    PropagationStatus["NONE"] = "NONE";
    PropagationStatus["FETCHING"] = "FETCHING";
    PropagationStatus["COMPLETE"] = "COMPLETE";
})(PropagationStatus || (PropagationStatus = {}));
export var ChartType;
(function (ChartType) {
    ChartType["BAR"] = "BAR";
    ChartType["PIE"] = "PIE";
})(ChartType || (ChartType = {}));
export var ChartGroup;
(function (ChartGroup) {
    ChartGroup["READY"] = "READY";
    ChartGroup["COMPL"] = "COMPL";
})(ChartGroup || (ChartGroup = {}));
export var RENDITIONS;
(function (RENDITIONS) {
    RENDITIONS["image_identify"] = "identify";
    RENDITIONS["image_xs"] = "xs";
    RENDITIONS["image_s"] = "s";
    RENDITIONS["image_l"] = "l";
    RENDITIONS["image_xxl"] = "xxl";
    RENDITIONS["video_LQ"] = "LQ";
    RENDITIONS["video_HQ"] = "HQ";
})(RENDITIONS || (RENDITIONS = {}));
export var FileType;
(function (FileType) {
    FileType["FOLDER"] = "FOLDER";
    FileType["MEDIA"] = "MEDIA";
    FileType["TOUCHPOINT"] = "TOUCHPOINT";
})(FileType || (FileType = {}));
export var FileAccess;
(function (FileAccess) {
    FileAccess["OWNER"] = "O";
    FileAccess["ADMIN"] = "A";
    FileAccess["WRITE"] = "W";
    FileAccess["READ"] = "R";
    FileAccess["VARIES"] = "VARIES";
})(FileAccess || (FileAccess = {}));
export var SearchMode;
(function (SearchMode) {
    SearchMode["DEFAULT"] = "DEFAULT";
    SearchMode["HIERARCHY"] = "HIERARCHY";
})(SearchMode || (SearchMode = {}));
export var MultiReadyAction;
(function (MultiReadyAction) {
    MultiReadyAction["SET"] = "SET";
    MultiReadyAction["UNSET"] = "UNSET";
})(MultiReadyAction || (MultiReadyAction = {}));
export var MultiReadyTarget;
(function (MultiReadyTarget) {
    MultiReadyTarget["MEDIA"] = "media";
    MultiReadyTarget["DOCUMENT"] = "document";
    MultiReadyTarget["ATTRIBUTE"] = "attribute";
})(MultiReadyTarget || (MultiReadyTarget = {}));
