import { BASE_URL } from "../constants";
import { Service } from "@warda/library-ui/interfaces";
import serializeObject from "../utils/serializeObject";
import { getAccessCode } from "../components/AreaSharepage/constants";
const apiUrls = {
    getPasswordPolicy: {
        url: (groupId) => `${BASE_URL}/${Service.USERS}/passwordPolicy/${groupId}`,
        method: "GET",
    },
    searchFilesShared: {
        url: () => `${BASE_URL}/${Service.DA}/share/search`,
        method: "POST",
    },
    downloadAnonymousDA: {
        url: (service, tenantId) => `${BASE_URL}/${service}/anonymous-massive-download/scheduleWithRename/${tenantId}`,
        method: "POST",
    },
    descendantsAnonymous: {
        url: (service, tenantId) => `${BASE_URL}/${service}/anonymous-descendants/${tenantId}`,
        method: "POST",
    },
    getMcrDocumentId: {
        url: (id, accessCode) => `${BASE_URL}/mcr/published-documents/${accessCode}/${id}`,
        method: "GET",
    },
    getMcrDocumentToken: {
        url: (id) => `${BASE_URL}/mcr/anonymous/documents/${id}/access-token`,
        method: "GET",
    },
    getRenditionPublic: {
        url: (link, docId, rendId) => {
            const base = `${BASE_URL}/${Service.DA}/media-content/published/${link}/${docId}`;
            if (!rendId)
                return base;
            return base + `/${rendId}`;
        },
        method: "GET",
    },
    zoomMediaPublic: {
        url: (link, docId) => `${BASE_URL}/${Service.DA}/media-content/published/${link}/${docId}/zoom-tiles/tiles.dzi?redirect=false`,
        method: "GET",
    },
    getLogoTenant: {
        url: (tenantId) => `${BASE_URL}/mcr/logo/${tenantId}`,
        method: "GET",
    },
    searchFiles: {
        url: () => `${BASE_URL}/${Service.SC}/library/public-page/public-files`,
        method: "POST",
    },
    downloadMassive: {
        url: () => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-massive/${accessCode}`;
        },
        method: "POST",
    },
    mediaDownload: {
        url: (fileId, rendition, downloadAs, redirect) => {
            const accessCode = getAccessCode();
            const base = `${BASE_URL}/${Service.SC}/media/download-published/${fileId}`;
            const obj = { accessCode, rendition, downloadAs, redirect };
            Object.keys(obj).forEach((k) => {
                if (obj[k] === undefined || obj[k] === null)
                    delete obj[k];
            });
            return base + "?" + serializeObject(obj);
        },
        method: "GET",
    },
    mediaZoom: {
        url: (fileId) => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-published/${fileId}/zoom-tiles/${accessCode}/tiles.dzi?redirect=false`;
        },
        method: "GET",
    },
    boxDocumentId: {
        url: (fileId) => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-published/${fileId}/published-documents/${accessCode}/documentId`;
        },
        method: "GET",
    },
    boxDocumentToken: {
        url: (documentId) => {
            const accessCode = getAccessCode();
            return `${BASE_URL}/${Service.SC}/media/download-published/document/${documentId}/${accessCode}/access-token`;
        },
        method: "GET",
    },
};
export default apiUrls;
