import * as React from "react";
import AssetFolder from "../../AreaFiles_DEPRECATED/AssetFolder_DEPRECATED";
import { ACT_VPORT } from "../reducer";
import { getItemActions, MAP_TYPE } from "../actions";
import { useLocation } from "react-router-dom";
import { CtxDispatchViewport } from "../contexts";
export const getCellHeight = () => 50;
export const getCellHeader = (total) => `Folders (${total})`;
const CellFolder = ({ items, itemsIdSelected, data, selected, detailOpen, color, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const searchString = useLocation().search;
    const query = new URLSearchParams(searchString);
    const tenantId = query.get("tenantId");
    const { id, name, path } = data;
    const onContextMenu = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.ITEMS_CONTEXT, id });
    }, [dispatchViewport, id]);
    const onClick = React.useCallback((p, { keyDownCtrl, keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.ITEMS_SELECT,
            itemId: id,
            keyDownCtrl,
            keyDownMeta,
        });
    }, [dispatchViewport, id]);
    const onDoubleClick = React.useCallback(() => {
        const pathCurr = [{ id, name, owner: "" }];
        const pathThis = path;
        const value = [].concat(pathThis, pathCurr);
        dispatchViewport({ type: ACT_VPORT.FOLDER_OPEN, value });
    }, [dispatchViewport, id, name, path]);
    const onActionClick = React.useCallback((event, id) => dispatchViewport({ type: MAP_TYPE[id] }), [dispatchViewport]);
    return (React.createElement(AssetFolder, { assetHeight: 45, assetWidth: 160, label: name, color: color, selected: selected, onClick: onClick, onDoubleClick: onDoubleClick, onContextMenu: onContextMenu, contextmenu: getItemActions({
            tenantId,
            contextmenu: true,
            detailOpen,
            items,
            itemsIdSelected,
        }).map((a) => ({ ...a, onClick: onActionClick })) }));
};
export default CellFolder;
