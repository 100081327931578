import * as React from "react";
import { CtxDispatchMain, CtxTenantM2ms, CtxTenantUsers, CtxSetUserPref, CtxSetSnackbar, CtxPermissions, CtxUserData, CtxUserPref, } from "./contexts";
import { ACTION_MAIN } from "./reducer";
import { useSnackbar } from "notistack";
const MainContext = ({ children, dispatchMain, permissions, users, m2ms, userData, userPref, }) => {
    const { enqueueSnackbar } = useSnackbar();
    const setSnackbar = React.useCallback((variant, message) => {
        console.log({ message });
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar]);
    const setPreferences = React.useCallback((payload) => {
        dispatchMain({ type: ACTION_MAIN.PREFERENCES_INIT_STARTED, payload });
    }, [dispatchMain]);
    return (React.createElement(CtxDispatchMain.Provider, { value: dispatchMain },
        React.createElement(CtxPermissions.Provider, { value: permissions },
            React.createElement(CtxSetUserPref.Provider, { value: setPreferences },
                React.createElement(CtxSetSnackbar.Provider, { value: setSnackbar },
                    React.createElement(CtxUserData.Provider, { value: userData },
                        React.createElement(CtxUserPref.Provider, { value: userPref },
                            React.createElement(CtxTenantUsers.Provider, { value: users },
                                React.createElement(CtxTenantM2ms.Provider, { value: m2ms }, children)))))))));
};
export default MainContext;
