import * as React from "react";
import FacetToggle from "@warda/library-ui/core/FieldSelectChips/FacetToggle";
import { FiltersCondition } from "@warda/library-ui/core/Filter";
const BTN_WIDTH = 30;
const SelectCond = ({ className, style, value, onChange }) => {
    const actionsConditions = React.useMemo(() => [
        {
            id: FiltersCondition.AND,
            label: "AND",
            selected: FiltersCondition.AND === value,
            width: BTN_WIDTH,
        },
        {
            id: FiltersCondition.OR,
            label: "OR",
            selected: FiltersCondition.OR === value,
            width: BTN_WIDTH,
        },
    ], [value]);
    return (React.createElement(FacetToggle, { className: className, style: style, actions: actionsConditions, onChange: onChange, help: "How filters works among them" }));
};
export default SelectCond;
