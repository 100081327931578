import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Modal from "@warda/library-ui/core/Modal";
import Btn from "@warda/library-ui/core/Btn";
import { ThemeIdMode, ThemeIdPalette, createThemeId, getThemeTupleCurrent, } from "../../colors";
import ThemePreview from "./ThemePreview";
import ThemeCard from "./ThemeCard";
import SelectThemePalette from "./SelectThemePalette";
import SelectThemeMode from "./SelectThemeMode";
import { CtxSetUserPref } from "../contexts";
import { PREF_KEY } from "../../constantsKey";
import { Initialize } from "@warda/library-ui/interfaces";
const WindowTheme = ({ open, onCancel }) => {
    const setPreferences = React.useContext(CtxSetUserPref);
    const [draftInit, setDraftInit] = React.useState(Initialize.NONE);
    const [draft, setDraft] = React.useState(getThemeTupleCurrent());
    const [draftMode, draftPalette] = draft;
    const draftId = createThemeId(draftMode, draftPalette);
    const cbOnClickMode = React.useCallback((newValue) => {
        setDraft([newValue, draft[1]]);
    }, [draft]);
    const cbOnClickPalette = React.useCallback((newValue) => {
        setDraft([draft[0], newValue]);
    }, [draft]);
    const onConfirm = React.useCallback(() => {
        setDraftInit(Initialize.START);
    }, []);
    const onReset = React.useCallback(() => {
        setDraft([ThemeIdMode.SOFT, ThemeIdPalette.PLUM]);
        setDraftInit(Initialize.START);
    }, []);
    React.useEffect(() => {
        if (!open) {
            setDraft(getThemeTupleCurrent());
        }
    }, [open]);
    React.useEffect(() => {
        if (draftInit === Initialize.START) {
            setDraftInit(Initialize.LOADING);
            setPreferences({ [PREF_KEY.themeId]: draftId });
            // setTheme and page refresh is managed
            // after saving the preferences in "Main.tsx"
        }
    }, [draftId, draftInit, setPreferences]);
    return (React.createElement(Modal, { loading: draftInit === Initialize.LOADING, open: open, onClose: onCancel, title: "Choose your personal theme", content: React.createElement(React.Fragment, null,
            React.createElement(ThemeCard, { title: "Mode" }, [ThemeIdMode.SOFT, ThemeIdMode.DARK].map((k) => (React.createElement(SelectThemeMode, { key: k, value: k, draft: draftMode, onClick: cbOnClickMode })))),
            React.createElement(ThemeCard, { title: "Palette" }, [
                ThemeIdPalette.PLUM,
                ThemeIdPalette.BLUE,
                ThemeIdPalette.PINK,
                ThemeIdPalette.AQUA,
            ].map((k) => (React.createElement(SelectThemePalette, { key: k, value: k, draft: draftPalette, onClick: cbOnClickPalette })))),
            React.createElement(ThemeCard, { title: "Preview", elevation: 0 },
                React.createElement(ThemePreview, { themeId: draftId }))), actions: React.createElement(React.Fragment, null,
            React.createElement(Btn, { variant: "bold", label: "RESET", onClick: onReset, color: getTheme().colors.msgWarn }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Btn, { variant: "bold", label: "CANCEL", onClick: onCancel }),
            React.createElement(Btn, { variant: "bold", label: "APPLY", onClick: onConfirm, color: getTheme().colors.msgSucc })) }));
};
export default WindowTheme;
