import * as React from "react";
import Skeleton from "@warda/library-ui/core/Skeleton";
import { createUseStyles } from "react-jss";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    skl: {
        margin: "0 2px",
        width: 100,
        height: 25,
        "border-radius": getTheme().borderRadius,
    },
});
const PlaceholderBtn = ({ loading }) => {
    const classes = useStyles({});
    if (!loading)
        return null;
    return React.createElement(Skeleton, { className: classes.skl, animation: "wave" });
};
export default PlaceholderBtn;
