import * as React from "react";
import Card from "@warda/library-ui/core/Card";
import BackgroundWithIcons from "../BackgroundWithIcons";
import Text from "@warda/library-ui/core/Text";
import { createUseStyles } from "react-jss";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    paper: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        "z-index": 2,
        margin: "auto",
        width: 400,
        height: 350,
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
    },
    emoji: {
        "font-size": 40,
    },
    logo: {
        "font-size": 40,
        "letter-spacing": 0,
        color: getTheme().colors.theme1,
    },
    flex1: {
        flex: 1,
    },
});
const PlaceholderNoLink = () => {
    const classes = useStyles({});
    return (React.createElement(BackgroundWithIcons, null,
        React.createElement(Card, { className: classes.paper },
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement("div", { className: classes.emoji, children: "\uD83E\uDD14" }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Text, { size: 2, children: "We're sorry" }),
            React.createElement(Text, { size: 2, children: "it seems you can't access this page" }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Text, { className: classes.logo, children: "SeeCommerce" }),
            React.createElement("div", { style: { flex: 1 } }))));
};
export default PlaceholderNoLink;
