export var ACTION;
(function (ACTION) {
    ACTION["UPLOAD_PROGRESS"] = "UPLOAD_PROGRESS";
    ACTION["PUTTING_S3"] = "PUTTING_S3";
})(ACTION || (ACTION = {}));
export const reducerInitState = {
    uploadProgressMap: new Map(),
    fileIdMap: new Map(),
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.UPLOAD_PROGRESS: {
            const { id, uploadProgress } = action;
            const previousUploadProgress = newState.uploadProgressMap.get(id) || 0;
            newState.uploadProgressMap.set(id, Math.max(previousUploadProgress, uploadProgress));
            return newState;
        }
        case ACTION.PUTTING_S3: {
            const { id, fileId } = action;
            newState.fileIdMap.set(id, fileId);
            return newState;
        }
        default:
            return newState;
    }
};
export default reducer;
