import * as React from "react";
import { createUseStyles } from "react-jss";
import Drawer from "../../../Drawer";
import { PANEL_DETAIL_WIDTH } from "../../../../constants";
import { SheetLayout } from "../../../../interfaces";
import Toolbar from "@warda/library-ui/core/Toolbar";
import BtnClose from "./BtnClose";
import Viewer from "./Viewer";
import BtnDownload from "./BtnDownload";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    panel: {
        position: "absolute",
        width: "100%",
        height: "100%",
        "z-index": getTheme().zIndex.drawer,
        "background-color": "#000",
        display: "flex",
        "flex-direction": "row",
        overflow: "hidden",
    },
    panelContent: {
        flex: 1,
        position: "relative",
        display: "flex",
        "flex-direction": "column",
    },
    toolbar: {
        padding: "5px 10px",
        "background-color": "transparent",
    },
    separator: {
        "border-right": `1px solid ${getTheme().colors.disable}`,
        height: 23,
        "margin-right": 12,
        "margin-left": 5,
    },
});
const PanelZoom = ({ detailSheet, assetData, onClose }) => {
    const classes = useStyles({});
    if (!assetData)
        return null;
    if (detailSheet !== SheetLayout.MEDIA_ZOOM)
        return null;
    return (React.createElement("div", { className: classes.panel },
        React.createElement("div", { className: classes.panelContent },
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(BtnDownload, { assetData: assetData }),
                React.createElement("div", { className: classes.separator }),
                React.createElement(BtnClose, { onClick: onClose })),
            React.createElement(Viewer, { assetData: assetData })),
        React.createElement(Drawer, { open: true, border: "left", width: PANEL_DETAIL_WIDTH, children: React.createElement("div", null) })));
};
export default PanelZoom;
