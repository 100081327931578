// LINK https://wardafactory.atlassian.net/wiki/spaces/SEEC/pages/2160033804/Permessi+implementati+a+UI
var PERMS;
(function (PERMS) {
    PERMS["beta_features"] = "beta_features";
    PERMS["debug_warda"] = "debug_warda";
    //
    PERMS["access_product_area"] = "access_product_area";
    PERMS["access_staging_area"] = "access_staging_area";
    PERMS["access_digital_asset_area"] = "access_digital_asset_area";
    PERMS["access_attributes_config"] = "access_attributes_config";
    PERMS["access_catalogs_config"] = "access_catalogs_config";
    PERMS["access_categories_config"] = "access_categories_config";
    PERMS["access_users_config"] = "access_users_config";
    PERMS["access_roles_area"] = "access_roles_area";
    PERMS["access_permissions"] = "access_permissions";
    PERMS["access_m2m"] = "access_m2m";
    PERMS["access_tenant_config"] = "access_tenant_config";
    PERMS["access_consumption"] = "access_consumption";
    PERMS["access_completeness"] = "access_completeness";
    PERMS["access_medialibrary_area"] = "access_medialibrary_area";
    PERMS["access_media_attrs_config"] = "access_media_attrs_config";
    PERMS["access_portal_users"] = "access_portal_users";
    PERMS["access_portal_roles"] = "access_portal_roles";
    PERMS["access_portal_permissions"] = "access_portal_permissions";
    PERMS["access_portal_pending_requests"] = "access_portal_pending_requests";
    //
    PERMS["seecommerce_products_calendar_view"] = "seecommerce_products_calendar_view";
    PERMS["seecommerce_manage_single_upload"] = "seecommerce_manage_single_upload";
    PERMS["seecommerce_manage_video_media"] = "seecommerce_manage_video_media";
    PERMS["seecommerce_manage_post_production_media"] = "seecommerce_manage_post_production_media";
    PERMS["seecommerce_manage_shooting_media"] = "seecommerce_manage_shooting_media";
    PERMS["seecommerce_manage_document_media"] = "seecommerce_manage_document_media";
    PERMS["seecommerce_manage_document_ready"] = "seecommerce_manage_document_ready";
    PERMS["seecommerce_edit_product_attributes"] = "seecommerce_edit_product_attributes";
    PERMS["seecommerce_manage_share_link"] = "seecommerce_manage_share_link";
    PERMS["seecommerce_review_product_view"] = "seecommerce_review_product_view";
    PERMS["seecommerce_check_view"] = "seecommerce_check_view";
    PERMS["seecommerce_media_for_post_production"] = "seecommerce_media_for_post_production";
    PERMS["seecommerce_manage_media_ready"] = "seecommerce_manage_media_ready";
    PERMS["seecommerce_manage_attribute_ready"] = "seecommerce_manage_attribute_ready";
    PERMS["seecommerce_assign"] = "seecommerce_assign";
    PERMS["seecommerce_edit_product_view"] = "seecommerce_edit_product_view";
    PERMS["seecommerce_edit_product_view_document"] = "seecommerce_edit_product_view_document";
    PERMS["seecommerce_download"] = "seecommerce_download";
    PERMS["seecommerce_export_xls"] = "seecommerce_export_xls";
    PERMS["seecommerce_annotation"] = "seecommerce_annotation";
    PERMS["seecommerce_upload_xlsx"] = "seecommerce_upload_xlsx";
    PERMS["seecommerce_upload_xlsx_pro"] = "seecommerce_upload_xlsx_pro";
    //
    PERMS["users_resend_invitation"] = "users_resend_invitation";
    PERMS["users_create_user"] = "users_create_user";
    PERMS["users_update_user"] = "users_update_user";
    PERMS["users_admin_manage_is_warda_flag"] = "users_admin_manage_is_warda_flag";
    PERMS["users_get_permissions"] = "users_get_permissions";
    PERMS["users_admin_get_permissions"] = "users_admin_get_permissions";
    PERMS["users_patch_permission"] = "users_patch_permission";
    PERMS["users_get_user_data"] = "users_get_user_data";
    PERMS["users_admin_get_users_data"] = "users_admin_get_users_data";
    PERMS["users_update_user_email"] = "users_update_user_email";
    PERMS["users_get_m2m_data"] = "users_get_m2m_data";
    PERMS["users_admin_get_m2ms_data"] = "users_admin_get_m2ms_data";
    PERMS["users_get_roles"] = "users_get_roles";
    PERMS["users_admin_get_roles"] = "users_admin_get_roles";
    PERMS["users_upsert_tenant_role"] = "users_upsert_tenant_role";
    PERMS["users_admin_upsert_tenant_role"] = "users_admin_upsert_tenant_role";
    PERMS["users_admin_manage_is_visible_in_tenants_flag"] = "users_admin_manage_is_visible_in_tenants_flag";
    PERMS["users_delete_tenant_roles"] = "users_delete_tenant_roles";
    PERMS["users_admin_revoke_token"] = "users_admin_revoke_token";
    //
    PERMS["digitalassets_manage_tag"] = "digitalassets_manage_tag";
    PERMS["digitalassets_download"] = "digitalassets_download";
    //
    PERMS["get_tenant_config"] = "get_tenant_config";
    PERMS["seecommerce_put_config"] = "seecommerce_put_config";
    //
    PERMS["medialibrary_manage_content"] = "medialibrary_manage_content";
    PERMS["medialibrary_public_cdn"] = "medialibrary_public_cdn";
    PERMS["medialibrary_touchpoint_manager"] = "medialibrary_touchpoint_manager";
    PERMS["medialibrary_manage_media_attrs_config"] = "medialibrary_manage_media_attrs_config";
    PERMS["medialibrary_manage_media_attrs_association"] = "medialibrary_manage_media_attrs_association";
    PERMS["medialibrary_media_attrs_values"] = "medialibrary_media_attrs_values";
    PERMS["medialibrary_share_page"] = "medialibrary_share_page";
})(PERMS || (PERMS = {}));
export default PERMS;
