import * as React from "react";
import { createUseStyles } from "react-jss";
import PlaceholderViewport from "../PlaceholderViewport";
import PlaceholderMainToolbar from "./PlaceholderMainToolbar";
const useStyles = createUseStyles({
    main: {
        position: "relative",
        flex: 1,
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "stretch",
    },
    mainContent: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        minHeight: 0, // fix sidepanels height
    },
});
const PlaceholderMain = () => {
    const classes = useStyles({});
    return (React.createElement("div", { className: classes.main },
        React.createElement(PlaceholderMainToolbar, null),
        React.createElement("div", { className: classes.mainContent },
            React.createElement(PlaceholderViewport, null))));
};
export default PlaceholderMain;
