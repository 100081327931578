import { ISortOrder } from "@warda/library-ui/interfaces";
import getAttributeKey from "../getAttributeKey";
import getCtlgId, { getLangId } from "../getCtlgId";
import { inScope } from "../PanelDetail/filterColumns";
import getMngSetsSelected from "../../../utils/getMngSetsSelected";
import escapeKeyEs from "../../../utils/escapeKeyEs";
export const filterSortsBroken = ({ sorts, columns, catalogs, slcCtlg, slcLang, }) => {
    return sorts.filter(({ id }) => {
        const column = columns.find((c) => c.id === id);
        return canSort({ catalogs, column, slcCtlg, slcLang });
    });
};
export const canSort = ({ catalogs, column, slcCtlg = "", slcLang = "", }) => {
    const { multiCatalog, multiLanguage, sortable } = column;
    if (sortable === false)
        return false;
    const ctlgId = getCtlgId(catalogs, slcCtlg);
    const langId = getLangId(catalogs, slcCtlg, slcLang);
    if (multiCatalog && !ctlgId)
        return false;
    if (multiLanguage && !langId)
        return false;
    if (!inScope(column, [slcCtlg]))
        return false;
    return true;
};
export const getSorts = ({ sorts, catalogs = [], columns, slcCtlg = "", slcLang = "", }) => {
    return sorts
        .filter(({ id }) => {
        const column = columns.find((c) => c.id === id);
        return canSort({ catalogs, column, slcCtlg, slcLang });
    })
        .map(({ id, order }) => {
        const column = columns.find((c) => c.id === id);
        const key = getAttributeKey(column, slcCtlg, slcLang);
        return { [key]: { order } };
    });
};
export const getSortsDefault = (columns) => {
    const ret = [];
    // find columns marked as default sorting
    const defaultSortingFound = columns.filter((x) => x.defaultSorting &&
        Number.isFinite(x.defaultSorting.priority) &&
        x.defaultSorting.sorting &&
        x.defaultSorting.sorting.toLowerCase() !== ISortOrder.NONE.toLowerCase());
    if (defaultSortingFound.length > 0) {
        // order the columns found according to their priority
        defaultSortingFound.sort((a, b) => a.defaultSorting.priority - b.defaultSorting.priority);
        defaultSortingFound.forEach((x) => {
            ret.push({
                id: escapeKeyEs(x.id),
                order: x.defaultSorting.sorting.toLowerCase() ===
                    ISortOrder.ASC.toLowerCase()
                    ? ISortOrder.ASC
                    : ISortOrder.DESC,
                label: x.label,
                keyword: x.keyword,
            });
        });
    }
    return ret;
};
export const getSortsGrid = ({ columns, mngSetsColumns, mngSetsColumnsContext, userPref, }) => {
    try {
        const { slcSet } = getMngSetsSelected(mngSetsColumnsContext, userPref, mngSetsColumns);
        return slcSet.payload.itemsSort.map((s) => {
            const column = columns.find((c) => c.id === s.id);
            const sort = {
                id: s.id,
                order: s.order,
                label: column.label,
            };
            return sort;
        });
    }
    catch {
        return getSortsDefault(columns);
    }
};
