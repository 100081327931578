import * as React from "react";
import DialogFullscreen from "../Dialog/DialogFullscreen";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport } from "../contexts";
import Viewer from "./Viewer";
const DialogViewer = ({ open, file }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const id = (file === null || file === void 0 ? void 0 : file.id) || "";
    const data = (file === null || file === void 0 ? void 0 : file.data) || {};
    const { name } = data;
    const cbOnClose = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_VIEWER });
    }, [dispatchViewport]);
    const cbOnDownload = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_DOWNLOAD, fileId: id });
    }, [dispatchViewport, id]);
    if (!file)
        return null;
    return (React.createElement(DialogFullscreen, { dark: true, open: open, title: name, onClose: cbOnClose, iconOnClose: "arrow_back", buttons: [
            {
                icon: "download",
                tooltip: "Download",
                onClick: cbOnDownload,
            },
        ], children: React.createElement(Viewer, { file: file }) }));
};
export default DialogViewer;
