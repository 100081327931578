import UploadItemStatus from "./UploadItemStatus";
const isFinalState = (status) => {
    switch (status) {
        case UploadItemStatus.Completed:
        case UploadItemStatus.Abort:
        case UploadItemStatus.Error:
            return true;
        default:
            return false;
    }
};
export default isFinalState;
