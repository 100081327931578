import { getAttributeId } from "../getAttributeKey";
import { getDictionariesBody } from "../../../api/fetchesApi";
import { getValuedAttributeKeysDictionary } from "./";
import apiUrls from "../../../api/apiUrls";
import { fetchCookieJwtWithRefreshToken } from "../../../api/fetchCookieJwt";
import { INDEX_NAME } from "../../../constantsKey";
export const getDictionariesToSearch = (items, columns) => {
    const confs = [];
    items.forEach(({ data }) => {
        const attributeKeys = getValuedAttributeKeysDictionary(data, columns);
        attributeKeys.forEach((attributeKey) => {
            const { attrId } = getAttributeId(attributeKey);
            const column = columns.find((c) => c.id === attrId);
            const { dictionaryType, dictionaryId } = column.editField;
            const c = confs.find((x) => x.attributeId === attrId &&
                x.dictionaryType === dictionaryType &&
                x.dictionaryId === dictionaryId);
            if (!c) {
                confs.push({
                    attributeId: attrId,
                    dictionaryType,
                    dictionaryId,
                    value: {},
                });
            }
        });
        attributeKeys.forEach((attributeKey) => {
            const { attrId, ctlgId } = getAttributeId(attributeKey);
            const column = columns.find((c) => c.id === attrId);
            const { dictionaryType, dictionaryId, multiSelectable, } = column.editField;
            const c = confs.find((x) => x.attributeId === attrId &&
                x.dictionaryType === dictionaryType &&
                x.dictionaryId === dictionaryId);
            if (!c.value[ctlgId]) {
                c.value[ctlgId] = [];
            }
            const codesSet = new Set(c.value[ctlgId]);
            const codesValue = multiSelectable
                ? data[attributeKey]
                : [data[attributeKey]];
            codesValue.forEach((v) => {
                if (!codesSet.has(v)) {
                    c.value[ctlgId].push(v);
                }
            });
        });
    });
    return confs;
};
export const getDataDictionaries = async (items, columns, error) => {
    try {
        if (error)
            throw "NO_LOAD";
        const dictToSearch = getDictionariesToSearch(items, columns);
        if (!dictToSearch.length)
            throw "NO_DICTS";
        const { url, method } = apiUrls.msearch;
        const { responses } = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: dictToSearch.map((x) => ({
                index: INDEX_NAME.DICTIONARIES,
                body: getDictionariesBody(x),
            })),
        });
        const res = [];
        responses.forEach((elasticSrc, i) => {
            const { attributeId } = dictToSearch[i];
            if (!!elasticSrc.error) {
                res.push({
                    attributeId,
                    dictionaries: [],
                });
            }
            else {
                const dictionaries = elasticSrc.hits.hits.map((o) => ({
                    id: o._id,
                    ...o._source,
                }));
                res.push({
                    attributeId,
                    dictionaries,
                });
            }
        });
        return res;
    }
    catch (err) {
        return [];
    }
};
