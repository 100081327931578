import * as React from "react";
import { ACT_VPORT } from "../reducer";
import Btn from "@warda/library-ui/core/Btn";
import { CtxDispatchViewport } from "../contexts";
import { areFiltersUseful } from "../../PanelFilters";
import { getTheme } from "@warda/library-ui/theme";
const BtnCleanFilters = ({ className, style, filters }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const onClick = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.FILTER, clearAll: true });
    }, [dispatchViewport]);
    if (!areFiltersUseful(filters))
        return null;
    return (React.createElement(Btn, { className: className, style: style, label: "Clear all", labelStyle: { color: getTheme().colors.theme1 }, onClick: onClick }));
};
export default BtnCleanFilters;
