import { Initialize } from "@warda/library-ui/interfaces";
export const SEARCH_ES_EMPTY = {
    initSearch: Initialize.LOADING,
    items: [],
    itemsTotal: 0,
};
export const SEARCH_ES_ERROR = {
    initSearch: Initialize.FAIL,
    items: [],
    itemsTotal: 0,
};
