import * as React from "react";
import { createUseStyles } from "react-jss";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { Initialize } from "@warda/library-ui/interfaces";
import classnames from "classnames";
import ViewerBoxPreview from "./ViewerBoxPreview";
var ACT;
(function (ACT) {
    ACT["INIT_START"] = "INIT_START";
    ACT["INIT_LOADING"] = "INIT_LOADING";
    ACT["INIT_SUCC"] = "INIT_SUCC";
    ACT["INIT_FAIL"] = "INIT_FAIL";
    ACT["VIEWER_READY"] = "VIEWER_READY";
})(ACT || (ACT = {}));
const reducerInitState = {
    initialize: Initialize.NONE,
    viewerToken: "",
    viewerDocId: "",
    viewerReady: false,
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT.INIT_START:
            newState.initialize = Initialize.START;
            newState.viewerToken = reducerInitState.viewerToken;
            newState.viewerDocId = reducerInitState.viewerDocId;
            newState.viewerReady = reducerInitState.viewerReady;
            return newState;
        case ACT.INIT_LOADING:
            newState.initialize = Initialize.LOADING;
            return newState;
        case ACT.INIT_FAIL:
            newState.initialize = Initialize.FAIL;
            return newState;
        case ACT.INIT_SUCC:
            newState.initialize = Initialize.SUCC;
            newState.viewerToken = action.token;
            newState.viewerDocId = action.docId;
            return newState;
        case ACT.VIEWER_READY:
            newState.viewerReady = true;
            return newState;
        default:
            return newState;
    }
};
const useStyles = createUseStyles({
    viewerDocument: {
        width: "100%",
        height: "100%",
        display: "flex",
        "flex-direction": "column",
        position: "relative",
        "align-items": "center",
        "justify-content": "center",
        "text-align": "center",
        color: "#fff",
    },
    boxFix: {
        opacity: 0,
        width: "100%",
        height: "100%",
        "&>div": {
            width: "100%",
            height: "100%",
            "&>div": {
                width: "100%",
                height: "100%",
                "&>div": {
                    width: "100%",
                    height: "100%",
                    "&>div": {
                        width: "100%",
                        height: "100%",
                        "&>div": {
                            "background-color": "transparent",
                        },
                    },
                },
            },
        },
    },
    boxReady: {
        opacity: 1,
    },
});
const ViewerBox = ({ fileId, getData }) => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, viewerToken, viewerDocId, viewerReady } = state;
    const onLoad = React.useCallback(() => {
        dispatch({ type: ACT.VIEWER_READY });
    }, []);
    React.useEffect(() => {
        (async () => {
            if (initialize === Initialize.START) {
                dispatch({ type: ACT.INIT_LOADING });
                try {
                    const { docId, token } = await getData(fileId);
                    dispatch({ type: ACT.INIT_SUCC, docId, token });
                }
                catch {
                    dispatch({ type: ACT.INIT_FAIL });
                }
            }
        })();
    }, [fileId, getData, initialize]);
    React.useEffect(() => {
        if (!!fileId)
            dispatch({ type: ACT.INIT_START });
    }, [fileId]);
    return (React.createElement("div", { className: classes.viewerDocument },
        React.createElement(Placeholder, { open: initialize === Initialize.FAIL, icon: "insert_drive_file", label: "Unable view document" }),
        React.createElement(Placeholder, { open: !viewerReady && initialize !== Initialize.FAIL, spinner: true, spinnerColor: "#fff" }),
        React.createElement(ViewerBoxPreview, { token: viewerToken, docId: viewerDocId, className: classnames({
                [classes.boxFix]: true,
                [classes.boxReady]: viewerReady,
            }), onLoad: onLoad })));
};
export default ViewerBox;
