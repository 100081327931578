import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import { Zoom } from "@warda/library-ui/core/Transitions";
import Text from "@warda/library-ui/core/Text";
import Icon from "@warda/library-ui/core/Icon";
import InputFile from "@warda/library-ui/core/InputFile";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import Avatar from "@warda/library-ui/core/Avatar";
import { CtxUserData, CtxTenantM2ms, CtxTenantUsers } from "../contexts";
import { getUser } from "@warda/library-ui/utils/getUser";
const SIZE = 100;
const useStyles = createUseStyles({
    profileAvatar: {
        position: "relative",
        margin: "auto",
    },
    btnUpload: {
        width: SIZE,
        height: SIZE,
        "border-radius": SIZE,
        "background-color": hexToRgbA(getTheme().colors.theme1, 0.5),
        position: "absolute",
        "z-index": 3,
        top: 0,
        left: 0,
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "& *": {
            color: "#fff !important",
        },
    },
    btnDelete: {
        position: "absolute",
        bottom: 5,
        left: 5,
        margin: 0,
        "z-index": 4,
        cursor: "pointer",
    },
    progressMask: {
        width: SIZE,
        height: SIZE,
        "border-radius": SIZE,
        "background-color": hexToRgbA("#000", 0.25),
        position: "absolute",
        "z-index": 5,
        top: 0,
        left: 0,
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        cursor: "pointer",
        "& *": {
            color: "#fff !important",
        },
    },
    inputFix: {
        zIndex: 999,
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
});
const ProfileAvatar = ({ srcUrl, progress, onReplacePhoto, onRemovePhoto, }) => {
    const classes = useStyles({});
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const currUser = React.useContext(CtxUserData);
    const userMock = getUser(currUser.userId, users, m2ms);
    const [mousehover, setMousehover] = React.useState(false);
    const onMouseEnter = React.useCallback(() => {
        setMousehover(true);
    }, []);
    const onMouseLeave = React.useCallback(() => {
        setMousehover(false);
    }, []);
    const onRefresh = React.useCallback(() => {
        if (progress === 100)
            location.reload();
    }, [progress]);
    return (React.createElement("div", { role: "presentation", className: classes.profileAvatar, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        React.createElement(Avatar, { style: { zIndex: 2, fontSize: 18 }, src: srcUrl, size: SIZE, text: userMock.avatarText, icon: userMock.avatarIcon }),
        React.createElement(Zoom, { open: mousehover && !progress, className: classes.btnUpload },
            React.createElement(React.Fragment, null,
                React.createElement(Icon, { children: "upload" }),
                React.createElement(Btn, { color: getTheme().colors.msgFail, icon: "delete", variant: "bold", onClick: onRemovePhoto, className: classes.btnDelete, small: true }))),
        progress === 100 ? null : (React.createElement("div", { className: classes.inputFix },
            React.createElement(InputFile, { onChangeInput: onReplacePhoto }))),
        progress === 0 ? null : (React.createElement("div", { role: "presentation", className: classes.progressMask, onClick: onRefresh }, progress === 100 ? (React.createElement(Icon, { children: "refresh" })) : (React.createElement(Text, { weight: "bolder", size: 4, children: String(progress) + "%" }))))));
};
export default ProfileAvatar;
