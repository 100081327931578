import parseJwt from "./parseJwt";
import Cookies from "js-cookie";
import { requestTokenWithRefreshToken } from "../api/fetchCookieJwt";
import { AUTHORIZATION_TOKEN } from "../constantsLocalStorage";
export const directDownloadMediaPublished_DEPRECATED = (srcUrl, name) => {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = `${srcUrl}?downloadAs=${name}`;
    anchor.click();
    document.body.removeChild(anchor);
};
export const directDownloadMediaDA_DEPRECATED = async (srcUrl, name) => {
    const jwt = parseJwt(Cookies.get(AUTHORIZATION_TOKEN) || "");
    const dateExpiresJwt = Number(`${jwt.exp}000`);
    const dateNow = new Date().getTime();
    if (dateNow > dateExpiresJwt) {
        await requestTokenWithRefreshToken();
    }
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = `${srcUrl}?downloadAs=${name}`;
    anchor.click();
    document.body.removeChild(anchor);
};
export const directDownload = (srcUrl) => {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = srcUrl;
    anchor.click();
    document.body.removeChild(anchor);
};
const directDownloadMedia = async (srcUrl) => {
    const jwt = parseJwt(Cookies.get(AUTHORIZATION_TOKEN) || "");
    const dateExpiresJwt = Number(`${jwt.exp}000`);
    const dateNow = new Date().getTime();
    if (dateNow > dateExpiresJwt) {
        await requestTokenWithRefreshToken();
    }
    directDownload(srcUrl);
};
export default directDownloadMedia;
