const hasPerms = (keys, permissions, condition = "AND") => {
    const permsId = new Set(permissions.map((p) => p.id));
    if (condition === "AND") {
        return !keys.some((key) => !permsId.has(key));
    }
    else {
        return keys.some((key) => permsId.has(key));
    }
};
export default hasPerms;
