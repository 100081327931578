export const wsItemError = (downloads, item) => {
    const { correlationId, message, filename, error } = item;
    const newDownloads = Array.from(downloads);
    const itemToUpdate = newDownloads.find(({ id }) => id === correlationId);
    // nothing to update - return state to avoid rerender
    if (!itemToUpdate)
        return [];
    const itemIndex = newDownloads.indexOf(itemToUpdate);
    newDownloads[itemIndex] = {
        ...itemToUpdate,
        name: filename,
        error: true,
        loading: false,
        tooltip: error || message || "",
    };
    return newDownloads;
};
export const wsItemUpdate = (downloads, item) => {
    const { correlationId, message, filename } = item;
    const newDownloads = Array.from(downloads);
    const itemToUpdate = newDownloads.find(({ id }) => id === correlationId);
    // nothing to update - return state to avoid rerender
    if (!itemToUpdate)
        return [];
    const itemIndex = newDownloads.indexOf(itemToUpdate);
    newDownloads[itemIndex] = {
        ...itemToUpdate,
        url: message,
        name: filename,
    };
    return newDownloads;
};
export const wsItemFinish = (downloads, item) => {
    const { correlationId, message } = item;
    const newDownloads = Array.from(downloads);
    const itemToUpdate = newDownloads.find(({ id }) => id === correlationId);
    // nothing to update - return state to avoid rerender
    if (!itemToUpdate)
        return [];
    const itemIndex = newDownloads.indexOf(itemToUpdate);
    newDownloads[itemIndex] = {
        ...itemToUpdate,
        url: message,
        loading: false,
    };
    return newDownloads;
};
