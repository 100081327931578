import { last } from "lodash-es";
import { SheetLayout, } from "../../interfaces";
import { Initialize, ISortOrder } from "@warda/library-ui/interfaces";
import { FOLDER_ROOT_ID, FOLDER_ROOT_LABEL } from "../../constants";
import { directDownloadMediaPublished_DEPRECATED } from "../../utils/directDownloadMedia";
import apiUrls from "../../api/apiUrlsAnonymous";
import isEmpty from "lodash-es/isEmpty";
import { multiselectCtrl, multiselectMeta, } from "@warda/library-ui/utils/multiselect";
const PAGINATIONS = [250, 2500];
export const SORTS = [
    {
        id: "name",
        label: "Name",
        order: ISortOrder.ASC,
        keyword: true,
    },
    {
        id: "createdon",
        label: "Creation Date",
        order: ISortOrder.ASC,
        keyword: false,
    },
];
export var ACT_VPORT;
(function (ACT_VPORT) {
    ACT_VPORT["INITIALIZE_START"] = "INITIALIZE_START";
    ACT_VPORT["INITIALIZE_LOADING"] = "INITIALIZE_LOADING";
    ACT_VPORT["INITIALIZE_ENDED"] = "INITIALIZE_ENDED";
    ACT_VPORT["ITEMS_CONTEXT"] = "ITEMS_CONTEXT";
    ACT_VPORT["ITEMS_SELECT"] = "ITEM_SELECT";
    ACT_VPORT["ITEMS_SELECT_ALL"] = "ITEMS_SELECT_ALL";
    ACT_VPORT["ITEMS_DESELECT"] = "ITEMS_DESELECT";
    ACT_VPORT["PAGINATION_CURR"] = "PAGINATION_CURR";
    ACT_VPORT["PAGINATION_SIZE"] = "PAGINATION_SIZE";
    ACT_VPORT["SORT_ORDER"] = "SORT_ORDER";
    ACT_VPORT["SORT_ID"] = "SORT_ID";
    ACT_VPORT["SHEET_LAYOUT"] = "SHEET_LAYOUT";
    ACT_VPORT["FOLDER_OPEN"] = "FOLDER_OPEN";
    ACT_VPORT["PATH_SELECT"] = "PATH_SELECT";
    ACT_VPORT["DOWNLOADS_UPDATE"] = "DOWNLOADS_UPDATE";
    ACT_VPORT["DOWNLOADS_FINISH"] = "DOWNLOADS_FINISH";
    ACT_VPORT["DOWNLOADS_ADDED"] = "DOWNLOADS_ADDED";
    ACT_VPORT["DOWNLOADS_RESET"] = "DOWNLOADS_RESET";
    ACT_VPORT["ACTION_OPEN_DETAILS"] = "ACTION_OPEN_DETAILS";
    ACT_VPORT["ACTION_DOWNLOAD_SINGLE"] = "ACTION_DOWNLOAD_SINGLE";
    ACT_VPORT["ACTION_DOWNLOAD_ARCHIV"] = "ACTION_DOWNLOAD_ARCHIV";
})(ACT_VPORT || (ACT_VPORT = {}));
export const reducerInitState = {
    initialize: Initialize.LOADING,
    items: [],
    itemsTotal: 0,
    itemsIdSelected: [],
    itemsToDownload: [],
    path: [{ id: FOLDER_ROOT_ID, name: FOLDER_ROOT_LABEL, owner: "" }],
    sort: SORTS[0],
    paginationCurr: 1,
    paginationSize: last(PAGINATIONS),
    detailSheet: SheetLayout.HIDE,
    downloads: [],
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT_VPORT.INITIALIZE_START:
            newState.initialize = Initialize.START;
            return newState;
        case ACT_VPORT.INITIALIZE_LOADING:
            newState.initialize = Initialize.LOADING;
            return newState;
        case ACT_VPORT.INITIALIZE_ENDED: {
            const a = action.data;
            newState.initialize = a.initialize;
            newState.items = a.items;
            newState.itemsTotal = a.itemsTotal;
            return newState;
        }
        case ACT_VPORT.ITEMS_CONTEXT: {
            const { id } = action;
            const selected = new Set(newState.itemsIdSelected).has(id);
            if (!selected)
                newState.itemsIdSelected = [id];
            return newState;
        }
        case ACT_VPORT.ITEMS_SELECT: {
            const { items, itemsIdSelected } = newState;
            const { itemId, keyDownCtrl, keyDownMeta } = action;
            let idsSlc = [itemId];
            if (keyDownMeta) {
                idsSlc = multiselectMeta(itemsIdSelected, items.map((i) => i.id), items.findIndex((i) => i.id === last(itemsIdSelected)), items.findIndex((i) => i.id === itemId));
            }
            if (keyDownCtrl) {
                idsSlc = multiselectCtrl(itemsIdSelected, itemId);
            }
            newState.itemsIdSelected = idsSlc;
            return newState;
        }
        case ACT_VPORT.ITEMS_DESELECT:
            if (newState.detailSheet === SheetLayout.MEDIA_ZOOM) {
                newState.detailSheet = SheetLayout.OPEN;
            }
            newState.itemsIdSelected = reducerInitState.itemsIdSelected;
            return newState;
        case ACT_VPORT.ITEMS_SELECT_ALL:
            newState.itemsIdSelected = newState.items.map(({ id }) => id);
            return newState;
        case ACT_VPORT.PAGINATION_CURR:
            newState.paginationCurr = action.payload;
            newState.initialize = Initialize.LOADING;
            return newState;
        case ACT_VPORT.PAGINATION_SIZE:
            newState.paginationCurr = 1;
            newState.paginationSize = action.payload;
            newState.initialize = Initialize.LOADING;
            return newState;
        case ACT_VPORT.SORT_ORDER: {
            const newSort = { ...newState.sort };
            newSort.order =
                newSort.order === ISortOrder.ASC ? ISortOrder.DESC : ISortOrder.ASC;
            newState.sort = newSort;
            newState.initialize = Initialize.LOADING;
            return newState;
        }
        case ACT_VPORT.SORT_ID: {
            const { id } = action;
            const newSort = SORTS.find((s) => s.id === id);
            newSort.order = newState.sort.order;
            newState.sort = newSort;
            newState.initialize = Initialize.LOADING;
            return newState;
        }
        case ACT_VPORT.ACTION_OPEN_DETAILS:
            newState.detailSheet = SheetLayout.OPEN;
            return newState;
        case ACT_VPORT.SHEET_LAYOUT:
            newState.detailSheet = action.layout;
            if (!!action.itemId) {
                newState.itemsIdSelected = [action.itemId];
            }
            return newState;
        case ACT_VPORT.FOLDER_OPEN:
            newState.path = action.value;
            newState.paginationSize = reducerInitState.paginationSize;
            newState.paginationCurr = reducerInitState.paginationCurr;
            newState.itemsIdSelected = reducerInitState.itemsIdSelected;
            newState.initialize = Initialize.LOADING;
            return newState;
        case ACT_VPORT.PATH_SELECT:
            const { id } = action;
            const { path } = newState;
            const indexToDelete = path.findIndex((o) => o.id === `${id}`) + 1;
            const newPath = path.slice(0, indexToDelete);
            newState.path = newPath;
            newState.paginationSize = reducerInitState.paginationSize;
            newState.paginationCurr = reducerInitState.paginationCurr;
            newState.itemsIdSelected = reducerInitState.itemsIdSelected;
            return newState;
        case ACT_VPORT.ACTION_DOWNLOAD_SINGLE: {
            const { items, itemsIdSelected } = newState;
            const file = items.find(({ id }) => id === itemsIdSelected[0]);
            const searchString = location.search;
            const query = new URLSearchParams(searchString);
            const link = query.get("link");
            const srcUrl = apiUrls.getRenditionPublic.url(link, file.documentRepoId);
            directDownloadMediaPublished_DEPRECATED(srcUrl, file.name);
            return newState;
        }
        case ACT_VPORT.ACTION_DOWNLOAD_ARCHIV:
            newState.itemsToDownload = (action.ids || newState.itemsIdSelected).reduce((acc, c) => {
                const file = newState.items.find((i) => i.id === c);
                const { id, documentRepoId, mimeType, name } = file;
                acc.push({
                    id,
                    documentRepoId,
                    name,
                    mimeType,
                    path: newState.path,
                });
                return acc;
            }, []);
            return newState;
        case ACT_VPORT.DOWNLOADS_UPDATE: {
            const { correlationId, message, filename, error } = action.payload;
            const newDownloads = Array.from(newState.downloads);
            const itemToUpdate = newDownloads.find(({ id }) => id === correlationId);
            // nothing to update - return state to avoid rerender
            if (isEmpty(itemToUpdate))
                return state;
            const itemIndex = newDownloads.indexOf(itemToUpdate);
            newDownloads[itemIndex] = {
                ...itemToUpdate,
                error: !!error,
                tooltip: !!error ? error : "",
                name: filename,
                url: message,
            };
            newState.downloads = newDownloads;
            return newState;
        }
        case ACT_VPORT.DOWNLOADS_FINISH: {
            const { correlationId, message, error } = action.payload;
            const newDownloads = Array.from(newState.downloads);
            const itemToUpdate = newDownloads.find(({ id }) => id === correlationId);
            // nothing to update - return state to avoid rerender
            if (isEmpty(itemToUpdate))
                return state;
            const itemIndex = newDownloads.indexOf(itemToUpdate);
            newDownloads[itemIndex] = {
                ...itemToUpdate,
                loading: false,
                error: !!error,
                tooltip: !!error ? error : "",
                url: message,
            };
            newState.downloads = newDownloads;
            return newState;
        }
        case ACT_VPORT.DOWNLOADS_ADDED: {
            const newDownloads = Array.from(newState.downloads);
            const { correlationId, filename } = action;
            newDownloads.push({
                id: correlationId,
                loading: true,
                name: filename,
                url: "",
            });
            newState.downloads = newDownloads;
            newState.itemsToDownload = reducerInitState.itemsToDownload;
            return newState;
        }
        case ACT_VPORT.DOWNLOADS_RESET:
            newState.itemsToDownload = reducerInitState.itemsToDownload;
            newState.downloads = reducerInitState.downloads;
            return newState;
        default:
            console.error(`no action ${action.type}`);
            return state;
    }
};
export default reducer;
