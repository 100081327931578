// temporaneamente gestiti dal FE nei Cookies - da eliminare in futuro
export const AUTHORIZATION_TOKEN = "warda-authorization";
export const REFRESH_TOKEN = "warda-refreshToken";
// costanti salvate nel local storage
//
// dati sensibili per il login
export const USER_ID = "userId";
export const GROUP_ID = "groupId";
export const TENANT_ID = "tenantId";
export const LOGIN_DATE = "loginDate";
export const JWT_KEY = "id_token";
//
// preferenze utente
export const listAssigneesSortId = "listAssigneesSortId";
export const listAssigneesSortOrder = "listAssigneesSortOrder";
export const listAssigneesShowDisabled = "listAssigneesShowDisabled";
export const downloadsSlcName = "downloadsSlcName";
export const downloadsSlcType = "downloadsSlcType";
export const downloadsChkPost = "downloadsChkPost";
export const downloadsChkLast = "downloadsChkLast";
export const KEEP_FILTERS_VALUES = "keepFiltersValues";
