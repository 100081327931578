import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import CardTitle from "../CardTitle";
import CardContent from "../CardContent";
import Text from "@warda/library-ui/core/Text";
const CardImport = ({ cardClasses, dispatch, data }) => {
    const { payload } = data;
    const { error, successes, failures } = payload;
    const tot = (successes || 0) + (failures || 0);
    const num = !!failures ? `${successes}/${tot}` : successes;
    return error ? (React.createElement(React.Fragment, null,
        React.createElement(CardTitle, { classes: cardClasses, dispatch: dispatch, title: "Unable to import data", data: data, avatarIcon: "warning", avatarColor: getTheme().colors.msgWarn }),
        React.createElement(CardContent, { classes: cardClasses },
            React.createElement(Text, { size: 0, children: error })))) : (React.createElement(React.Fragment, null,
        React.createElement(CardTitle, { classes: cardClasses, dispatch: dispatch, title: "Import data completed", data: data, avatarIcon: "check", avatarColor: getTheme().colors.msgSucc }),
        React.createElement(CardContent, { classes: cardClasses },
            React.createElement(Text, { size: 0, children: `Updated ${num} product${tot > 1 ? "s" : ""}` }))));
};
export default CardImport;
