import * as React from "react";
import ViewBlockAsset from "@warda/library-ui/core/View/ViewBlockAsset";
import AssetExtension from "@warda/library-ui/core/AssetExtension";
import apiUrls from "../../../../api/apiUrlsAnonymous";
import getThumbRendition from "../../../../utils/getThumbRendition";
import { CtxDispatchViewport } from "../../contexts";
import { getAssetPropsMedia } from "../utils";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
const hTitle = 50;
const margin = 10;
const wPreview = Math.min(window.innerWidth / 2, 180) - margin * 4;
const hPreview = isMobile() ? 140 : 200;
const sPreview = Math.min(wPreview, hPreview);
export const blockHeightMedia = hTitle + margin * 3 + sPreview;
const ViewBlockAssetMedia = ({ id, color, size, data, selected, selectedIds, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, onContextMenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const { fileId, mimeType } = data;
    const props = React.useMemo(() => {
        return getAssetPropsMedia({
            dispatchViewport,
            color,
            id,
            data,
            selected,
            selectedIds,
            onContextMenu,
            contextmenuOriginAnchor,
            contextmenuOriginTransf,
            contextmenuPosizionZone,
        });
    }, [
        color,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        data,
        dispatchViewport,
        id,
        onContextMenu,
        selected,
        selectedIds,
    ]);
    return (React.createElement(ViewBlockAsset, { ...props, width: size[0] - margin * 2, preview: {
            previewWidth: sPreview,
            previewHeight: sPreview,
            srcUrl: apiUrls.mediaDownload.url(fileId, getThumbRendition(2, mimeType)),
            mimeType,
            placeholderIcon: props.icon,
        } },
        React.createElement(AssetExtension, { mimeType: mimeType, style: { position: "absolute", bottom: 10, right: 10 } })));
};
export default ViewBlockAssetMedia;
