import * as React from "react";
import Breadcrumb from "@warda/library-ui/core/Breadcrumb";
import TextLoading from "@warda/library-ui/core/TextLoading";
import Text from "@warda/library-ui/core/Text";
import { Initialize } from "@warda/library-ui/interfaces";
import { getFilePath } from "../../api/fetchesApiAnonymous";
import { ACT_VPORT } from "./reducer";
import { CtxDispatchViewport } from "./contexts";
import { AREA } from "../AreaMediaLibrary/constants";
import { getTheme } from "@warda/library-ui/theme";
var ACT;
(function (ACT) {
    ACT["RESET"] = "RESET";
    ACT["INIT_START"] = "INIT_MODAL_START";
    ACT["INIT_LOADING"] = "INIT_MODAL_LOADING";
    ACT["INIT_SUCC"] = "INIT_MODAL_SUCC";
    ACT["INIT_FAIL"] = "INIT_MODAL_FAIL";
})(ACT || (ACT = {}));
const reducerInitState = {
    init: Initialize.NONE,
    fileId: "",
    filePath: [],
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT.RESET:
            return reducerInitState;
        case ACT.INIT_START: {
            const pagePrntId = action.pagePrntId;
            const newFileId = action.newFileId;
            if (pagePrntId === newFileId)
                return reducerInitState;
            newState.init = Initialize.START;
            newState.fileId = newFileId;
            newState.filePath = reducerInitState.filePath;
            return newState;
        }
        case ACT.INIT_LOADING:
            newState.init = Initialize.LOADING;
            return newState;
        case ACT.INIT_SUCC:
            newState.init = Initialize.SUCC;
            newState.filePath = action.path;
            return newState;
        case ACT.INIT_FAIL:
            newState.init = Initialize.FAIL;
            return newState;
        default:
            return state;
    }
};
const FilePath = ({ pagePrntId, file }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { init, fileId, filePath } = state;
    const onClick = React.useCallback((pathId) => {
        dispatchViewport({ type: ACT_VPORT.BREADCRUMB, pathId });
    }, [dispatchViewport]);
    React.useEffect(() => {
        if (init === Initialize.START) {
            (async () => {
                try {
                    dispatch({ type: ACT.INIT_LOADING });
                    const path = await getFilePath(AREA.SHAREPAGE, file);
                    dispatch({ type: ACT.INIT_SUCC, path });
                }
                catch (err) {
                    console.warn(err);
                    dispatch({ type: ACT.INIT_FAIL });
                }
            })();
        }
    }, [file, init]);
    React.useEffect(() => {
        const newFileId = file === null || file === void 0 ? void 0 : file.id;
        if (!!newFileId && fileId !== newFileId) {
            dispatch({ type: ACT.INIT_START, pagePrntId, newFileId });
        }
    }, [file === null || file === void 0 ? void 0 : file.id, fileId, pagePrntId]);
    React.useEffect(() => {
        if (!file) {
            dispatch({ type: ACT.RESET });
        }
    }, [file]);
    if (init === Initialize.NONE) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { style: { color: getTheme().colors.theme1, marginLeft: 10 }, children: "Found in:" }),
        init === Initialize.LOADING ? (React.createElement(TextLoading, { style: { marginLeft: 10 } })) : null,
        init === Initialize.FAIL ? (React.createElement(Text, { weight: "bolder", style: { marginLeft: 10 }, children: "ERROR" })) : null,
        init === Initialize.SUCC ? (React.createElement(Breadcrumb, { maxItems: 4, items: filePath, onClick: onClick })) : null));
};
export default FilePath;
