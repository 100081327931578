import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import { needPagination, getFileTypeText } from "./utils";
const ViewLoadedCount = ({ className, style, type, files, filesTotal, }) => {
    const { countFiles, countTotal } = needPagination({
        type,
        files,
        filesTotal,
    });
    const typeText = getFileTypeText(type).toLocaleLowerCase();
    return (React.createElement(Text, { className: className, style: style, weight: "bolder", ellipsis: true, children: `${countFiles} / ${countTotal} loaded ${typeText}` }));
};
export default ViewLoadedCount;
