import * as React from "react";
import { createUseStyles } from "react-jss";
import Background from "@warda/library-ui/core/Background";
import { getTheme } from "@warda/library-ui/theme";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import Icon from "@warda/library-ui/core/Icon";
import classnames from "classnames";
const getPerc = (x) => (x ? `${x}%` : undefined);
var IType;
(function (IType) {
    IType["ROUND"] = "ROUND";
    IType["SQUARE"] = "SQUARE";
})(IType || (IType = {}));
const useStylesIcon = createUseStyles({
    backgroundIcon: {
        position: "absolute",
        margin: "auto",
        "z-index": 0,
        opacity: 0,
        width: ({ size }) => size,
        height: ({ size }) => size,
        top: ({ top }) => getPerc(top),
        right: ({ right }) => getPerc(right),
        bottom: ({ bottom }) => getPerc(bottom),
        left: ({ left }) => getPerc(left),
        "background-color": "#ffffff",
        transition: "all 1000ms",
        "border-radius": ({ type }) => type === IType.ROUND ? 9999 : 15,
        "box-shadow": "0 5px 10px 5px rgba(0,0,0,0.3)",
        transform: "translate3d(0, 100%, 0)",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
    },
    backgroundIconAnimation: {
        opacity: 0.3,
        transform: "none",
    },
    icon: {
        "font-size": ({ size }) => `${(size / 5) * 2}px !important`,
        color: ({ color }) => hexToRgbA(color, 0.75),
    },
});
const BackgroundScIcon = (p) => {
    const classes = useStylesIcon(p);
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        const animation = setTimeout(() => {
            setReady(true);
        }, 500);
        return () => {
            clearTimeout(animation);
        };
    }, []);
    return (React.createElement("div", { className: classnames({
            [classes.backgroundIcon]: true,
            [classes.backgroundIconAnimation]: ready,
        }) },
        React.createElement(Icon, { className: classes.icon, children: p.icon })));
};
const BackgroundSc = ({ children }) => (React.createElement(Background, { color1: getTheme().colors.theme1, color2: getTheme().colors.theme2 },
    children,
    React.createElement(BackgroundScIcon, { color: getTheme().colors.theme2, type: IType.ROUND, icon: "chat_bubble", size: 100, top: 15, right: 10 }),
    React.createElement(BackgroundScIcon, { color: getTheme().colors.theme2, type: IType.SQUARE, icon: "public", size: 75, top: 40, right: 25 }),
    React.createElement(BackgroundScIcon, { color: getTheme().colors.theme2, type: IType.ROUND, icon: "how_to_reg", size: 150, bottom: 15, right: 15 }),
    React.createElement(BackgroundScIcon, { color: getTheme().colors.theme1, type: IType.SQUARE, icon: "file_download", size: 75, top: 18, left: 12 }),
    React.createElement(BackgroundScIcon, { color: getTheme().colors.theme1, type: IType.ROUND, icon: "thumb_up", size: 150, top: 35, left: 18 }),
    React.createElement(BackgroundScIcon, { color: getTheme().colors.theme1, type: IType.SQUARE, icon: "photo_library", size: 100, bottom: 20, left: 14 })));
export default BackgroundSc;
