import * as React from "react";
import { CellType } from "../../../interfaces";
import { TYPE_FOLDER } from "../../../constants";
import { getCellHeader as getCellFileHeader } from "./CellFile";
import { getCellHeader as getCellFolderHeader } from "./CellFolder";
import Text from "@warda/library-ui/core/Text";
export const needHeader = {
    [CellType.FOLDER]: 1,
    [CellType.FILE]: 1,
};
export const itemsTypeSort = [CellType.FOLDER, CellType.FILE];
export const BlockHeader = ({ type, items, cellIndex }) => {
    let totalFolder = 0;
    let totalFile = 0;
    items.forEach(({ mimeType }) => {
        if (mimeType === TYPE_FOLDER) {
            totalFolder = totalFolder + 1;
        }
        else {
            totalFile = totalFile + 1;
        }
    });
    if (cellIndex > 0)
        return null;
    switch (type) {
        case CellType.FOLDER:
            return (React.createElement(Text, { style: { flex: 1, marginLeft: 14 }, weight: "bolder", children: getCellFolderHeader(totalFolder) }));
        case CellType.FILE:
            return (React.createElement(Text, { style: { flex: 1, marginLeft: 14 }, weight: "bolder", children: getCellFileHeader(totalFile) }));
        default:
            return null;
    }
};
