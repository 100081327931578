import isEmpty from "lodash-es/isEmpty";
import getAggs from "./getAggs";
export const getAggsItems = (id, items) => {
    if (isEmpty(items))
        return items;
    const keySort = `${id}weight`;
    // if has keySort, need to sort
    const aggsItems = !items[0][keySort]
        ? items
        : items.sort((a, b) => {
            try {
                const aKey = a[keySort].buckets[0].key;
                const bKey = b[keySort].buckets[0].key;
                if (aKey > bKey)
                    return 1;
                if (aKey < bKey)
                    return -1;
                return 0;
            }
            catch {
                return 0;
            }
        });
    return aggsItems.map(({ key, doc_count }) => ({ key, doc_count }));
};
export const processAggregationsEs = (aggregations) => {
    return aggregations.map((f) => {
        const { id, items, sum_other_doc_count } = f;
        const itemsProcessed = getAggsItems(id, items);
        return {
            id,
            items: itemsProcessed,
            sum_other_doc_count,
        };
    });
};
const getAggregations = async ({ searchEs, query, include, filterAggregations = [], slcCtlg = "", slcLang = "", }) => {
    const { aggregations } = await searchEs({
        size: 0,
        from: 0,
        query,
        aggs: getAggs({
            filterAggregations,
            include,
            catalogId: slcCtlg,
            languageId: slcLang,
        }),
    });
    return processAggregationsEs(aggregations);
};
export default getAggregations;
