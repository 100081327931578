import * as React from "react";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { typeDocument, typeImage, typeVideo, } from "@warda/library-ui/utils/mimeTypes";
import { useLocation } from "react-router-dom";
import ViewerVideo from "@warda/library-ui/core/ViewerVideo";
import ViewerBox from "../../../ViewerBox";
import apiUrls from "../../../../api/apiUrlsAnonymous";
import { RENDITIONS } from "../../../../interfaces";
import getFileIcon from "../../../Viewer/getFileIcon";
import PyramidZoom from "@warda/library-ui/core/PyramidZoom";
import { anonymousGetBoxDocumentData } from "../../../../api/fetchesApiAnonymous";
import { getSrcFirmed } from "../../../../api/fetchesApi";
const Viewer = ({ assetData }) => {
    const searchString = useLocation().search;
    const query = new URLSearchParams(searchString);
    const link = query.get("link");
    const { mimeType, documentRepoId } = assetData;
    const getBoxData = React.useCallback(async (fileId) => {
        const res = await anonymousGetBoxDocumentData(fileId, link);
        return res;
    }, [link]);
    if (new Set(typeVideo).has(mimeType)) {
        return (React.createElement(ViewerVideo, { src: apiUrls.getRenditionPublic.url(link, documentRepoId, RENDITIONS.video_HQ) }));
    }
    if (new Set(typeImage).has(mimeType)) {
        return (React.createElement(PyramidZoom, { src: apiUrls.zoomMediaPublic.url(link, documentRepoId), getSrcFirmed: getSrcFirmed }));
    }
    if (new Set(typeDocument).has(mimeType)) {
        return React.createElement(ViewerBox, { fileId: documentRepoId, getData: getBoxData });
    }
    return (React.createElement("div", { style: {
            position: "relative",
            flex: 1,
            display: "flex",
            alignItems: "stretch",
            overflow: "hidden",
        } },
        React.createElement(Placeholder, { icon: getFileIcon(mimeType), iconStyle: { marginBottom: 10 }, label: "Zoom unavailable" })));
};
export default Viewer;
