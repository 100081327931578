import UploadItemStatus from "./UploadItemStatus";
const isUploadingState = (status) => {
    switch (status) {
        case UploadItemStatus.ReadyToUpload:
        case UploadItemStatus.S3Uploading:
        case UploadItemStatus.AwaitingConfirmation:
            return true;
        default:
            return false;
    }
};
export default isUploadingState;
