import { getTheme, setTheme } from "@warda/library-ui/theme";
import { ThemeId, THEMES } from "../colors";
const setThemeDefault = () => {
    const defaultThemeId = ThemeId.SOFT_PLUM;
    const currentThemeId = getTheme().id;
    const defaultTheme = THEMES.find((t) => t.id === defaultThemeId);
    if (currentThemeId !== defaultThemeId) {
        setTheme(defaultTheme);
        location.reload();
    }
};
export default setThemeDefault;
