const sortDisplayOrder = (a, b) => {
    const catA_Order = a.data.displayOrder;
    const catA_Id = a.data.id;
    const catB_Order = b.data.displayOrder;
    const catB_Id = b.data.id;
    if (catA_Order < catB_Order)
        return -1;
    if (catA_Order > catB_Order)
        return +1;
    if (catA_Order === catB_Order) {
        return catA_Id < catB_Id ? -1 : +1;
    }
};
const buildTreeINodeCategory = (iCategoryByAttributeIdByCatalogByRoot) => {
    const idMapping = iCategoryByAttributeIdByCatalogByRoot.reduce((acc, el, i) => {
        acc[el.data.id] = i;
        return acc;
    }, {});
    const iItemEsNodeCategory = iCategoryByAttributeIdByCatalogByRoot.map((o) => ({ ...o, children: [] }));
    let root;
    iItemEsNodeCategory.forEach((el) => {
        // Handle the root element
        if (el.data.parent === null) {
            root = el;
            return;
        }
        // Use our mapping to locate the parent element in our data array
        const parentEl = iItemEsNodeCategory[idMapping[el.data.parent]];
        // Add our current el to its parent's `children` array
        parentEl.children = [...(parentEl.children || []), el].sort(sortDisplayOrder);
    });
    return root;
};
const buildINodeCategories = (categories) => {
    const categoriesSorted = [];
    // ogni category ha i nodi con lo stesso root
    const roots = Array.from(new Set(categories.map((o) => o.data.root)))
        .map((rootId) => {
        return categories.find(({ data }) => data.id === rootId);
    })
        .sort(sortDisplayOrder)
        .map((c) => {
        return c.data.id;
    });
    for (let j = 0; j < roots.length; j++) {
        const categoriesByRoot = categories.filter((o) => o.data.root === roots[j]);
        const category = buildTreeINodeCategory(categoriesByRoot);
        categoriesSorted.push(category);
    }
    return categoriesSorted;
};
const buildTreeAttributeStructure = (array) => {
    const result = [];
    const arrayUniqueAttributeSet = Array.from(new Set(array.map((o) => o.data.attributeStructureId)));
    for (let i = 0; i < arrayUniqueAttributeSet.length; i++) {
        const catalogs = [];
        const iCategoryByAttributeId = array.filter((o) => o.data.attributeStructureId === arrayUniqueAttributeSet[i]);
        const arrayUniqueCatalogs = Array.from(new Set(array.map((o) => o.data.catalog))).sort((a, b) => (a < b ? -1 : 1));
        for (let j = 0; j < arrayUniqueCatalogs.length; j++) {
            const idCatalog = arrayUniqueCatalogs[j];
            const iCategoryByAttributeIdByCatalog = iCategoryByAttributeId.filter((o) => o.data.catalog === idCatalog);
            const iCatalog = {
                id: idCatalog,
                categories: buildINodeCategories(iCategoryByAttributeIdByCatalog),
            };
            catalogs.push(iCatalog);
        }
        result.push({ id: arrayUniqueAttributeSet[i], catalogs });
    }
    return result;
};
const depthFirstSearch = (item, result, depth = 1) => {
    result.push({ id: item.id, data: { ...item.data, depth } });
    const nDepth = depth + 1;
    for (let i = 0; i < item.children.length; i++) {
        depthFirstSearch(item.children[i], result, nDepth);
    }
};
const sortCategories = (array) => {
    const r = buildTreeAttributeStructure(array);
    const result = [];
    for (let i = 0; i < r.length; i++) {
        const iAttributeStructureId = r[i];
        for (let j = 0; j < iAttributeStructureId.catalogs.length; j++) {
            const iCatalog = iAttributeStructureId.catalogs[j];
            for (let k = 0; k < iCatalog.categories.length; k++) {
                depthFirstSearch(iCatalog.categories[k], result);
            }
        }
    }
    return result;
};
export default sortCategories;
