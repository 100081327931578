import * as React from "react";
import { DATE_FORMAT } from "@warda/library-ui/interfaces";
import AssetExtension from "@warda/library-ui/core/AssetExtension";
import Text from "@warda/library-ui/core/Text";
import bytesFormat from "@warda/library-ui/utils/bytesFormat";
import * as moment from "moment";
import { ATTRIBUTES_KEY } from "../../constants";
const ViewListAssetChildren = ({ columns, columnsShow, created, mimeType, sizeInBytes, }) => (React.createElement(React.Fragment, null, columns
    .filter((c, index) => columnsShow[index])
    .filter((c, index) => index !== 0)
    .map(({ id, width }) => (React.createElement("div", { key: id, style: {
        width,
        maxWidth: width,
        minWidth: width,
        paddingRight: 10,
        boxSizing: "border-box",
    } },
    id !== ATTRIBUTES_KEY.CREATED ? null : (React.createElement(Text, { ellipsis: true, children: moment(created).format(DATE_FORMAT) })),
    id !== ATTRIBUTES_KEY.SIZE || !sizeInBytes ? null : (React.createElement(Text, { ellipsis: true, children: bytesFormat(sizeInBytes) })),
    id !== ATTRIBUTES_KEY.TYPE || !mimeType ? null : (React.createElement(AssetExtension, { mimeType: mimeType })))))));
export default ViewListAssetChildren;
