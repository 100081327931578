import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Modal from "@warda/library-ui/core/Modal";
import Btn from "@warda/library-ui/core/Btn";
import Text from "@warda/library-ui/core/Text";
const ModalLogout = ({ open, onLogout, onCancel }) => (React.createElement(Modal, { open: open, onClose: onCancel, title: "\uD83D\uDC4B Logout", content: React.createElement(Text, { children: "Are you sure to logout?" }), actions: React.createElement(React.Fragment, null,
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(Btn, { variant: "bold", label: "CANCEL", onClick: onCancel }),
        React.createElement(Btn, { variant: "bold", label: "LOGOUT", onClick: onLogout, color: getTheme().colors.msgFail })) }));
export default ModalLogout;
