export const defaultId = "all";
export const getOptions = (options) => {
    const array = [
        {
            id: defaultId,
            label: "All Levels",
            level: 0,
        },
    ];
    options.forEach(({ id, label, level }) => {
        array.push({
            id,
            label: label.toLocaleUpperCase(),
            level,
        });
    });
    return array;
};
export const getFilterTypeValue = (options, slcId) => {
    const opt = options.find((o) => o.id === slcId);
    if (!!opt)
        return [opt];
    return [];
};
