const getAttributeKey = ({ id, multiCatalog, multiLanguage, isFlat = true }, catalogId = "", languageId = "") => {
    try {
        if (!id || isFlat)
            throw "";
        if (multiLanguage && languageId) {
            return id + `.(${catalogId}).(${languageId})`;
        }
        if (multiCatalog && catalogId) {
            return id + `.(${catalogId}).()`;
        }
        return id + ".().()";
    }
    catch {
        return id;
    }
};
export default getAttributeKey;
export const getAttributeId = (key) => {
    //
    // attributo di sistema
    if (!key.includes(".(")) {
        return {
            attrId: key,
            ctlgId: "",
            langId: "",
        };
    }
    //
    // attributo globale o multiCatalog o multiLanguage
    const [attrId, type, ctlgId, langId] = key.split(")").join("").split(".(");
    return {
        attrId: attrId + `.(${type})`,
        ctlgId,
        langId,
    };
};
