import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import Btn from "@warda/library-ui/core/Btn";
import Icon from "@warda/library-ui/core/Icon";
const useStyle = createUseStyles({
    title: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "5px 0",
    },
    titleLabel: {
        color: getTheme().colors.typography,
    },
    titleButton: {
        marginRight: "-3px",
    },
    titleIcon: {
        fontSize: "16px !important",
        color: getTheme().colors.typography,
        margin: "0 7px",
    },
});
const MenuFiltersValuedTitle = ({ icon, label, filters, onClear, color, }) => {
    const classes = useStyle({});
    const onClick = React.useCallback(() => {
        onClear(filters);
    }, [filters, onClear]);
    return (React.createElement("div", { className: classes.title },
        React.createElement(Icon, { className: classes.titleIcon, children: icon }),
        React.createElement(Text, { size: 2, weight: "bolder", className: classes.titleLabel, children: label }),
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(Btn, { className: classes.titleButton, labelStyle: { color }, color: color, label: `Clear (${filters.length})`, onClick: onClick })));
};
export default MenuFiltersValuedTitle;
