import { createUseStyles } from "react-jss";
import * as React from "react";
import { CellType, SheetLayout, } from "../../../interfaces";
import ViewBlock from "@warda/library-ui/core/View/ViewBlock";
import Placeholder from "@warda/library-ui/core/Placeholder";
import isEmpty from "lodash-es/isEmpty";
import { TYPE_FOLDER } from "../../../constants";
import CellFile, { getCellHeight as getCellFileHeight } from "./CellFile";
import CellFolder, { getCellHeight as getCellFolderHeight } from "./CellFolder";
import { ACT_VPORT } from "../reducer";
import Sorting from "./Sorting";
import { isMacintosh, isWindows } from "@warda/library-ui/utils/deviceUtils";
import { last } from "lodash-es";
import CatchCode, { KeyMap } from "@warda/library-ui/core/CatchCode";
import { CtxDispatchViewport } from "../contexts";
import { needHeader, itemsTypeSort, BlockHeader, } from "../../AreaFiles_DEPRECATED/AreaLayoutBlock/utils";
const getCellHeight = (cellType) => {
    switch (cellType) {
        case CellType.FOLDER:
            return getCellFolderHeight();
        case CellType.FILE:
            return getCellFileHeight();
        default:
            return 50;
    }
};
const useStyles = createUseStyles({
    content: {
        position: "relative",
        flex: 1,
        display: "flex",
        "flex-direction": "column",
        "align-items": "stretch",
    },
});
const Content = ({ items, itemsIdSelected, sort, detailSheet, loading, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const classes = useStyles({});
    const detailOpen = detailSheet === SheetLayout.OPEN;
    const onDeselect = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.ITEMS_DESELECT });
    }, [dispatchViewport]);
    const onSelectAll = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.ITEMS_SELECT_ALL });
    }, [dispatchViewport]);
    const cellRender = React.useCallback((p) => {
        const cellProp = {
            ...p,
            items,
            itemsIdSelected,
            detailOpen,
        };
        switch (p.type) {
            case CellType.FILE:
                return React.createElement(CellFile, { ...cellProp });
            case CellType.FOLDER:
                return React.createElement(CellFolder, { ...cellProp });
            default:
                return React.createElement("div", { children: "- NO_TYPE -" });
        }
    }, [detailOpen, items, itemsIdSelected]);
    const itemsCell = React.useMemo(() => items.map((data) => ({
        id: data.id,
        type: data.mimeType === TYPE_FOLDER ? CellType.FOLDER : CellType.FILE,
        data,
    })), [items]);
    const onSelectIndex = React.useCallback((n) => {
        if (!isEmpty(itemsIdSelected)) {
            const index = items.findIndex(({ id }) => id === last(itemsIdSelected));
            const newIndex = n < 0
                ? Math.max(index + n, 0)
                : Math.min(index + n, items.length - 1);
            const itemId = items[newIndex].id;
            dispatchViewport({ type: ACT_VPORT.ITEMS_SELECT, itemId });
        }
    }, [dispatchViewport, items, itemsIdSelected]);
    const onPressArrowRight = React.useCallback(() => {
        onSelectIndex(+1);
    }, [onSelectIndex]);
    const onPressArrowLeft = React.useCallback(() => {
        onSelectIndex(-1);
    }, [onSelectIndex]);
    const listeners = React.useMemo(() => {
        const l = [
            { toCatch: KeyMap.Escape, onCatch: onDeselect },
            { toCatch: KeyMap.ArrowRight, onCatch: onPressArrowRight },
            { toCatch: KeyMap.ArrowLeft, onCatch: onPressArrowLeft },
        ];
        if (isMacintosh())
            l.push({ toCatch: KeyMap.Meta + "A", onCatch: onSelectAll });
        if (isWindows())
            l.push({ toCatch: KeyMap.Alt + "A", onCatch: onSelectAll });
        return l;
    }, [onDeselect, onPressArrowLeft, onPressArrowRight, onSelectAll]);
    const itemHeader = React.useCallback(({ type, indexCol }) => (React.createElement(BlockHeader, { items: items, type: type, cellIndex: indexCol })), [items]);
    return (React.createElement(CatchCode, { listeners: listeners },
        React.createElement("div", { className: classes.content },
            React.createElement(Sorting, { sort: sort }),
            React.createElement(Placeholder, { open: loading || !items.length, spinner: loading, icon: "folder_open", label: "EMPTY FOLDER" }),
            React.createElement(ViewBlock, { itemRender: cellRender, items: itemsCell, itemWidth: 180, itemHeight: getCellHeight, itemHeader: itemHeader, needHeader: needHeader, itemsSelectedId: itemsIdSelected, itemsTypeSort: itemsTypeSort, onClick: onDeselect, style: { marginLeft: 15 } }))));
};
export default Content;
