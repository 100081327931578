import * as React from "react";
import Card from "@warda/library-ui/core/Card";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Btn from "@warda/library-ui/core/Btn";
import Text from "@warda/library-ui/core/Text";
import Divider from "@warda/library-ui/core/Divider";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
import mixColors from "@warda/library-ui/utils/mixColors";
import Badge from "@warda/library-ui/core/Badge";
const ThemePreviewSample = ({ theme }) => {
    const c = theme.colors;
    return (React.createElement(Card, { elevation: 10, style: {
            display: "flex",
            flexDirection: "column",
            top: 15,
            right: 15,
            height: "100%",
            width: "100%",
            backgroundColor: c.background,
            overflow: "hidden",
            borderColor: c.grayBorder,
        } },
        React.createElement(Toolbar, { style: { padding: "0 10px", backgroundColor: c.background } },
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Badge, { icon: "share", color: c.theme2, style: {
                    backgroundColor: mixColors(0.2, c.background, c.theme2),
                    top: 26,
                    left: 14,
                    zIndex: 3,
                } }),
            React.createElement(Btn, { color: c.theme1, icon: "notifications", iconStyle: { color: c.typography } }),
            React.createElement(Btn, { color: c.theme1, icon: "settings", iconStyle: { color: c.typography }, selected: true }),
            React.createElement(Btn, { style: { backgroundColor: c.grayDrawer }, icon: "person", iconStyle: { color: c.typography } })),
        React.createElement(Divider, { color: c.grayBorder }),
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "row",
                backgroundClip: c.background,
                flex: 1,
                width: "100%",
            } },
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement("div", { style: {
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: c.grayDrawer,
                    borderLeft: `1px solid ${c.grayBorder}`,
                    width: 110,
                    textAlign: "left",
                } },
                React.createElement(BorderShadow, { position: "top", border: false }),
                React.createElement("div", { style: { flex: 1, margin: 10 } },
                    React.createElement(Text, { style: { color: c.typography, marginLeft: 5 }, children: "Field title", size: 0 }),
                    React.createElement("div", { style: {
                            position: "relative",
                            backgroundColor: c.background,
                            padding: "5px 10px",
                            border: `1px solid ${c.theme1}`,
                            borderRadius: theme.borderRadius,
                        } },
                        React.createElement(Text, { style: { color: c.typography }, children: "Input value" }),
                        React.createElement(Text, { style: {
                                borderRadius: theme.borderRadius,
                                position: "absolute",
                                bottom: "-16px",
                                right: "-5px",
                                color: c.theme1,
                                fontStyle: "italic",
                                backgroundColor: mixColors(0.2, c.background, c.theme1),
                                border: `1px solid ${c.theme1}`,
                                padding: "0 5px",
                            }, children: "Unsaved" })))))));
};
export default ThemePreviewSample;
