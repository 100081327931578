import * as React from "react";
import WindowTheme from "./WindowTheme";
import Btn from "@warda/library-ui/core/Btn";
const BtnWindowTheme = () => {
    const [menu, setMenu] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenu(true);
    }, []);
    const onMenuClose = React.useCallback(() => {
        setMenu(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { icon: "palette", tooltip: "Change theme", onClick: onMenuOpen }),
        React.createElement(WindowTheme, { open: menu, onCancel: onMenuClose })));
};
export default BtnWindowTheme;
