import { getRelativePathsFolderId } from "../utils/pathUtils";
import itemsAddAndUploading from "../utils/itemsAddAndUploading";
import { maxSize } from "@warda/library-ui/utils/mimeTypes";
import { MAX_UPLOAD } from "../../../constants";
import itemsUpdate from "../utils/itemsUpdate";
import { UploadArea } from "../UploadArea";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["RESET"] = "RESET";
    ACTIONS["SET_ITEMS"] = "SET_ITEMS";
    ACTIONS["ADD_ITEMS"] = "ADD_ITEMS";
    ACTIONS["MSG_CLOSE"] = "MSG_CLOSE";
    ACTIONS["MSG_OPEN"] = "MSG_OPEN";
    ACTIONS["EXPANDED"] = "EXPANDED";
    ACTIONS["UPDATE_FOLDER_ID_AND_FILE_UPLOADING"] = "UPDATE_FOLDER_ID";
    ACTIONS["SAVED_IN_DA"] = "SAVED_IN_DA";
})(ACTIONS || (ACTIONS = {}));
export const reducerInitState = {
    expanded: true,
    items: [],
    msgStopUpload: false,
    mapFileIdSaved: {},
    relativePathsFolderId: [],
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTIONS.ADD_ITEMS:
            const { folderId, ifiles } = action.uploadsDA;
            const relativePathsFolderId = getRelativePathsFolderId(ifiles, folderId);
            newState.items = itemsAddAndUploading({
                area: UploadArea.da,
                items: newState.items,
                filesToAdd: ifiles,
                maxSize,
                relativePathsFolderId,
                numParallelUpload: MAX_UPLOAD,
            });
            newState.relativePathsFolderId = relativePathsFolderId;
            return newState;
        case ACTIONS.SET_ITEMS:
            newState.items = itemsUpdate({
                area: UploadArea.da,
                items: newState.items,
                item: action.item,
                numParallelUpload: MAX_UPLOAD,
                relativePathsFolderId: newState.relativePathsFolderId,
            });
            return newState;
        case ACTIONS.EXPANDED:
            newState.expanded = !newState.expanded;
            return newState;
        case ACTIONS.MSG_OPEN:
            newState.msgStopUpload = true;
            return newState;
        case ACTIONS.MSG_CLOSE:
            newState.msgStopUpload = false;
            return newState;
        case ACTIONS.RESET:
            return reducerInitState;
        case ACTIONS.UPDATE_FOLDER_ID_AND_FILE_UPLOADING: {
            const { folderId, nameFolderToCreate } = action;
            newState.relativePathsFolderId = newState.relativePathsFolderId.map((c) => c.name === nameFolderToCreate ? { ...c, folderId } : { ...c });
            newState.items = itemsAddAndUploading({
                area: UploadArea.da,
                items: newState.items,
                filesToAdd: [],
                maxSize,
                relativePathsFolderId: newState.relativePathsFolderId,
            });
            return newState;
        }
        case ACTIONS.SAVED_IN_DA: {
            const { id } = action;
            newState.mapFileIdSaved[id] = true;
            return newState;
        }
        default:
            return newState;
    }
};
