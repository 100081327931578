import * as React from "react";
import Toolbar from "@warda/library-ui/core/Toolbar";
import SelectActions from "./SelectActions";
import BtnNavigateBack from "./Buttons/BtnNavigateBack";
import BtnBreadcrumb from "./Buttons/BtnBreadcrumb";
import BtnSort from "./Buttons/BtnSort";
import BtnView from "./Buttons/BtnView";
import BtnSearch from "./Buttons/BtnSearch";
import BtnCleanFilters from "./Buttons/BtnCleanFilters";
import { areFiltersUseful } from "../PanelFilters";
import FilePath from "./FilePath";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import Divider from "@warda/library-ui/core/Divider";
import FilterFileNameMobile from "./Filters/FilterFileNameMobile";
import { ViewMode } from "./constants";
import FilterFileType from "./Filters/FilterFileType";
const ToolbarActions = ({ pagePrntId, files, filesSlcId, folderPath, sortKey, sortOrd, viewMode, viewType, filters, }) => {
    const isFiltered = areFiltersUseful(filters);
    const isSelected = filesSlcId.length > 0;
    if (isMobile()) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Toolbar, null, isSelected ? (React.createElement(React.Fragment, null,
                React.createElement(SelectActions, { filesSlcId: filesSlcId }),
                isMobile() || !isFiltered || filesSlcId.length !== 1 ? null : (React.createElement(FilePath, { pagePrntId: pagePrntId, file: files.find((f) => f.id === filesSlcId[0]) })))) : (React.createElement(React.Fragment, null,
                folderPath.length === 0 || viewMode === ViewMode.SEARCH ? (React.createElement(FilterFileNameMobile, { value: filters[0].value, showClose: viewMode === ViewMode.SEARCH, autofocus: viewMode === ViewMode.SEARCH })) : (React.createElement(React.Fragment, null,
                    React.createElement(BtnNavigateBack, { folderPath: folderPath }),
                    React.createElement(BtnBreadcrumb, { pagePrntId: pagePrntId, folderPath: folderPath }),
                    React.createElement("div", { style: { flex: 1 } }))),
                React.createElement(BtnSearch, { viewMode: viewMode })))),
            React.createElement(Divider, null),
            React.createElement(Toolbar, null, viewMode === ViewMode.SEARCH ? (React.createElement(React.Fragment, null,
                React.createElement(FilterFileType, { value: filters[1].value, options: filters[1].options }),
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(BtnCleanFilters, { filters: filters }))) : (React.createElement(React.Fragment, null,
                React.createElement(BtnSort, { sortKey: sortKey, sortOrd: sortOrd }),
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(BtnView, { view: viewType }))))));
    }
    return (React.createElement(Toolbar, null,
        isSelected ? (React.createElement(React.Fragment, null,
            React.createElement(SelectActions, { filesSlcId: filesSlcId }),
            !isFiltered || filesSlcId.length !== 1 ? null : (React.createElement(FilePath, { pagePrntId: pagePrntId, file: files.find((f) => f.id === filesSlcId[0]) })))) : (React.createElement(React.Fragment, null,
            React.createElement(BtnNavigateBack, { folderPath: folderPath }),
            React.createElement(BtnBreadcrumb, { pagePrntId: pagePrntId, folderPath: folderPath }))),
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(BtnSort, { sortKey: sortKey, sortOrd: sortOrd }),
        React.createElement(BtnView, { view: viewType })));
};
export default ToolbarActions;
