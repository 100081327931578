import * as React from "react";
import { createUseStyles } from "react-jss";
import Zoom from "@warda/library-ui/core/Transitions/Zoom";
import { getTheme } from "@warda/library-ui/theme";
import BtnBase from "@warda/library-ui/core/BtnBase";
const useStyles = createUseStyles({
    button: {
        color: ({ color }) => color,
        position: "absolute",
        "z-index": 1,
        top: 0,
        left: 0,
        padding: 10,
        "border-radius": getTheme().borderRadius,
    },
    checkbox: {
        display: "flex",
        "align-items": "center",
        backgroundColor: getTheme().colors.background,
        overflow: "hidden",
        "min-height": ({ size }) => size,
        "min-width": ({ size }) => size,
        "max-height": ({ size }) => size,
        "max-width": ({ size }) => size,
        height: ({ size }) => size,
        width: ({ size }) => size,
        border: ({ selected, color }) => `1px solid ${selected ? color : getTheme().colors.grayBorder}`,
        "border-radius": getTheme().borderRadius,
        padding: 2,
    },
    content: {
        width: "inherit",
        height: "inherit",
        "border-radius": 1,
        "background-color": ({ color }) => color,
    },
});
const AssetCheckbox = ({ color, size, selected, onClick }) => {
    const classes = useStyles({
        selected,
        color,
        size: size - 6,
    });
    return (React.createElement(BtnBase, { className: classes.button, onClick: onClick },
        React.createElement("div", { className: classes.checkbox },
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Zoom, { open: selected },
                React.createElement("div", { className: classes.content })),
            React.createElement("div", { style: { flex: 1 } }))));
};
export default AssetCheckbox;
