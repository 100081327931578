import * as React from "react";
import emptyFn from "@warda/library-ui/utils/emptyFn";
export const CtxDispatchMain = React.createContext(emptyFn);
export const CtxSetSnackbar = React.createContext(emptyFn);
export const CtxSetUserPref = React.createContext(emptyFn);
export const CtxPermissions = React.createContext([]);
export const CtxTenantUsers = React.createContext([]);
export const CtxTenantM2ms = React.createContext([]);
export const CtxUserData = React.createContext(undefined);
export const CtxUserPref = React.createContext(undefined);
