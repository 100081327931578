import { ACT_VPORT } from "./reducer";
import { TYPE_FOLDER } from "../../constants";
const DETAILS = "DETAILS";
const DOWNLOAD_SINGLE = "DOWNLOAD_SINGLE";
const DOWNLOAD_ARCHIV = "DOWNLOAD_ARCHIV";
export const getItemActions = ({ tenantId, contextmenu, detailOpen, items, itemsIdSelected, }) => {
    const selectionSet = new Set(itemsIdSelected);
    return [
        {
            id: DETAILS,
            icon: "chrome_reader_mode",
            label: "Details",
            hidden: !contextmenu || detailOpen,
        },
        {
            id: DOWNLOAD_SINGLE,
            icon: "file_download",
            label: "Download",
            hidden: itemsIdSelected.length === 0 ||
                itemsIdSelected.length > 1 ||
                !!items.find(({ id, mimeType }) => {
                    return selectionSet.has(id) && mimeType === TYPE_FOLDER;
                }),
        },
        {
            id: DOWNLOAD_ARCHIV,
            icon: "file_download",
            label: "Download",
            hidden: !tenantId ||
                itemsIdSelected.length === 0 ||
                (itemsIdSelected.length === 1 &&
                    items.find(({ id }) => {
                        return id === itemsIdSelected[0];
                    }).mimeType !== TYPE_FOLDER),
        },
    ].filter((a) => !a.hidden);
};
export const MAP_TYPE = {
    [DETAILS]: ACT_VPORT.ACTION_OPEN_DETAILS,
    [DOWNLOAD_SINGLE]: ACT_VPORT.ACTION_DOWNLOAD_SINGLE,
    [DOWNLOAD_ARCHIV]: ACT_VPORT.ACTION_DOWNLOAD_ARCHIV,
};
