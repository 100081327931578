import * as React from "react";
import { useAsyncAbortable } from "react-async-hook";
import useConstant from "use-constant";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Initialize } from "@warda/library-ui/interfaces";
import { anonymousSearchFilesDA } from "../../api/fetchesApiAnonymous";
import last from "@warda/library-ui/utils/last";
const SEARCH_ES_EMPTY = {
    initialize: Initialize.LOADING,
    items: [],
    itemsTotal: 0,
};
const searchEs = async ({ path, sort: sortCurrent, paginationCurr, paginationSize, link, }) => {
    try {
        const { items, itemsTotal } = await anonymousSearchFilesDA({
            size: paginationSize,
            from: paginationSize * (paginationCurr - 1),
            accessCode: link,
            parentFolder: last(path).id,
            sortKey: sortCurrent.id,
            sortOrder: sortCurrent.order,
        });
        return {
            initialize: Initialize.SUCC,
            items,
            itemsTotal,
        };
    }
    catch {
        return {
            initialize: Initialize.FAIL,
            items: [],
            itemsTotal: 0,
        };
    }
};
const useSearchEs = (a) => {
    const [searchTime, setSearchTime] = React.useState(null);
    const debounceSearchDocs = useConstant(() => AwesomeDebouncePromise(searchEs, 300));
    const searchRes = useAsyncAbortable(async (abortSignal, argDocs) => {
        if (argDocs === null)
            return SEARCH_ES_EMPTY;
        return debounceSearchDocs(a);
    }, [searchTime]);
    return {
        searchTime,
        setSearchTime,
        searchRes,
    };
};
export default useSearchEs;
