import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Icon from "@warda/library-ui/core/Icon";
import Text from "@warda/library-ui/core/Text";
import BtnBase from "@warda/library-ui/core/BtnBase";
import { THEME_LABEL_MODE, ThemeIdMode } from "../../colors";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
const getModeIcon = (mode) => {
    switch (mode) {
        case ThemeIdMode.SOFT:
            return "sunny";
        case ThemeIdMode.DARK:
            return "nightlight";
        default:
            return "help_center";
    }
};
const useStyles = createUseStyles({
    select: {
        height: 40,
        width: 200,
        borderRadius: getTheme().borderRadius,
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        margin: 2,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
    selected: {
        backgroundColor: hexToRgbA(getTheme().colors.theme1, 0.2) + " !important",
    },
    icon: {
        fontSize: 14,
        height: 25,
        width: 25,
        borderRadius: 100,
        margin: "0 10px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    iconSelected: {
        backgroundColor: getTheme().colors.background,
        color: getTheme().colors.theme1,
    },
});
const SelectThemeMode = ({ value, draft, onClick }) => {
    const classes = useStyles({});
    const selected = value === draft;
    const cbOnClick = React.useCallback(() => {
        onClick(value);
    }, [onClick, value]);
    return (React.createElement(BtnBase, { color: getTheme().colors.theme1, className: classnames({
            [classes.select]: true,
            [classes.selected]: selected,
        }), onClick: cbOnClick },
        React.createElement(Icon, { className: classnames({
                [classes.icon]: true,
                [classes.iconSelected]: selected,
            }), children: getModeIcon(value) }),
        React.createElement(Text, { weight: "bolder", style: selected ? { color: getTheme().colors.theme1 } : undefined, children: THEME_LABEL_MODE[value] }),
        React.createElement("div", { style: { flex: 1 } })));
};
export default SelectThemeMode;
