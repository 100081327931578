import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import { Initialize } from "@warda/library-ui/interfaces";
import apiUrls from "../../api/apiUrlsAnonymous";
import { getTenantId } from "./constants";
import { getTheme } from "@warda/library-ui/theme";
const TenantLogo = ({ className, style }) => {
    const tenantId = getTenantId();
    const [init, setInit] = React.useState(Initialize.LOADING);
    const onLoad = React.useCallback(() => {
        setInit(Initialize.SUCC);
    }, []);
    const onError = React.useCallback(() => {
        setInit(Initialize.FAIL);
    }, []);
    if (init === Initialize.FAIL) {
        return (React.createElement(Text, { className: className, style: {
                ...style,
                margin: "0 10px",
                fontSize: 25,
                color: getTheme().colors.theme1,
            }, children: "SeeCommerce" }));
    }
    return (React.createElement("img", { className: className, style: { ...style, height: 25 }, src: apiUrls.getLogoTenant.url(tenantId), alt: "", onLoad: onLoad, onError: onError }));
};
export default TenantLogo;
