import * as React from "react";
import { ACT_VPORT } from "../reducer";
import FieldSearch from "@warda/library-ui/core/FieldSearch";
import { CtxDispatchViewport } from "../contexts";
import FilterFileType from "./FilterFileType";
import BtnCleanFilters from "../Buttons/BtnCleanFilters";
const FiltersDesktop = ({ hidden, filters }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const onChange = React.useCallback((value) => {
        dispatchViewport({
            type: ACT_VPORT.FILTER,
            clearAll: false,
            index: 0,
            value,
        });
    }, [dispatchViewport]);
    if (hidden)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldSearch, { style: {
                borderRadius: 5,
                padding: "1px 5px",
                width: 300,
                margin: "0 5px 0 25px",
            }, placeholder: "Search in Sharepage...", value: filters[0].value, onChange: onChange }),
        React.createElement(FilterFileType, { value: filters[1].value, options: filters[1].options }),
        React.createElement(BtnCleanFilters, { filters: filters })));
};
export default FiltersDesktop;
