import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import MultiDownloads from "@warda/library-ui/core/MultiDownloads";
import isEmpty from "lodash-es/isEmpty";
import { ACTION_MAIN } from "../reducer";
import Btn from "@warda/library-ui/core/Btn";
import Modal from "@warda/library-ui/core/Modal";
import { CtxDispatchMain, CtxSetSnackbar } from "../contexts";
import { Severity } from "@warda/library-ui/interfaces";
const useStyles = createUseStyles({
    multiDownload: {
        left: 25,
        bottom: 75,
        right: "auto",
    },
});
const WindowDownloads = ({ downloads }) => {
    const classes = useStyles({});
    const dispatchMain = React.useContext(CtxDispatchMain);
    const setSnackbar = React.useContext(CtxSetSnackbar);
    const [modalConfirmStop, setModalConfirmStop] = React.useState(false);
    const onModalClose = React.useCallback(() => {
        setModalConfirmStop(false);
    }, []);
    const onClose = React.useCallback(() => {
        dispatchMain({ type: ACTION_MAIN.DOWNLOADS__RESET });
        setModalConfirmStop(false);
    }, [dispatchMain]);
    const onCloseCheck = React.useCallback(() => {
        if (!isEmpty(downloads) && downloads.find((x) => x.loading === true)) {
            setModalConfirmStop(true);
        }
        else {
            onClose();
        }
    }, [downloads, onClose]);
    const onCopyUrlToClipboard = React.useCallback(() => {
        setSnackbar(Severity.INFO, "Url copied to clipboard");
    }, [setSnackbar]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MultiDownloads, { className: classes.multiDownload, items: downloads, open: !isEmpty(downloads), onClose: onCloseCheck, onCopyUrlToClipboard: onCopyUrlToClipboard }),
        React.createElement(Modal, { open: modalConfirmStop, onClose: onModalClose, title: "Cancel Download", content: React.createElement(Text, { children: "Are you sure you want to cancel download?" }), actions: React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { variant: "bold", label: "CONTINUE", onClick: onModalClose }),
                React.createElement(Btn, { variant: "bold", color: getTheme().colors.msgFail, label: "STOP DOWNLOAD", onClick: onClose })) })));
};
export default WindowDownloads;
