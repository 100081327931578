import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Btn from "@warda/library-ui/core/Btn";
import emptyFn from "@warda/library-ui/utils/emptyFn";
const BtnCopy = ({ classes, entityIds = [] }) => {
    return (React.createElement(Btn, { small: true, className: classes.btnCopy, style: entityIds.length === 0 ? { scale: 0 } : undefined, color: getTheme().colors.theme2, icon: "content_copy", copyToClipboard: entityIds.join("\n"), onClick: emptyFn, tooltip: [
            entityIds.length === 1
                ? "Copy Product Key"
                : `Copy ${entityIds.length} Products Key`,
            entityIds.join("\n"),
        ] }));
};
export default BtnCopy;
