import * as React from "react";
import BtnMarkRead from "./BtnMarkRead";
import BtnDelete from "./BtnDelete";
import Text from "@warda/library-ui/core/Text";
import CardAvatar from "./CardAvatar";
import BtnCopy from "./BtnCopy";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import * as moment from "moment";
import Tooltip from "@warda/library-ui/core/Tooltip";
TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
const CardTitle = ({ classes, dispatch, data, title, entityIds = [], avatarIcon, avatarColor, }) => {
    const { id, from, markedAsRead, creation } = data;
    return (React.createElement("div", { className: classes.cardTitle },
        React.createElement(BtnMarkRead, { dispatch: dispatch, id: id, markedAsRead: markedAsRead }),
        React.createElement(CardAvatar, { userId: avatarIcon ? undefined : from, icon: avatarIcon, color: avatarColor }),
        React.createElement(Text, { className: classes.textTitle, children: title }),
        React.createElement(Tooltip, { title: moment(creation).format("DD/MM/YY - HH:mm") },
            React.createElement("div", null,
                React.createElement(Text, { className: classes.textDate, size: 0, children: String(timeAgo.format(new Date(creation).getTime())) }))),
        React.createElement(BtnCopy, { classes: classes, entityIds: entityIds }),
        React.createElement(BtnDelete, { classes: classes, dispatch: dispatch, id: id })));
};
export default CardTitle;
