import { USER_PREF_DEFAULT } from "../constants";
import { PREF_KEY } from "../constantsKey";
const updatePreferences = (p, payload = {}) => {
    const newPreferences = {
        ...USER_PREF_DEFAULT,
        ...p,
        ...payload,
    };
    const unknown = {};
    Object.keys(newPreferences).forEach((k) => {
        const keysAccepted = new Set(Object.values(PREF_KEY));
        if (!keysAccepted.has(k)) {
            unknown[k] = newPreferences[k];
            delete newPreferences[k];
        }
    });
    if (Object.keys(unknown).length > 0) {
        console.log("[PREFERENCES_UNKNOWN]", unknown);
    }
    return newPreferences;
};
export default updatePreferences;
