const createCORSRequest = ({ method, url, opts, }) => {
    let xhr = new XMLHttpRequest();
    if (xhr.withCredentials !== null) {
        xhr.open(method, url, true);
        if ((opts === null || opts === void 0 ? void 0 : opts.withCredentials) != null) {
            xhr.withCredentials = opts.withCredentials;
        }
    }
    else {
        xhr = null;
    }
    return xhr;
};
export default createCORSRequest;
