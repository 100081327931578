import * as React from "react";
import { createUseStyles } from "react-jss";
import Divider from "@warda/library-ui/core/Divider";
import Content from "./Content";
import reducer, { reducerInitState, ACT_VPORT } from "./reducer";
import { getTheme } from "@warda/library-ui/theme";
import useSearchEs from "./useSearchEs";
import { useLocation } from "react-router-dom";
import PlaceholderNoLink from "./PlaceholderNoLink";
import PanelDetail from "./PanelDetail";
import { Initialize } from "@warda/library-ui/interfaces";
import { getWebSocketConnectionId, openWebSocket, registerWebSocketCallback, unregisterWebSocketCallback, } from "../webSocket";
import webSocketCallbacks from "./websocketCallbacks";
import last from "@warda/library-ui/utils/last";
import WindowDownloads from "./WindowDownloads";
import { anonymousMassiveDownload } from "../../api/fetchesApiAnonymous";
import { v4 as uuidv4 } from "uuid";
import { CtxDispatchViewport } from "./contexts";
import { PRODUCT_ID } from "../../constantsKey";
import ToolbarHeader from "./ToolbarHeader";
const useStyles = createUseStyles({
    main: {
        position: "relative",
        flex: 1,
        display: "flex",
        height: "100%",
        width: "100%",
        "flex-direction": "row",
        "align-items": "stretch",
    },
    toolbarMain: {
        "background-color": getTheme().colors.grayDrawer,
    },
    content: {
        position: "relative",
        flex: 1,
        display: "flex",
        "flex-direction": "column",
        "align-items": "stretch",
        "min-height": 0, // fix sidepanels height
    },
});
const Area = () => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, items, itemsIdSelected, itemsToDownload, path, sort, paginationCurr, paginationSize, detailSheet, downloads, } = state;
    const assetDataId = last(itemsIdSelected);
    const assetData = items.find((item) => item.id === assetDataId);
    const searchString = useLocation().search;
    const query = new URLSearchParams(searchString);
    const link = query.get("link");
    const tenantId = query.get("tenantId");
    const { setSearchTime, searchRes } = useSearchEs({
        path,
        sort,
        paginationCurr,
        paginationSize,
        link,
    });
    const loading = initialize === Initialize.START || initialize === Initialize.LOADING;
    const serializedPath = JSON.stringify(path);
    const serializedSort = JSON.stringify(sort);
    const serializedPagSize = JSON.stringify(paginationSize);
    const serializedPagCurr = JSON.stringify(paginationCurr);
    const serializedParams = serializedPath + serializedSort + serializedPagSize + serializedPagCurr;
    // search items
    React.useEffect(() => {
        if (!!serializedParams) {
            setSearchTime(new Date().getTime());
        }
    }, [serializedParams, setSearchTime]);
    // initialize content
    React.useEffect(() => {
        if (!!searchRes.result) {
            dispatch({ type: ACT_VPORT.INITIALIZE_ENDED, data: searchRes.result });
        }
    }, [searchRes.result]);
    // refresh items
    React.useEffect(() => {
        if (initialize === Initialize.START) {
            setSearchTime(new Date().getTime());
            dispatch({ type: ACT_VPORT.INITIALIZE_LOADING });
        }
    }, [initialize, setSearchTime]);
    // Avvio websocket
    React.useEffect(() => {
        const urlWebSocket = `${process.env.WEBSOCKET_URL}/anonymous-socket/${PRODUCT_ID}/${tenantId}`;
        openWebSocket(urlWebSocket, true);
    }, [tenantId]);
    // add websocket listener
    React.useEffect(() => {
        webSocketCallbacks.forEach((x) => {
            registerWebSocketCallback({
                id: x.id,
                callback: x.callback,
                dispatch,
            });
        });
        return () => {
            webSocketCallbacks.forEach((x) => {
                unregisterWebSocketCallback(x.id);
            });
        };
    }, []);
    // manage archiver downloads
    React.useEffect(() => {
        (async () => {
            if (!!itemsToDownload.length) {
                const correlationId = uuidv4();
                try {
                    await anonymousMassiveDownload({
                        correlationId,
                        connectionId: getWebSocketConnectionId(),
                        downloadItems: itemsToDownload,
                        currentFolders: path,
                        tenantId,
                        accessCode: link,
                    });
                    dispatch({
                        type: ACT_VPORT.DOWNLOADS_ADDED,
                        correlationId,
                        filename: `${correlationId}.zip`,
                    });
                }
                catch {
                    dispatch({ type: ACT_VPORT.DOWNLOADS_RESET });
                }
            }
        })();
    }, [itemsToDownload, link, path, tenantId]);
    if (initialize === Initialize.FAIL) {
        return React.createElement(PlaceholderNoLink, null);
    }
    return (React.createElement(CtxDispatchViewport.Provider, { value: dispatch },
        React.createElement("div", { className: classes.main },
            React.createElement("div", { className: classes.content },
                React.createElement(ToolbarHeader, { items: items, itemsIdSelected: itemsIdSelected, path: path, detailSheet: detailSheet, loading: loading }),
                React.createElement(Divider, { style: { margin: "0 15px" } }),
                React.createElement(Content, { items: items, itemsIdSelected: itemsIdSelected, sort: sort, detailSheet: detailSheet, loading: loading })),
            React.createElement(PanelDetail, { detailSheet: detailSheet, assetData: assetData }),
            React.createElement(WindowDownloads, { downloads: downloads }))));
};
export default Area;
