//////// ⚠️ key parameter
export const KEYPAR_ENTITY = "id";
export const KEYPAR_DETAIL = "dp";
export const KEYPAR_TAB = "tab";
export const KEYPAR_IMG = "img";
////////
export const PRODUCT_ID = "seecommerce";
export var INDEX_NAME;
(function (INDEX_NAME) {
    INDEX_NAME["TABULAR"] = "tabular";
    INDEX_NAME["TABULARROOT"] = "tabularroot";
    INDEX_NAME["CONFIG"] = "config";
    INDEX_NAME["DICTIONARIES"] = "dictionaries";
    INDEX_NAME["CATALOGS"] = "catalogs";
    INDEX_NAME["CATEGORIES"] = "categories";
    INDEX_NAME["MEDIA"] = "media";
    INDEX_NAME["PUBLC"] = "publications";
    INDEX_NAME["READY"] = "ready";
    INDEX_NAME["FILE_STORAGE"] = "file-storage";
    INDEX_NAME["CDN_USAGE"] = "cdn-usage";
    INDEX_NAME["MEDIALIBRARY_FARDO"] = "medialibrary";
})(INDEX_NAME || (INDEX_NAME = {}));
export const KEY_VIEW_CHECK = "viewCheck";
export const KEY_VIEW_STATUS = "viewStatus";
export const KEY_VIEW_DATA = "viewData";
export const KEY_MEDIA = "media";
export const KEY_READY_MEDIA = "readyMedia";
export const KEY_READY_ATTRS = "readyAttributes";
export const KEY_PUBLC_MEDIA = "mediaPublications";
export const KEY_PUBLC_ATTRS = "attributePublications";
export const KEY_CATALOG = "catalogs";
export const KEY_ENTITY_STRUCTURE_ID = "entityStructureId";
export const KEY_ROOT_DOCUMENT_ID = "rootDocumentId";
export const KEY_ENTITY_ID = "entityId";
export const KEY_ENTITY_TYPE = "entityType";
export const KEY_CARRYOVER_PARENT = "carryOverParent";
export const KEY_ATTRIBUTE_SETS = "attributeSets";
export const KEY_ASSIGNMENTS = "assignments";
export const KEY_VIEWS_EDITING_PERFORMED = "viewsEditingPerformed"; // TODO DEPRECATED
export const KEY_VIEWS_EDITING_MEDIA = "mediaViewsEditingPerformed";
export const KEY_VIEWS_EDITING_DOCUMENT = "documentViewsEditingPerformed";
export const KEY_EDITED_ATTRIBUTES = "editedAttributes";
export const KEY_BUSINESS_IDS = "businessIds";
export const FILTERS_STICKY = new Set([KEY_ENTITY_TYPE, KEY_CATALOG]);
export var ZOOM_COLORS_KEY;
(function (ZOOM_COLORS_KEY) {
    ZOOM_COLORS_KEY["BLACK"] = "BLACK";
    ZOOM_COLORS_KEY["GRAY1"] = "GRAY1";
    ZOOM_COLORS_KEY["GRAY2"] = "GRAY2";
    ZOOM_COLORS_KEY["WHITE"] = "WHITE";
})(ZOOM_COLORS_KEY || (ZOOM_COLORS_KEY = {}));
export var ASSIGNEES_SORTS_KEY;
(function (ASSIGNEES_SORTS_KEY) {
    ASSIGNEES_SORTS_KEY["fullname"] = "fullname";
    ASSIGNEES_SORTS_KEY["frequency"] = "frequency";
})(ASSIGNEES_SORTS_KEY || (ASSIGNEES_SORTS_KEY = {}));
export var PREF_KEY;
(function (PREF_KEY) {
    PREF_KEY["filterType"] = "filterType";
    PREF_KEY["filterCtlg"] = "filterCtlg";
    PREF_KEY["filterLang"] = "filterLang";
    PREF_KEY["detailAnnotationsShow"] = "detailAnnotationsShow";
    PREF_KEY["detailAnnotationsType"] = "detailAnnotationsType";
    PREF_KEY["detailBackgroundColor"] = "detailBackgroundColor";
    PREF_KEY["detailEditingAttrCtlgs"] = "detailEditingAttrCtlgs";
    PREF_KEY["detailEditingAttrLangs"] = "detailEditingAttrLangs";
    PREF_KEY["categoryDrawerExpanded"] = "categoryDrawerExpanded";
    PREF_KEY["categoryFilterSelected"] = "categoryFilterSelected";
    PREF_KEY["gridPreviewMedias"] = "gridPreviewMedias";
    PREF_KEY["productsFiltersSetLevels"] = "productsFiltersSetLevels";
    PREF_KEY["productsColumnsSetLevels"] = "productsColumnsSetLevels";
    PREF_KEY["productsLayout"] = "productsLayout";
    PREF_KEY["medialibLayout"] = "medialibLayout";
    PREF_KEY["jsonstoreContext"] = "jsonstoreContext";
    PREF_KEY["themeId"] = "themeId";
})(PREF_KEY || (PREF_KEY = {}));
export var JsonstoreContext;
(function (JsonstoreContext) {
    JsonstoreContext["userPreferences"] = "user-preferences";
    JsonstoreContext["productsSetsColumns"] = "catalog_grid_columns";
    JsonstoreContext["productsSetsFilters"] = "catalog_grid_filters";
    JsonstoreContext["productsSetsAttribs"] = "catalog_grid_fields_panel_details";
    JsonstoreContext["medialibSetsFilters"] = "medialibrary_filters";
    JsonstoreContext["stagingSetsColumns"] = "stagingarea_grid_columns";
    JsonstoreContext["stagingSetsFilters"] = "stagingarea_grid_filters";
    JsonstoreContext["productsReadyAttrs"] = "bulk_ready_attributes";
    JsonstoreContext["productsExportXlsx"] = "export_xlsx";
})(JsonstoreContext || (JsonstoreContext = {}));
export var JsonstoreAction;
(function (JsonstoreAction) {
    JsonstoreAction["GET"] = "GET";
    JsonstoreAction["CREATE"] = "CREATE";
    JsonstoreAction["DELETE"] = "DELETE";
    JsonstoreAction["UPDATE"] = "UPDATE";
})(JsonstoreAction || (JsonstoreAction = {}));
