import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Badge from "@warda/library-ui/core/Badge";
import isAssetDateExpired from "../isAssetDateExpired";
const BadgeExpirationDate = ({ className, style, data, }) => {
    if (!isAssetDateExpired(data))
        return null;
    return (React.createElement(Badge, { color: getTheme().colors.msgWarn, className: className, style: style, icon: "warning", tooltip: "Copyright expiration date is expired" }));
};
export default BadgeExpirationDate;
