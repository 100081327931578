import * as React from "react";
import { ACT_VPORT } from "../reducer";
import Btn from "@warda/library-ui/core/Btn";
import { CtxDispatchViewport } from "../contexts";
import { isEmpty } from "lodash-es";
const BtnNavigateBack = ({ className, style, folderPath, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const cbOnNavigateBack = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.NAVIGATION });
    }, [dispatchViewport]);
    if (isEmpty(folderPath))
        return null;
    return (React.createElement(Btn, { className: className, style: style, icon: "arrow_back", tooltip: "Back", onClick: cbOnNavigateBack }));
};
export default BtnNavigateBack;
