import { AttributeFamily } from "../../../interfaces";
export const CROSS_CTLGS_ID = "CROSS_CTLGS_ID";
export const CROSS_CTLGS_LABEL = "Cross-Catalog mode";
export const ATT_NO_LANG_ID = "untranslated"; // key coordinata col BE
export const ATT_NO_LANG_LABEL = "Untranslated";
export const ATT_GLOBAL_ID = "global";
export const ATT_GLOBAL_LABEL = "Global";
export const KEY_IDS_MERGED = "KEY_IDS_MERGED";
export const FAMILY_CONF = [
    {
        id: AttributeFamily.MEDIA,
        label: AttributeFamily.MEDIA,
        icon: "",
        bulk: false,
        sets: false,
        children: [],
    },
    {
        id: AttributeFamily.DOCUMENTS,
        label: AttributeFamily.DOCUMENTS,
        icon: "",
        bulk: false,
        sets: false,
        children: [],
    },
    {
        id: AttributeFamily.CATEGORIES,
        label: AttributeFamily.CATEGORIES,
        icon: "",
        bulk: true,
        sets: false,
        children: [],
    },
    {
        id: AttributeFamily.ASSOCIATION,
        label: AttributeFamily.ASSOCIATION,
        icon: "",
        bulk: true,
        sets: false,
        children: [],
    },
    {
        id: AttributeFamily.ALL_ATTRIBUTES,
        label: AttributeFamily.ALL_ATTRIBUTES,
        icon: "",
        bulk: true,
        sets: true,
        children: [
            AttributeFamily.MASTER,
            AttributeFamily.PLANNING,
            AttributeFamily.EDITORIAL,
            AttributeFamily.MONITORING,
            AttributeFamily.OTHERS,
        ],
    },
    {
        id: AttributeFamily.MASTER,
        label: AttributeFamily.MASTER,
        icon: "arrow_right",
        bulk: false,
        sets: true,
        children: [],
    },
    {
        id: AttributeFamily.PLANNING,
        label: AttributeFamily.PLANNING,
        icon: "arrow_right",
        bulk: true,
        sets: true,
        children: [],
    },
    {
        id: AttributeFamily.EDITORIAL,
        label: AttributeFamily.EDITORIAL,
        icon: "arrow_right",
        bulk: true,
        sets: true,
        children: [],
    },
    {
        id: AttributeFamily.MONITORING,
        label: AttributeFamily.MONITORING,
        icon: "arrow_right",
        bulk: false,
        sets: true,
        children: [],
    },
    {
        id: AttributeFamily.OTHERS,
        label: AttributeFamily.OTHERS,
        icon: "arrow_right",
        bulk: false,
        sets: true,
        children: [],
    },
];
