import createCORSRequest from "./createCORSRequest";
import scrubFilename from "./scrubFilename";
class S3Uploader {
    constructor(options = {}) {
        this.onFinishS3Put = (fileId, file) => {
            console.log(`onFinishS3Put: fileId ${fileId} for file name ${file.name}`);
        };
        this.onProgress = (percent, status, processedFile) => {
            console.log("onProgress()", percent, status, processedFile);
        };
        this.onError = (status, file) => {
            console.log("onError()", status, file);
        };
        this.server = "";
        this.signingUrl = "/sign-s3";
        this.signingUrlMethod = "GET";
        this.successResponses = [200, 201];
        this.httprequest = null;
        this.abortUpload = function () {
            if (this.httprequest)
                this.httprequest.abort();
            this.onAbort();
        };
        Object.assign(this, options);
    }
    handleFileSelect(file, mimeType) {
        const result = [];
        this.preprocess(file, (processedFile) => {
            this.onProgress(0, "Waiting", processedFile);
            result.push(this.uploadFile(processedFile, mimeType));
            return result;
        });
    }
    preprocess(file, next) {
        // console.log("base.preprocess()", file);
        return next(file);
    }
    onSignedUrl(result) {
        console.log("onSignedUrl ", result);
    }
    createCORSRequest(method, url, opts) {
        return createCORSRequest({ method, url, opts });
    }
    executeOnSignedUrl(file, mimeType, callback) {
        const fileName = scrubFilename(file.name);
        let queryString = `?objectName=${fileName}&contentType=${encodeURIComponent(mimeType)}`;
        if (this.s3path) {
            queryString += `&path=${encodeURIComponent(this.s3path)}`;
        }
        if (this.signingUrlQueryParams) {
            const signingUrlQueryParams = typeof this.signingUrlQueryParams === "function"
                ? this.signingUrlQueryParams()
                : this.signingUrlQueryParams;
            Object.keys(signingUrlQueryParams).forEach((key) => {
                const val = signingUrlQueryParams[key];
                queryString += `&${key}=${val}`;
            });
        }
        const xhr = this.createCORSRequest(this.signingUrlMethod, this.server + this.signingUrl + queryString, { withCredentials: this.signingUrlWithCredentials });
        if (this.signingUrlHeaders) {
            const signingUrlHeaders = typeof this.signingUrlHeaders === "function"
                ? this.signingUrlHeaders()
                : this.signingUrlHeaders;
            Object.keys(signingUrlHeaders).forEach((key) => {
                const val = signingUrlHeaders[key];
                xhr.setRequestHeader(key, val);
            });
        }
        if (xhr.overrideMimeType)
            xhr.overrideMimeType("text/plain; charset=x-user-defined");
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 &&
                this.successResponses.indexOf(xhr.status) >= 0) {
                let result;
                try {
                    result = JSON.parse(xhr.responseText);
                    this.onSignedUrl(result);
                }
                catch (error) {
                    this.onError("Invalid response from server", file);
                    return false;
                }
                return callback(result);
            }
            if (xhr.readyState === 4 &&
                this.successResponses.indexOf(xhr.status) < 0) {
                return this.onError(`Could not contact request signing server. Status = ${xhr.status}`, file);
            }
        }.bind(this);
        return xhr.send();
    }
    uploadToS3(file, mimeType, signResult) {
        const { signedUrl } = signResult;
        const xhr = this.createCORSRequest("PUT", signedUrl, null);
        if (!xhr) {
            this.onError("CORS not supported", file);
        }
        else {
            xhr.onload = function () {
                if (this.successResponses.indexOf(xhr.status) >= 0) {
                    this.onProgress(100, "Upload completed", file);
                    return this.onFinishS3Put(signResult === null || signResult === void 0 ? void 0 : signResult.fileId, file);
                }
                return this.onError(`Upload error: ${xhr.status}`, file);
            }.bind(this);
            xhr.onerror = function () {
                return this.onError("XHR error", file);
            }.bind(this);
            xhr.upload.onprogress = function (e) {
                let percentLoaded;
                if (e.lengthComputable) {
                    percentLoaded = Math.round((e.loaded / e.total) * 100);
                    return this.onProgress(percentLoaded, percentLoaded === 100 ? "Finalizing" : "Uploading", file);
                }
            }.bind(this);
        }
        xhr.setRequestHeader("Content-Type", mimeType);
        if (this.contentDisposition) {
            let disposition = this.contentDisposition;
            if (disposition === "auto") {
                disposition = "inline";
            }
            const fileName = scrubFilename(file.name);
            xhr.setRequestHeader("Content-Disposition", `${disposition}; filename="${fileName}"`);
        }
        if (signResult.headers) {
            const signResultHeaders = signResult.headers;
            Object.keys(signResultHeaders).forEach((key) => {
                const val = signResultHeaders[key];
                xhr.setRequestHeader(key, val);
            });
        }
        if (this.uploadRequestHeaders) {
            const { uploadRequestHeaders } = this;
            Object.keys(uploadRequestHeaders).forEach((key) => {
                const val = uploadRequestHeaders[key];
                xhr.setRequestHeader(key, val);
            });
        }
        else {
            xhr.setRequestHeader("x-amz-acl", "public-read");
        }
        this.httprequest = xhr;
        return xhr.send(file);
    }
    uploadFile(file, mimeType) {
        const uploadToS3Callback = this.uploadToS3.bind(this, file, mimeType);
        if (this.getSignedUrl)
            return this.getSignedUrl(file, uploadToS3Callback);
        return this.executeOnSignedUrl(file, mimeType, uploadToS3Callback);
    }
}
export default S3Uploader;
