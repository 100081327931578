import { RENDITIONS } from "../interfaces";
import { typeVideo } from "@warda/library-ui/utils/mimeTypes";
const sizeImg = {
    0: RENDITIONS.image_xs,
    1: RENDITIONS.image_s,
    2: RENDITIONS.image_l,
    3: RENDITIONS.image_xxl,
};
const sizeVid = {
    0: RENDITIONS.video_LQ,
    1: RENDITIONS.video_LQ,
    2: RENDITIONS.video_LQ,
    3: RENDITIONS.video_HQ,
};
const getThumbRendition = (size, mimeType) => {
    const isVideo = new Set(typeVideo).has(mimeType);
    return isVideo ? sizeVid[size] : sizeImg[size];
};
export default getThumbRendition;
