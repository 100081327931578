import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import { getFileTypeText } from "../utils";
const ViewBlockHeader = ({ type, filesTotal, indexRow, indexCol, }) => {
    if (indexRow > 0)
        return null;
    if (indexCol > 0)
        return null;
    return (React.createElement(Text, { ellipsis: true, weight: "bolder", style: { marginLeft: 13, flex: 1 }, children: String(filesTotal[type]) + " " + getFileTypeText(type) }));
};
export default ViewBlockHeader;
