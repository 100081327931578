import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Toolbar from "@warda/library-ui/core/Toolbar";
import { DRAWER_WIDTH } from "../../constants";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
import Skeleton from "@warda/library-ui/core/Skeleton";
const WIDTH = DRAWER_WIDTH + 1;
const SklBig = () => React.createElement(Skeleton, { height: 25, width: 200, animation: "wave" });
const SklSmall = () => (React.createElement(Skeleton, { height: 25, width: 25, style: { margin: 5 }, animation: "wave" }));
const useStyles = createUseStyles({
    mainToolbar: {
        backgroundColor: getTheme().colors.background,
        padding: 0,
        alignItems: "stretch",
    },
    panel: {
        height: "-webkit-fill-available",
        width: WIDTH,
        minWidth: WIDTH,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        backgroundColor: getTheme().colors.grayDrawer,
    },
    mainActions: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        padding: "0 15px",
        borderBottom: `1px solid ${getTheme().colors.grayBorder}`,
    },
});
const PlaceholderMainToolbar = () => {
    const classes = useStyles({});
    return (React.createElement(Toolbar, { className: classes.mainToolbar },
        React.createElement("div", { className: classes.panel },
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(SklBig, null),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(BorderShadow, { position: "right" })),
        React.createElement("div", { className: classes.mainActions },
            React.createElement(SklBig, null),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(SklSmall, null),
            React.createElement(SklSmall, null),
            React.createElement(SklSmall, null),
            React.createElement(SklSmall, null),
            React.createElement(SklSmall, null))));
};
export default PlaceholderMainToolbar;
