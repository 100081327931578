import PERMS from "./permissions";
import { PointSelector } from "@warda/library-ui/core/ImageAnnotation";
import { AreaType, AttributeFamily, GridMedia, } from "./interfaces";
import { PREF_KEY, ZOOM_COLORS_KEY } from "./constantsKey";
import { SegmentedViewType } from "@warda/library-ui/core/SegmentedView";
import { ThemeId } from "./colors";
export const getEnv = () => {
    switch (process.env.BASE_URL_GATEWAY) {
        case "https://api.wardacloud.com":
            return "PROD";
        case "https://api-test.wardacloud.com":
            return "TEST";
        default:
            return "DEV";
    }
};
export const BASE_URL = `${process.env.BASE_URL_GATEWAY || ""}`;
export const categoryDefault = "DEFAULT";
export const ROOT_DIV_ID = "app";
export const PANEL_DETAIL_WIDTH_CLOSE = 22;
export const PANEL_DETAIL_WIDTH = 420;
export const FIELD_WIDTH = 360;
export const DRAWER_WIDTH = 280;
export const SHAREPAGE_DA = "share"; // key shared with BE - not change
export const AREA_PRODUCTS = "products"; // key used by user's JsonStore file
export const AREA_PRODUCTS_ATTRIBUTES = "products_attributes";
export const AREA_MEDIA_LIBRARY = "media_library_2";
export const AREA_MEDIA_METADATA = "media_metedata";
export const AREA_CATALOGS = "catalogs";
export const AREA_CATEGORIES = "categories";
export const AREA_USER_MNG_SC = "usermanagement_sc";
export const AREA_USER_MNG_PORTAL = "usermanagement_portal";
export const AREA_M2MS = "m2ms";
export const AREA_FILES = "medialibrary";
export const AREA_CONFIG = "configurations";
export const AREA_COMPLETENESS = "completeness";
export const AREA_USAGESTATS = "usage_stats";
export const AREA_SHAREPAGE = "sharepage";
export const AREA_STAGING = "stagingarea"; // TODO da eliminare quando sarà inclusa nella ML2
export const LAYOUT_GRID = "grid"; // used in jsonStore id - not change
export const ID_COLUMNS = "columns"; // used in jsonStore id - not change
export const ID_FILTERS = "filters"; // used in jsonStore id - not change
export const ID_ATTRIBS = "fields_panel_details"; // used in jsonStore id - not change
export const TYPE_FOLDER = "application/vnd.warda-apps.folder";
export const MAX_COLUMN_SORT = 5;
export const FOLDER_ROOT_ID = "root";
export const FOLDER_ROOT_LABEL = "Root";
export const DEFAULT_WIDTH_COLUMN = 120;
export const MAX_PRODUCTS_SELECTABLE = 10000; // for ElasticSearch and Export XLSX
export const MAX_PRODUCTS_MASSIVE_ACTIONS = 500;
export const MAX_PAGINATION = 500;
export const ERROR_INTERNALSERVER = "Internal server error";
export const ERROR_TOO_MANY_ITEMS = "Please try select a smaller number of items";
export const ERROR_GENERIC = "Something went wrong, please retry";
export const ERROR_NOT_FOUND = "Unable get file data, please refresh and retry";
export const ERROR_SAVE_SETS = "Unable to save your Sets, please refresh and retry";
export const ERROR_SAVE_PREF = "Unable to save your preferences, please refresh and retry";
export const MAX_UPLOAD = 3;
export const ZOOM_COLORS = {
    [ZOOM_COLORS_KEY.BLACK]: {
        label: "Total Black",
        blackIcons: true,
        background: "#000000",
    },
    [ZOOM_COLORS_KEY.GRAY1]: {
        label: "Dark Gray",
        blackIcons: true,
        background: "#303030",
    },
    [ZOOM_COLORS_KEY.GRAY2]: {
        label: "Light Gray",
        blackIcons: false,
        background: "#d8d8d8",
    },
    [ZOOM_COLORS_KEY.WHITE]: {
        label: "Total White",
        blackIcons: false,
        background: "#ffffff",
    },
};
export const SECTIONS = [
    {
        perm: [PERMS.access_product_area],
        view: AREA_PRODUCTS,
        type: AreaType.AREA,
        icon: "article",
        text: "Products",
    },
    {
        perm: [PERMS.access_completeness],
        view: AREA_COMPLETENESS,
        type: AreaType.AREA,
        icon: "view_timeline",
        text: "Completeness",
    },
    {
        perm: [PERMS.access_consumption],
        view: AREA_USAGESTATS,
        type: AreaType.AREA,
        icon: "insert_chart",
        text: "Usage Stats",
    },
    {
        perm: [PERMS.access_staging_area],
        view: AREA_STAGING,
        type: AreaType.AREA,
        icon: "dataset",
        text: "Staging Area",
    },
    {
        perm: [PERMS.access_digital_asset_area],
        view: AREA_FILES,
        type: AreaType.AREA,
        icon: "folder",
        text: "Media Library",
    },
    {
        perm: [PERMS.access_medialibrary_area],
        view: AREA_MEDIA_LIBRARY,
        type: AreaType.AREA,
        icon: "folder",
        text: "Media Library v2.0 🚀",
    },
    {
        perm: [
            PERMS.access_users_config,
            PERMS.access_roles_area,
            PERMS.access_permissions,
        ],
        view: AREA_USER_MNG_SC,
        type: AreaType.CONF,
        icon: "people_alt",
        text: "SeeCommerce User Management",
    },
    {
        perm: [
            PERMS.access_portal_users,
            PERMS.access_portal_roles,
            PERMS.access_portal_permissions,
            PERMS.access_portal_pending_requests,
        ],
        view: AREA_USER_MNG_PORTAL,
        type: AreaType.CONF,
        icon: "supervised_user_circle",
        text: "Portal User Management",
    },
    {
        perm: [PERMS.access_tenant_config],
        view: AREA_CONFIG,
        type: AreaType.CONF,
        icon: "settings",
        text: "Self Configurability",
    },
    {
        perm: [PERMS.access_m2m],
        view: AREA_M2MS,
        type: AreaType.CONF,
        icon: "keyboard_command",
        text: "Touchpoints",
    },
    {
        perm: [PERMS.access_catalogs_config],
        view: AREA_CATALOGS,
        type: AreaType.CONF,
        icon: "auto_stories",
        text: "Catalogs",
    },
    {
        perm: [PERMS.access_categories_config],
        view: AREA_CATEGORIES,
        type: AreaType.CONF,
        icon: "category",
        text: "Categories",
    },
    {
        perm: [PERMS.access_attributes_config],
        view: AREA_PRODUCTS_ATTRIBUTES,
        type: AreaType.CONF,
        icon: "my_library_books",
        text: "Products Attributes",
    },
    {
        perm: [PERMS.access_media_attrs_config],
        view: AREA_MEDIA_METADATA,
        type: AreaType.CONF,
        icon: "photo_library",
        text: "Media Metadata",
    },
];
export const CALENDAR_COLORS = [
    "#d50000",
    "#f4511e",
    "#33b679",
    "#039be5",
    "#7986cb",
    "#e67c73",
    "#f6bf26",
    "#0b8043",
    "#3f51b5",
    "#8e24aa", // vinaccia
];
export const USER_PREF_DEFAULT = {
    [PREF_KEY.filterType]: "",
    [PREF_KEY.filterCtlg]: "",
    [PREF_KEY.filterLang]: "",
    [PREF_KEY.detailAnnotationsShow]: false,
    [PREF_KEY.detailAnnotationsType]: PointSelector.TYPE,
    [PREF_KEY.detailBackgroundColor]: ZOOM_COLORS_KEY.BLACK,
    [PREF_KEY.detailEditingAttrCtlgs]: [],
    [PREF_KEY.detailEditingAttrLangs]: [],
    [PREF_KEY.categoryDrawerExpanded]: false,
    [PREF_KEY.categoryFilterSelected]: "",
    [PREF_KEY.gridPreviewMedias]: GridMedia.LAST_UPLOAD,
    [PREF_KEY.productsFiltersSetLevels]: [],
    [PREF_KEY.productsColumnsSetLevels]: [],
    [PREF_KEY.productsLayout]: SegmentedViewType.GRID,
    [PREF_KEY.medialibLayout]: SegmentedViewType.LIST,
    [PREF_KEY.jsonstoreContext]: {},
    [PREF_KEY.themeId]: ThemeId.SOFT_PLUM,
};
export const MAX_CATEGORIES = 2000;
export const MANAGER_GROUPS = Object.values(AttributeFamily).map((id) => ({ id, label: id }));
