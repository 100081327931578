import * as React from "react";
import { createUseStyles } from "react-jss";
import Card from "@warda/library-ui/core/Card";
import Text from "@warda/library-ui/core/Text";
import getSeverityColor from "@warda/library-ui/utils/getSeverityColor";
import mixColors from "@warda/library-ui/utils/mixColors";
import getSeverityIcon from "@warda/library-ui/utils/getSeverityIcon";
import Icon from "@warda/library-ui/core/Icon";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    snackbar: {
        overflow: "hidden",
        paddingRight: 30,
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "6px 16px",
        backgroundColor: ({ severity }) => {
            return mixColors(0.9, getSeverityColor(severity), getTheme().colors.background);
        },
    },
});
const SnackbarMessage = React.forwardRef(({ variant: severity, message }, ref) => {
    const classes = useStyles({ severity });
    return (React.createElement(Card, { ref: ref, elevation: 1, className: classes.snackbar },
        React.createElement(Icon, { style: {
                marginRight: 12,
                padding: "7px 0",
                color: getSeverityColor(severity),
            }, children: getSeverityIcon(severity) }),
        React.createElement(Text, { weight: "bolder", ellipsis: true, children: message }),
        React.createElement("div", { style: { flex: 1 } })));
});
export default SnackbarMessage;
