import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Btn from "@warda/library-ui/core/Btn";
import Modal from "@warda/library-ui/core/Modal";
import Text from "@warda/library-ui/core/Text";
const MsgStopUpload = ({ i18n, onStop, onContinue, open }) => {
    return (React.createElement(Modal, { open: open, onClose: onContinue, title: "⚠️ " + i18n.msgStopUploadsTitle, content: React.createElement(Text, { children: i18n.msgStopUploadsContent }), actions: React.createElement(React.Fragment, null,
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Btn, { variant: "bold", label: i18n.msgStopUploadsBtnContinue.toLocaleUpperCase(), onClick: onContinue }),
            React.createElement(Btn, { variant: "bold", label: i18n.msgStopUploadsBtnInterrupt.toLocaleUpperCase(), onClick: onStop, color: getTheme().colors.msgFail })) }));
};
export default MsgStopUpload;
