import { missingKey } from "@warda/library-ui/interfaces";
export const KEY_NO_SELECT = "KEY_NO_SELECT";
export const KEY_CATALOGED = "KEY_CATALOGED";
export const getOptionsCtlg = (ctlgs) => {
    const opts = [
        {
            id: KEY_NO_SELECT,
            label: "All products",
        },
    ];
    if (ctlgs.length === 1) {
        opts.push({
            id: ctlgs[0].id,
            label: "Products in catalog",
        });
        opts.push({
            id: missingKey,
            label: "Products without catalog",
        });
    }
    if (ctlgs.length > 1) {
        opts.push({
            id: KEY_CATALOGED,
            label: "Products in ANY catalog",
        });
        opts.push({
            id: missingKey,
            label: "Products in NO catalog",
        });
        opts.push({
            id: "catalogs_title",
            label: "Products in a specific catalog",
            divider: true,
            disabled: true,
            hideIcon: true,
        });
        ctlgs.forEach((c) => {
            opts.push({
                id: c.id,
                label: c.displayName,
            });
        });
    }
    return opts;
};
export const getOptionsLang = (ctlg) => {
    if (!ctlg)
        return [];
    return ctlg.languages.map((l) => ({
        id: l,
        label: l.toLocaleUpperCase(),
    }));
};
const getValue = (ids) => ids.map((id) => ({ id }));
export const getFilterCtlgValue = (catalogs, slcCtlg, slcLang) => {
    if (slcCtlg === KEY_CATALOGED) {
        // products almost one catalog
        return {
            slcCtlg,
            slcLang: "",
            value: getValue(catalogs.map((c) => c.id)),
        };
    }
    if (slcCtlg === missingKey) {
        // products without catalogs
        return {
            slcCtlg,
            slcLang: "",
            value: getValue([missingKey]),
        };
    }
    const ctlg = catalogs.find((c) => c.id === slcCtlg);
    if (!!ctlg) {
        // products with this catalog
        // manage that the catalog no longer exists
        return {
            slcCtlg,
            slcLang: slcLang || ctlg.fallback,
            value: getValue([slcCtlg]),
        };
    }
    // no selection
    return {
        slcCtlg: KEY_NO_SELECT,
        slcLang: "",
        value: [],
    };
};
