import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Badge from "@warda/library-ui/core/Badge";
import { CdnPublishedStatus } from "../../../interfaces";
import { CtxDispatchViewport } from "../contexts";
import { ACT_VPORT } from "../reducer";
const BadgeShareUrls = ({ data, className, style }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const { id, publicshares, cdnPublishedStatus } = data;
    const onClick = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.MODAL_SHARE_LINK, ids: [id] });
    }, [dispatchViewport, id]);
    if (!(publicshares === null || publicshares === void 0 ? void 0 : publicshares.find((p) => p.root)) &&
        cdnPublishedStatus !== CdnPublishedStatus.PUBLISHED)
        return null;
    return (React.createElement(Badge, { color: getTheme().colors.msgInfo, className: className, style: style, tooltip: "Get link", icon: "link", iconStyle: { transform: "rotate(-45deg)" }, onClick: onClick }));
};
export default BadgeShareUrls;
