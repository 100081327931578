import { getValuedAttributeKeysDictionary } from "./";
import { getAttributeId } from "../getAttributeKey";
import { ATT_NO_LANG_ID } from "../PanelDetail/constants";
export const decorateAttributesDictionary = (item, columns, datasDictionary) => {
    const obj = {};
    const attributeKeys = getValuedAttributeKeysDictionary(item, columns);
    attributeKeys.forEach((attrKey) => {
        const attrValue = item[attrKey];
        const { attrId, ctlgId, langId } = getAttributeId(attrKey);
        const dictsData = datasDictionary.find((d) => d.attributeId === attrId);
        const dictsValue = typeof attrValue === "string" ? [attrValue] : attrValue;
        if (!!dictsData) {
            obj[attrKey] = dictsValue.map((code) => {
                const dict = dictsData.dictionaries.find((d) => {
                    if (d.code !== code)
                        return false;
                    return !!d.catalog ? d.catalog === ctlgId : true;
                });
                if (!dict) {
                    console.warn("Dictionary not found", {
                        attributeId: attrId,
                        attributeKey: attrKey,
                        dictsData,
                        attributeValue: code,
                    });
                }
                return {
                    code,
                    value: !!dict ? dict.value[langId || ATT_NO_LANG_ID] : "⚠️",
                };
            });
        }
    });
    return obj;
};
