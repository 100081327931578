export const getLangId = (catalogs, slcCtlg, slcLang) => {
    const ctlg = catalogs.find((c) => c.id === slcCtlg);
    if (!ctlg)
        return "";
    const lang = ctlg.languages.some((l) => l === slcLang);
    if (!lang)
        return "";
    return slcLang;
};
const getCtlgId = (catalogs, slcCtlg) => {
    if (!catalogs || !catalogs.length || !slcCtlg)
        return "";
    const ctlg = catalogs.find((c) => c.id === slcCtlg);
    if (!ctlg)
        return "";
    return ctlg.id;
};
export default getCtlgId;
