import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { THEMES } from "../../colors";
import { createUseStyles } from "react-jss";
import ThemePreviewField from "./ThemePreviewField";
import ThemePreviewSample from "./ThemePreviewSample";
import mixColors from "@warda/library-ui/utils/mixColors";
const PREVIEW_SIZE = 150;
const useStyles = createUseStyles({
    content: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
    },
    preview: {
        minWidth: PREVIEW_SIZE,
        maxWidth: PREVIEW_SIZE,
        width: PREVIEW_SIZE,
        minHeight: PREVIEW_SIZE,
        maxHeight: PREVIEW_SIZE,
        height: PREVIEW_SIZE,
        overflow: "hidden",
        border: `1px solid ${getTheme().colors.grayBorder}`,
        borderRadius: getTheme().borderRadius,
    },
});
const ThemePreview = ({ themeId }) => {
    const classes = useStyles({});
    const theme = THEMES.find((t) => t.id === themeId);
    const c = theme.colors;
    return (React.createElement("div", { className: classes.content },
        React.createElement("div", { className: classes.preview, style: { backgroundColor: mixColors(0.15, c.background, c.typography) } },
            React.createElement(ThemePreviewSample, { theme: theme })),
        React.createElement("div", null,
            React.createElement(ThemePreviewField, { label: "Primary Color", color: c.theme1 }),
            React.createElement(ThemePreviewField, { label: "Secondary Color", color: c.theme2 }),
            React.createElement(ThemePreviewField, { label: "Typography", color: c.typography }),
            React.createElement(ThemePreviewField, { label: "Border", color: c.grayBorder }),
            React.createElement(ThemePreviewField, { label: "Background", color: c.background }),
            React.createElement(ThemePreviewField, { label: "Drawer", color: c.grayDrawer }))));
};
export default ThemePreview;
