import * as React from "react";
import BtnBlack from "../../../AreaProducts/PanelDetail/PanelZoom/BtnBlack";
import { directDownloadMediaPublished_DEPRECATED } from "../../../../utils/directDownloadMedia";
import apiUrls from "../../../../api/apiUrlsAnonymous";
import { CtxDispatchViewport } from "../../contexts";
import { TYPE_FOLDER } from "../../../../constants";
import { ACT_VPORT } from "../../reducer";
const BtnDownload = ({ assetData }) => {
    const dispatch = React.useContext(CtxDispatchViewport);
    const searchString = location.search;
    const query = new URLSearchParams(searchString);
    const link = query.get("link");
    const { id, name, documentRepoId, mimeType } = assetData;
    const onClick = React.useCallback(() => {
        if (mimeType === TYPE_FOLDER) {
            dispatch({ type: ACT_VPORT.ACTION_DOWNLOAD_ARCHIV, ids: [id] });
        }
        else {
            const srcUrl = apiUrls.getRenditionPublic.url(link, documentRepoId);
            directDownloadMediaPublished_DEPRECATED(srcUrl, name);
        }
    }, [dispatch, documentRepoId, id, link, mimeType, name]);
    return (React.createElement(BtnBlack, { isDark: true, icon: "file_download", tooltip: "Download", onClick: onClick }));
};
export default BtnDownload;
