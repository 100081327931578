import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route } from "react-router-dom";
import { useEffect } from "react";
import { ROOT_DIV_ID, SHAREPAGE_DA, AREA_SHAREPAGE } from "./constants";
import { getTheme, setTheme } from "@warda/library-ui/theme";
import WebFont from "webfontloader";
import BackgroundWithIcons from "./components/BackgroundWithIcons";
import Cookies from "js-cookie";
import Login from "@warda/library-ui/auth/Login";
import Text from "@warda/library-ui/core/Text";
import Main from "./components/Main";
import AreaFilesShared from "./components/AreaFilesShared_DEPRECATED";
import { REFRESH_TOKEN } from "./constantsLocalStorage";
import AreaSharepage from "./components/AreaSharepage";
import { onLogin, onChoosePassword, onDemandPassword, } from "./api/fetchCookieJwt";
import { getPasswordPolicy } from "./api/fetchesApiAnonymous";
import { THEMES } from "./colors";
const title = "SeeCommerce";
const AppRender = () => (React.createElement(Route, { path: "/:viewId?", 
    // eslint-disable-next-line react/jsx-no-bind
    render: (routeConf) => {
        const { viewId } = routeConf.match.params;
        if (viewId === AREA_SHAREPAGE) {
            return React.createElement(AreaSharepage, null);
        }
        if (viewId === SHAREPAGE_DA) {
            return React.createElement(AreaFilesShared, null);
        }
        if (!Cookies.get(REFRESH_TOKEN)) {
            const themeDefault = THEMES[0];
            if (getTheme().id !== themeDefault.id) {
                setTheme(themeDefault);
                location.reload();
                return null;
            }
            return (React.createElement(Login, { header: React.createElement(Text, { weight: "lighter", children: title, style: {
                        color: getTheme().colors.theme1,
                        fontSize: 40,
                        marginBottom: 60,
                    } }), background: React.createElement(BackgroundWithIcons, null), onLogin: onLogin, onDemandPassword: onDemandPassword, onChoosePassword: onChoosePassword, getPasswordPolicy: getPasswordPolicy, hiddenRegistration: true, position: "modal" }));
        }
        return React.createElement(Main, { title: title, viewId: viewId });
    } }));
const AppInitTheme = () => {
    localStorage.setItem("lang", "en");
    useEffect(() => {
        WebFont.load(getTheme().fonts);
    }, []);
    return (React.createElement(BrowserRouter, null,
        React.createElement(AppRender, null)));
};
const container = document.getElementById(ROOT_DIV_ID);
const root = createRoot(container);
root.render(React.createElement(AppInitTheme, null));
