import * as React from "react";
import CardTitle from "../CardTitle";
import { getThemeTuple, THEME_COLORS_PALETTE, THEME_LABEL_MODE, THEME_LABEL_PALETTE, } from "../../../../colors";
const CardTheme = ({ cardClasses, dispatch, data }) => {
    const { payload } = data;
    const { themeId } = payload;
    const [mode, palette] = getThemeTuple(themeId);
    const textP = THEME_LABEL_PALETTE[palette] || "Unknown";
    const textM = THEME_LABEL_MODE[mode] || "Unknown";
    const [color1] = THEME_COLORS_PALETTE[palette] || "#ff0000";
    return (React.createElement(CardTitle, { classes: cardClasses, dispatch: dispatch, avatarIcon: "palette", avatarColor: color1, title: `Set theme ${textM} "${textP}"`, data: data }));
};
export default CardTheme;
