var UploadItemStatus;
(function (UploadItemStatus) {
    UploadItemStatus["Waiting"] = "waiting";
    UploadItemStatus["ReadyToUpload"] = "readyToUpload";
    UploadItemStatus["S3Uploading"] = "s3Uploading";
    UploadItemStatus["AwaitingConfirmation"] = "awaitingConfirmation";
    UploadItemStatus["Completed"] = "completed";
    UploadItemStatus["Error"] = "error";
    UploadItemStatus["Abort"] = "abort";
})(UploadItemStatus || (UploadItemStatus = {}));
export default UploadItemStatus;
