import * as React from "react";
import Card from "@warda/library-ui/core/Card";
import Text from "@warda/library-ui/core/Text";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
const useStyles = createUseStyles({
    card: {
        position: "relative",
        padding: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        width: 410,
        textAlign: "center",
        marginBottom: 10,
    },
    title: {
        textAlign: "left",
        margin: "2px 5px",
    },
});
const ThemeListTitle = ({ className, style, title, elevation, children, }) => {
    const classes = useStyles({});
    return (React.createElement(Card, { elevation: elevation, style: style, className: classnames({
            [classes.card]: true,
            [className]: !!className,
        }) },
        React.createElement(Text, { className: classes.title, weight: "lighter", size: 0, children: title }),
        React.createElement("div", null, children)));
};
export default ThemeListTitle;
