const isAssetDateExpired = (data) => {
    const { copyright } = data;
    const expirationDate = (copyright || {}).expirationDate;
    const currentDate = new Date().getTime();
    if (!expirationDate)
        return false;
    if (currentDate < new Date(expirationDate).getTime())
        return false;
    return true;
};
export default isAssetDateExpired;
