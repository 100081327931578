import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import MultiDownloads from "@warda/library-ui/core/MultiDownloads";
import { ACT_VPORT } from "./reducer";
import Btn from "@warda/library-ui/core/Btn";
import Modal from "@warda/library-ui/core/Modal";
import { CtxDispatchViewport } from "./contexts";
const useStyles = createUseStyles({
    multiDownload: {
        left: 25,
        bottom: 75,
        right: "auto",
    },
});
const WindowDownloads = ({ downloads }) => {
    const classes = useStyles({});
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const [confirmStop, setConfirmStop] = React.useState(false);
    const onConfirmStop = React.useCallback(() => setConfirmStop(false), []);
    const onClose = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DOWNLOADS_RESET });
        setConfirmStop(false);
    }, [dispatchViewport]);
    const onCloseCheck = React.useCallback(() => {
        if (!!downloads.length && downloads.find((x) => x.loading === true)) {
            setConfirmStop(true);
        }
        else {
            onClose();
        }
    }, [downloads, onClose]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MultiDownloads, { className: classes.multiDownload, items: downloads, open: !!downloads.length, onClose: onCloseCheck }),
        React.createElement(Modal, { open: confirmStop, onClose: onCloseCheck, title: "Cancel Download", content: React.createElement(Text, { children: "Are you sure you want to cancel download?" }), actions: React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { variant: "bold", label: "CONTINUE", onClick: onConfirmStop }),
                React.createElement(Btn, { variant: "bold", color: getTheme().colors.msgFail, label: "STOP DOWNLOAD", onClick: onClose })) })));
};
export default WindowDownloads;
