import * as React from "react";
import { createUseStyles } from "react-jss";
import { ACT_VPORT } from "./reducer";
import Btn from "@warda/library-ui/core/Btn";
import { useLocation } from "react-router-dom";
import Breadcrumb from "@warda/library-ui/core/Breadcrumb";
import { getItemActions, MAP_TYPE } from "./actions";
import BtnAction from "./BtnAction";
import { SheetLayout } from "../../interfaces";
import { CtxDispatchViewport } from "./contexts";
import LogoCustom from "./LogoCustom";
import { getTheme } from "@warda/library-ui/theme";
const HEIGHT = 60;
const useStyles = createUseStyles({
    toolbar: {
        padding: "0 25px",
        position: "relative",
        display: "flex",
        "flex-direction": "row",
        "align-items": "center",
        "min-height": HEIGHT,
        "max-height": HEIGHT,
        height: HEIGHT,
    },
    separator: {
        background: getTheme().colors.disable,
        margin: "0 10px 0 25px",
        width: 1,
        height: 25,
    },
});
const ToolbarHeader = ({ items, itemsIdSelected, path, detailSheet, loading, }) => {
    const searchString = useLocation().search;
    const query = new URLSearchParams(searchString);
    const tenantId = query.get("tenantId");
    const dispatch = React.useContext(CtxDispatchViewport);
    const classes = useStyles({});
    const detailOpen = detailSheet === SheetLayout.OPEN;
    const onBreadcrumbClick = React.useCallback((id) => {
        dispatch({ type: ACT_VPORT.PATH_SELECT, id });
    }, [dispatch]);
    const onActionClick = React.useCallback((event, id) => {
        const type = MAP_TYPE[id];
        if (type)
            dispatch({ type });
    }, [dispatch]);
    const onRefresh = React.useCallback(() => {
        dispatch({ type: ACT_VPORT.INITIALIZE_START });
    }, [dispatch]);
    return (React.createElement("div", { className: classes.toolbar },
        React.createElement(LogoCustom, { tenantId: tenantId }),
        path.length < 2 ? null : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.separator }),
            React.createElement(Breadcrumb, { items: path, onClick: onBreadcrumbClick }))),
        React.createElement("div", { style: { flex: 1 } }),
        getItemActions({
            tenantId,
            contextmenu: false,
            detailOpen,
            items,
            itemsIdSelected,
        }).map((a) => (React.createElement(BtnAction, { key: a.id, ...a, onClick: onActionClick }))),
        React.createElement(Btn, { icon: "refresh", tooltip: "Refresh", disabled: loading, onClick: onRefresh })));
};
export default ToolbarHeader;
