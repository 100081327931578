import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Btn from "@warda/library-ui/core/Btn";
import WindowNotifications from "./Window";
import { reducer, reducerInitState, ACTION } from "./reducer";
import { getAllNotifDrc, getCountNotifDrc, markAsReadNotifDrc, markAsViewNotifDrc, deleteNotifDrc, getOneNotifDrc, } from "../../api/fetchesApiNotifications";
import { registerWebSocketCallback, unregisterWebSocketCallback, } from "../webSocket";
import websocketCallbacks from "./websocketCallbacks";
const LABEL = "Direct Notifications";
const BtnWindow = () => {
    const btnRef = React.useRef(null);
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, windowOpen, drcItems, drcNewIdsToGet, drcBadgeUnview, drcDeletingAll, drcMarkReadAll, } = state;
    const onOpen = React.useCallback(() => {
        dispatch({ type: ACTION.WINDOW_OPEN, open: true });
    }, []);
    // get unviewedLoad
    React.useEffect(() => {
        (async () => {
            if (!initialize) {
                const unview = await getCountNotifDrc();
                const items = await getAllNotifDrc({});
                dispatch({ type: ACTION.INITIALIZE, unview, items });
            }
        })();
    }, [initialize]);
    // delete all DRC
    React.useEffect(() => {
        (async () => {
            if (drcDeletingAll) {
                await deleteNotifDrc();
                dispatch({ type: ACTION.DRC_DELETE_ALL });
            }
        })();
    }, [drcDeletingAll]);
    // mark as read all DRC
    React.useEffect(() => {
        (async () => {
            if (drcMarkReadAll) {
                await markAsReadNotifDrc();
                dispatch({ type: ACTION.DRC_MARKREAD_ALL });
            }
        })();
    }, [drcMarkReadAll]);
    // mark as view all DRC at opening panel
    React.useEffect(() => {
        (async () => {
            if (windowOpen && drcBadgeUnview !== 0) {
                await markAsViewNotifDrc();
                dispatch({ type: ACTION.DRC_BADGE_UNVIEW, value: 0 });
            }
        })();
    }, [drcBadgeUnview, windowOpen]);
    // add websocket listener
    React.useEffect(() => {
        websocketCallbacks.forEach((x) => {
            registerWebSocketCallback({
                id: x.id,
                callback: x.callback,
                dispatch,
            });
        });
        return () => {
            websocketCallbacks.forEach((x) => {
                unregisterWebSocketCallback(x.id);
            });
        };
    }, []);
    // after WS notifications, show new cards DRC
    React.useEffect(() => {
        (async () => {
            if (!!drcNewIdsToGet.length) {
                const newItem = await getOneNotifDrc(drcNewIdsToGet[0]);
                dispatch({ type: ACTION.DRC_NEWITEMS_LOADED, newItem });
            }
        })();
    }, [drcNewIdsToGet]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { cmpRef: btnRef, disabled: !initialize, icon: "notifications", tooltip: LABEL, onClick: onOpen, selected: windowOpen, badge: !!drcBadgeUnview, badgeColor: getTheme().colors.msgFail }),
        React.createElement(WindowNotifications, { dispatch: dispatch, open: windowOpen, anchorEl: btnRef.current, title: LABEL, items: drcItems })));
};
export default BtnWindow;
