import * as React from "react";
import { createUseStyles } from "react-jss";
import { getTheme } from "@warda/library-ui/theme";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport, CtxSetSnackbar } from "../contexts";
import Btn from "@warda/library-ui/core/Btn";
import { Severity } from "@warda/library-ui/interfaces";
const color = getTheme().colors.theme2;
const useStyles = createUseStyles({
    "@keyframes rotate": {
        "100%": {
            transform: "rotate(1turn)",
        },
    },
    button: {
        borderRadius: getTheme().borderRadius,
        backgroundColor: getTheme().colors.background,
        position: "relative",
        zIndex: 0,
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        "& *": {
            color,
        },
        "&::before": {
            boxSizing: "border-box",
            content: '""',
            position: "absolute",
            zIndex: -2,
            width: 80,
            height: 80,
            background: "linear-gradient(180deg, rgba(0,43,255,1) 0%, rgba(255,255,255,1) 100%)",
            animation: "$rotate 1s linear infinite",
        },
        "&::after": {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            boxSizing: "border-box",
            content: '""',
            position: "absolute",
            zIndex: -1,
            width: "calc(100% - 4px)",
            height: "calc(100% - 4px)",
            background: getTheme().colors.grayDrawer,
            borderRadius: getTheme().borderRadius - 2,
        },
    },
    listitem: {
        borderRadius: getTheme().borderRadius,
        backgroundColor: getTheme().colors.background,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
});
const BtnDownloads = ({ downloads }) => {
    const classes = useStyles({});
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const setSnackbar = React.useContext(CtxSetSnackbar);
    const color = getTheme().colors.theme2;
    const onClick = React.useCallback(() => {
        setSnackbar(Severity.INFO, "Preparing zip download");
    }, [setSnackbar]);
    /*
    const [dialog, setDialog] = React.useState(false);
    const cbOnDialogShow = React.useCallback(() => {
      setDialog(true);
    }, []);
    const cbOnDialogHide = React.useCallback(() => {
      setDialog(false);
    }, []);
  
    const actions: IPopoverListItem[] = downloads.map((d) => {
      const data: IPopoverListItem = {
        id: d.id,
        label: d.name,
        children: !d.loading ? undefined : (
          <CircularProgress color={color} style={{ padding: "0 8px" }} size={16} />
        ),
      };
      return data;
    });
    */
    React.useEffect(() => {
        const file = downloads.find((d) => d.loading === false);
        if (!!file) {
            dispatchViewport({ type: ACT_VPORT.DOWNLOADS_REMOVE, file });
        }
    }, [dispatchViewport, downloads]);
    if (downloads.length === 0)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { className: classes.button, color: color, labelPosition: "left", label: downloads.length === 1 ? undefined : String(downloads.length), icon: "download", onClick: onClick })));
};
export default BtnDownloads;
