import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { SheetLayout, SheetStatus } from "../../interfaces";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { PANEL_DETAIL_WIDTH, PANEL_DETAIL_WIDTH_CLOSE } from "../../constants";
import Drawer from "./Drawer";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
const noSwitch = new Set([SheetLayout.MEDIA_ZOOM, SheetLayout.MEDIA_ATTR]);
const DetailChildren = ({ children, status }) => {
    if (status === SheetStatus.LOADING) {
        return React.createElement(Placeholder, { spinner: true });
    }
    if (status === SheetStatus.PHOLDER) {
        return (React.createElement(Placeholder, { icon: "announcement", label: "Please select an item to show details" }));
    }
    return React.createElement(React.Fragment, null, children);
};
const DrawerDetail = ({ children, status, onReset, layout, layoutOnChange, backdropOpen, backdropOnClick, switchIconClassName, switchIconStyle, switchColor = getTheme().colors.theme1, }) => {
    const switchOnClick = React.useCallback(() => {
        layoutOnChange(layout === SheetLayout.HIDE ? SheetLayout.OPEN : SheetLayout.HIDE);
    }, [layout, layoutOnChange]);
    return (React.createElement(Drawer, { zIndex: getTheme().zIndex.drawer, style: { backgroundColor: getTheme().colors.background }, onReset: onReset, backdropOpen: backdropOpen, backdropOnClick: backdropOnClick, open: layout !== SheetLayout.HIDE, width: PANEL_DETAIL_WIDTH, widthClose: PANEL_DETAIL_WIDTH_CLOSE, border: "left", borderShadow: false, switchIconClassName: switchIconClassName, switchIconStyle: switchIconStyle, switchHide: noSwitch.has(layout) || !!backdropOpen, switchColor: switchColor, switchOnClick: switchOnClick, switchDisabled: backdropOpen },
        React.createElement(BorderShadow, { position: "top", border: false }),
        React.createElement(DetailChildren, { status: status, children: children })));
};
export default DrawerDetail;
