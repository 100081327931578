import * as React from "react";
import { useAsyncAbortable } from "react-async-hook";
import useConstant from "use-constant";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { FileType, SearchMode } from "../../interfaces";
import { SEARCH_FILES_RESULT_DEFAULT, } from "../../api/fetchesApiMediaLibrary";
import { AREA } from "../AreaMediaLibrary/constants";
import { anonymousSearchFiles, } from "../../api/fetchesApiAnonymous";
import { areFiltersUseful } from "../PanelFilters";
import { getQuery } from "../AreaProducts/useSearchEs";
const getSearchEs = ({ sortKey, sortOrd, filters, filtersCondition, paginationCurr, paginationSize, }) => ({
    sort: [{ [sortKey]: { order: sortOrd, unmapped_type: "keyword" } }],
    size: paginationSize,
    from: paginationSize * (paginationCurr - 1),
    query: getQuery({
        filters,
        filtersCondition,
        conditionsAdditional: [{ bool: { must: { term: { deleted: false } } } }],
    }),
});
export const searchFiles = async ({ type, hrch, prnt, sortKey, sortOrd, filters, filtersCondition, paginationCurr = 1, paginationSize = 1000, }) => {
    const conf = {
        area: AREA.SHAREPAGE,
        hrch,
        prnt,
        searchMode: areFiltersUseful(filters)
            ? SearchMode.HIERARCHY
            : SearchMode.DEFAULT,
        searchEsFolder: { from: 0, size: 0 },
        searchEsMedia: { from: 0, size: 0 },
    };
    if (!type || type === FileType.FOLDER) {
        conf.searchEsFolder = getSearchEs({
            sortKey,
            sortOrd,
            filters,
            filtersCondition,
            paginationCurr,
            paginationSize,
        });
    }
    if (!type || type === FileType.MEDIA) {
        conf.searchEsMedia = getSearchEs({
            sortKey,
            sortOrd,
            filters,
            filtersCondition,
            paginationCurr,
            paginationSize,
        });
    }
    const res = await anonymousSearchFiles(conf);
    return res;
};
const useSearchEs = (a) => {
    const [searchTime, setSearchTime] = React.useState(null);
    const debounceSearchDocs = useConstant(() => AwesomeDebouncePromise(searchFiles, 300));
    const searchRes = useAsyncAbortable(async (abortSignal, argDocs) => {
        if (argDocs === null)
            return SEARCH_FILES_RESULT_DEFAULT;
        return debounceSearchDocs(a);
    }, [searchTime]);
    return {
        searchTime,
        setSearchTime,
        searchRes,
    };
};
export default useSearchEs;
