import * as React from "react";
import getAggregations from "../../../utils/getAggregations";
import reducer, { reducerInitState, ACTION } from "./reducer";
import { Initialize, missingKey } from "@warda/library-ui/interfaces";
import { isMultCatalogLanguage, FilterTypeSingleSelection, getFilterAggregation, } from "../utils";
import { FilterSelect } from "@warda/library-ui/core/Filter";
import { getTheme } from "@warda/library-ui/theme";
const FilterSearchStrings = (p) => {
    const { id, label, labelSub, type, value = [], onChange, onClickCount, searchEs, className, collapsed, collapsedHelp, multiCatalog, multiLanguage, slcCtlg, slcLang, mandatory, missingLabel = "No value", optionsCount, searchable = true, } = p;
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, options, optionsMore, inputValue } = state;
    const onSearch = React.useCallback((inputValue) => {
        dispatch({ type: ACTION.SET_INPUT, inputValue });
    }, []);
    const filterAggregations = React.useMemo(() => [getFilterAggregation(p)], [p]);
    React.useEffect(() => {
        (async () => {
            if (isMultCatalogLanguage({
                multiCatalog,
                multiLanguage,
                ctlgId: slcCtlg,
                langId: slcLang,
            }) &&
                initialize === Initialize.START) {
                dispatch({ type: ACTION.INIT_LOADING });
                let options = reducerInitState.options;
                let optionsMore = reducerInitState.optionsMore;
                try {
                    const [{ items, sum_other_doc_count }] = await getAggregations({
                        include: inputValue ? `.*${inputValue}.*` : undefined,
                        searchEs,
                        filterAggregations,
                        slcCtlg: slcCtlg,
                        slcLang: slcLang,
                    });
                    options = items
                        .filter(({ key }) => {
                        return !!key;
                    })
                        .sort((a, b) => {
                        const aKey = a.key;
                        const bKey = b.key;
                        if (a.key === missingKey)
                            return -1;
                        if (aKey > bKey)
                            return 1;
                        if (aKey < bKey)
                            return -1;
                        return 0;
                    })
                        .map(({ key, doc_count }) => {
                        return {
                            id: key,
                            label: key === missingKey ? missingLabel : key,
                            labelStyle: { color: getTheme().colors.typography },
                            count: optionsCount ? doc_count : undefined,
                        };
                    });
                    optionsMore = sum_other_doc_count;
                }
                catch (err) {
                    console.log({ id: filterAggregations[0].id, err });
                }
                dispatch({ type: ACTION.INIT_SUCC, options, optionsMore });
            }
        })();
    }, [
        filterAggregations,
        initialize,
        inputValue,
        missingLabel,
        multiCatalog,
        multiLanguage,
        optionsCount,
        searchEs,
        slcCtlg,
        slcLang,
    ]);
    return (React.createElement(FilterSelect, { id: id, type: type, label: label, labelSub: labelSub, onChange: onChange, onSearch: searchable ? onSearch : undefined, searchable: searchable, onClickCount: onClickCount, options: options, optionsMore: optionsMore, value: value.map((v) => v.id), maxItems: type === FilterTypeSingleSelection ? 1 : undefined, className: className, collapsed: collapsed, collapsedHelp: collapsedHelp, mandatory: mandatory, loadingSearch: initialize === Initialize.LOADING }));
};
export default FilterSearchStrings;
