import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Badge from "@warda/library-ui/core/Badge";
import { SharedRole } from "../../../interfaces";
import isEmpty from "lodash-es/isEmpty";
import { ORGANIZATION_ID } from "../constants";
const getShares = (sharedWith) => ({
    sharesPrivate: sharedWith.filter(({ id }) => id !== ORGANIZATION_ID),
    sharesOrganiz: sharedWith.filter(({ id }) => id === ORGANIZATION_ID),
});
export const getLabel = (sharedWith) => {
    const { sharesPrivate, sharesOrganiz } = getShares(sharedWith);
    if (!isEmpty(sharesOrganiz)) {
        const role = sharesOrganiz[0].role;
        if (role === SharedRole.EDITOR) {
            return "All users can find and edit this item";
        }
        if (role === SharedRole.VIEWER) {
            return "All users can find and view this item";
        }
        if (role === SharedRole.VARIES) {
            return "Multiple settings applied";
        }
    }
    if (!isEmpty(sharesPrivate)) {
        return sharesPrivate.length === 1
            ? "The share is limited at a specific user"
            : `The share is limited at ${sharesPrivate.length} specific users`;
    }
    return "Only the owner can find and edit this item";
};
const getIcon = (sharedWith) => {
    const { sharesPrivate, sharesOrganiz } = getShares(sharedWith);
    if (!isEmpty(sharesOrganiz))
        return "business";
    if (!isEmpty(sharesPrivate))
        return "people_alt";
    return "lock";
};
const BadgeSharedWith = ({ sharedWith, className, style, tooltip, onClick, }) => (React.createElement(Badge, { className: className, style: style, color: getTheme().colors.theme2, icon: getIcon(sharedWith), tooltip: tooltip, onClick: onClick }));
export default BadgeSharedWith;
