import { ArchiverStatus } from "../../interfaces";
import { SHAREPAGE_DA } from "../../constants";
import { ACT_VPORT } from "./reducer";
import { NotificationType, } from "@warda/library-ui/interfaces";
const downloadSet = new Set([
    NotificationType.EXPORT,
    NotificationType.ARCHIVING,
]);
const wsCallbackDownloads = (itemsWs, dispatch) => {
    itemsWs
        .filter((c) => downloadSet.has(c.notificationType))
        .forEach(({ payload }) => {
        if (payload.status === ArchiverStatus.STARTED)
            dispatch({ type: ACT_VPORT.DOWNLOADS_UPDATE, payload });
        if (payload.status === ArchiverStatus.COMPLETED)
            dispatch({ type: ACT_VPORT.DOWNLOADS_FINISH, payload });
    });
};
const wsCallbacks = [
    {
        id: `${SHAREPAGE_DA}_archiver`,
        callback: wsCallbackDownloads,
    },
];
export default wsCallbacks;
