import * as React from "react";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import DialogDrawer from "./DialogDrawer";
import DialogFullscreen from "./DialogFullscreen";
import DialogModal from "./DialogModal";
const Dialog = ({ className, style, open, fullscreen, loading, title, buttons, onClose, children, }) => {
    if (fullscreen && isMobile() === false) {
        return (React.createElement(DialogDrawer, { className: className, style: style, open: open, loading: loading, title: title, buttons: buttons, onClose: onClose, children: children }));
    }
    if (fullscreen && isMobile() === true) {
        return (React.createElement(DialogFullscreen, { className: className, style: style, open: open, loading: loading, title: title, buttons: buttons, onClose: onClose, children: children }));
    }
    return (React.createElement(DialogModal, { className: className, style: style, open: open, loading: loading, title: title, buttons: buttons, onClose: onClose, children: children }));
};
export default Dialog;
