import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import Toolbar from "@warda/library-ui/core/Toolbar";
import { ACTION } from "./reducer";
import Btn from "@warda/library-ui/core/Btn";
const WindowToolbar = ({ dispatch, title, actions }) => {
    const onDeleteAll = React.useCallback(() => {
        dispatch({ type: ACTION.JOB_DELETE_ALL, toFetch: true });
    }, [dispatch]);
    return (React.createElement(Toolbar, { style: { padding: "0 10px 0 15px" } },
        React.createElement(Text, { size: 2, weight: "bolder", children: title }),
        React.createElement("div", { style: { flex: 1 } }),
        !actions ? null : (React.createElement(Btn, { icon: "playlist_remove", tooltip: "Clear listing", onClick: onDeleteAll }))));
};
export default WindowToolbar;
