import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import WindowChangelog from "./WindowChangelog";
const BtnWindowChangelog = ({ title }) => {
    const menuRef = React.useRef(null);
    const [menu, setMenu] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenu(true);
    }, []);
    const onMenuClose = React.useCallback(() => {
        setMenu(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { cmpRef: menuRef, tooltip: "What's new", icon: "info", selected: menu, onClick: onMenuOpen }),
        React.createElement(WindowChangelog, { title: title, anchorEl: menuRef.current, open: menu, onClose: onMenuClose })));
};
export default BtnWindowChangelog;
