import * as React from "react";
import { AREA_PRODUCTS, AREA_PRODUCTS_ATTRIBUTES, AREA_CATALOGS, AREA_FILES, AREA_CATEGORIES, AREA_M2MS, AREA_CONFIG, AREA_COMPLETENESS, AREA_USAGESTATS, AREA_STAGING, AREA_MEDIA_LIBRARY, AREA_MEDIA_METADATA, AREA_USER_MNG_SC, AREA_USER_MNG_PORTAL, } from "../constants";
import PlaceholderMain from "./PlaceholderMain";
import PlaceholderViewport from "./PlaceholderViewport";
const AreaProducts = React.lazy(() => import("./AreaProducts"));
const AreaMediaLibrary = React.lazy(() => import("./AreaMediaLibrary"));
const AreaMediaMetadata = React.lazy(() => import("./AreaMediaMetadata"));
const AreaStaging = React.lazy(() => import("./AreaMediaLibrary/AreaStaging"));
const AreaFiles = React.lazy(() => import("./AreaFiles_DEPRECATED"));
const AreaProductsAttributes = React.lazy(() => import("./AreaProductsAttributes"));
const AreaCatalogs = React.lazy(() => import("./AreaCatalogs"));
const AreaCategories = React.lazy(() => import("./AreaCategories"));
const AreaM2ms = React.lazy(() => import("./AreaM2ms"));
const AreaSelfService = React.lazy(() => import("./AreaSelfService"));
const AreaCompleteness = React.lazy(() => import("./AreaCompleteness"));
const AreaUsageStats = React.lazy(() => import("./AreaUsageStats"));
const AreaUsersMngSc = React.lazy(() => import("./AreaUsersMngSc"));
const AreaUsersMngPortal = React.lazy(() => import("./AreaUsersMngPortal"));
const MainSectionContent = ({ viewId }) => {
    switch (viewId) {
        case AREA_PRODUCTS:
            return React.createElement(AreaProducts, null);
        case AREA_STAGING:
            return React.createElement(AreaStaging, null);
        case AREA_MEDIA_LIBRARY:
            return React.createElement(AreaMediaLibrary, null);
        case AREA_FILES:
            return React.createElement(AreaFiles, null);
        case AREA_PRODUCTS_ATTRIBUTES:
            return React.createElement(AreaProductsAttributes, null);
        case AREA_CATALOGS:
            return React.createElement(AreaCatalogs, null);
        case AREA_CATEGORIES:
            return React.createElement(AreaCategories, null);
        case AREA_M2MS:
            return React.createElement(AreaM2ms, null);
        case AREA_CONFIG:
            return React.createElement(AreaSelfService, null);
        case AREA_COMPLETENESS:
            return React.createElement(AreaCompleteness, null);
        case AREA_USAGESTATS:
            return React.createElement(AreaUsageStats, null);
        case AREA_MEDIA_METADATA:
            return React.createElement(AreaMediaMetadata, null);
        case AREA_USER_MNG_SC:
            return React.createElement(AreaUsersMngSc, null);
        case AREA_USER_MNG_PORTAL:
            return React.createElement(AreaUsersMngPortal, null);
        default:
            return React.createElement(PlaceholderMain, null);
    }
};
const MainSection = (a) => (React.createElement(React.Suspense, { fallback: React.createElement(PlaceholderViewport, null) },
    React.createElement(MainSectionContent, { ...a })));
export default MainSection;
