import { ACTION } from "./reducer";
import { getNotificationsId } from "../WindowNotifDrc/websocketCallbacks";
const websocketUpdateJob = async (itemsWs, dispatch) => {
    const { idsJob: job } = getNotificationsId(itemsWs);
    if (!!job.length)
        dispatch({ type: ACTION.JOB_NEWITEMS_TO_GET, ids: job });
};
const wsCallbacks = [
    {
        id: "notificationsJob",
        callback: websocketUpdateJob,
    },
];
export default wsCallbacks;
