import { FileType, } from "../../../interfaces";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { ACT_VPORT } from "../reducer";
import getFileIcon from "../../Viewer/getFileIcon";
export const itemsTypeSort = [FileType.FOLDER, FileType.MEDIA];
export const getFileTypeText = (type) => {
    switch (type) {
        case FileType.TOUCHPOINT:
            return "Touchpoints";
        case FileType.FOLDER:
            return "Folders";
        case FileType.MEDIA:
            return "Media";
        default:
            return "Undefined";
    }
};
export const needFooter = (files, filesTotal) => {
    return {
        [FileType.FOLDER]: needPagination({
            type: FileType.FOLDER,
            files,
            filesTotal,
        }).pagination
            ? 1
            : 0,
        [FileType.MEDIA]: needPagination({
            type: FileType.MEDIA,
            files,
            filesTotal,
        }).pagination
            ? 1
            : 0,
    };
};
export const needPagination = ({ type, files, filesTotal, }) => {
    const countTotal = filesTotal[type];
    const countFiles = files.filter((f) => f.type === type).length;
    return {
        countTotal,
        countFiles,
        pagination: countFiles < countTotal,
    };
};
export const needHeader = {
    [FileType.FOLDER]: 1,
    [FileType.MEDIA]: 1,
};
const getAssetCheckbox = (inSelection) => {
    if (isMobile()) {
        return inSelection ? "always" : "none";
    }
    else {
        inSelection ? "mousehover" : "none";
    }
};
const getContextmenu = ({ onDownload, }) => {
    const listitems = [];
    if (isMobile())
        return [];
    if (!!onDownload) {
        listitems.push({
            id: "download",
            icon: "file_download",
            label: "Download",
            onClick: onDownload,
        });
    }
    return listitems;
};
export const getAssetPropsFolder = ({ dispatchViewport, color, id, data, selected, selectedIds, onContextMenu: cbOnContextMenu, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, }) => {
    const { name } = data;
    const inSelection = !!selectedIds.length;
    const onNavigate = () => {
        dispatchViewport({ type: ACT_VPORT.NAVIGATION, id, name });
    };
    const onContextMenu = (e, open) => {
        if (!selected) {
            dispatchViewport({
                type: ACT_VPORT.SELECT_FILE_CLICK,
                id,
                keyDownCtrl: false,
                keyDownMeta: false,
            });
        }
        cbOnContextMenu(e, open);
    };
    const onClick = (e, { keyDownCtrl, keyDownMeta }) => {
        if (isMobile()) {
            if (inSelection) {
                dispatchViewport({
                    type: ACT_VPORT.SELECT_FILE_CHECK,
                    id,
                    keyDownCtrl: true,
                    keyDownMeta,
                });
            }
            else {
                onNavigate();
            }
        }
        else {
            dispatchViewport({
                type: ACT_VPORT.SELECT_FILE_CLICK,
                id,
                keyDownCtrl,
                keyDownMeta,
            });
        }
    };
    const onDoubleClick = () => {
        if (isMobile()) {
            //
        }
        else {
            onNavigate();
        }
    };
    const onCheck = (e, { keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CHECK,
            id,
            keyDownCtrl: true,
            keyDownMeta,
        });
    };
    const onLongPress = () => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CHECK,
            id,
            keyDownCtrl: false,
            keyDownMeta: false,
        });
    };
    const contextmenu = getContextmenu({
        onDownload: () => {
            dispatchViewport({ type: ACT_VPORT.DIALOG_DOWNLOAD, fileId: id });
        },
    });
    const checkbox = getAssetCheckbox(inSelection);
    return {
        icon: "folder",
        label: name,
        color,
        selected,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        onContextMenu,
        onClick,
        onDoubleClick,
        onCheck,
        onLongPress,
        contextmenu,
        checkbox,
    };
};
export const getAssetPropsMedia = ({ dispatchViewport, color, id, data, selected, selectedIds, onContextMenu: cbOnContextMenu, contextmenuOriginAnchor, contextmenuOriginTransf, contextmenuPosizionZone, }) => {
    const { name, mimeType } = data;
    const icon = getFileIcon(mimeType);
    const inSelection = !!selectedIds.length;
    const onContextMenu = (e, open) => {
        if (!selected) {
            dispatchViewport({
                type: ACT_VPORT.SELECT_FILE_CLICK,
                id,
                keyDownCtrl: false,
                keyDownMeta: false,
            });
        }
        cbOnContextMenu(e, open);
    };
    const onClick = (e, { keyDownCtrl, keyDownMeta }) => {
        if (isMobile() && inSelection) {
            dispatchViewport({
                type: ACT_VPORT.SELECT_FILE_CHECK,
                id,
                keyDownCtrl: true,
                keyDownMeta,
            });
        }
        else {
            dispatchViewport({
                type: ACT_VPORT.SELECT_FILE_CLICK,
                id,
                keyDownCtrl,
                keyDownMeta,
                detail: isMobile(),
            });
        }
    };
    const onCheck = (e, { keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CHECK,
            id,
            keyDownCtrl: true,
            keyDownMeta,
        });
    };
    const onDetail = () => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CLICK,
            id,
            keyDownCtrl: false,
            keyDownMeta: false,
            detail: true,
        });
    };
    const onLongPress = () => {
        dispatchViewport({
            type: ACT_VPORT.SELECT_FILE_CHECK,
            id,
            keyDownCtrl: false,
            keyDownMeta: false,
        });
    };
    const contextmenu = getContextmenu({
        onDownload: () => {
            dispatchViewport({ type: ACT_VPORT.DIALOG_DOWNLOAD, fileId: id });
        },
    });
    const checkbox = getAssetCheckbox(inSelection);
    return {
        icon,
        label: name,
        color,
        selected,
        contextmenuOriginAnchor,
        contextmenuOriginTransf,
        contextmenuPosizionZone,
        onContextMenu,
        onClick,
        onDoubleClick: onDetail,
        onCheck,
        onLongPress,
        contextmenu,
        checkbox,
    };
};
