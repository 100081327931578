import { Initialize } from "@warda/library-ui/interfaces";
import { FileType, SearchMode, } from "../interfaces";
import apiUrls from "./apiUrlsMediaLibrary";
import { fetchCookieJwtWithRefreshToken } from "./fetchCookieJwt";
import { signUrl } from "./fetchesApi";
import getMimeType from "../components/Uploads/utils/getMimeType";
import getErrorMsg from "@warda/library-ui/utils/getErrorMsg";
import last from "@warda/library-ui/utils/last";
import { AREA, AREA_TEXTS } from "../components/AreaMediaLibrary/constants";
import PERMS from "../permissions";
import hasPerms from "../utils/hasPerms";
import getIdsFile from "../components/AreaMediaLibrary/AreaFiles/utils/getIdsFile";
import { normalizeElasticSrc } from "../utils/elasticsearch";
import { ERROR_GENERIC } from "../constants";
export const createMyMediaLibrary = async () => {
    const { url, method } = apiUrls.createMyMediaLibrary;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
    });
    return res;
};
const composeFolderPathStart = (area) => {
    switch (area) {
        case AREA.TOUCHPOINT:
            return [
                {
                    id: AREA.TOUCHPOINTS_LIST,
                    name: AREA_TEXTS[AREA.TOUCHPOINTS_LIST],
                },
            ];
        case AREA.SHARED_WITH_ME:
            return [
                {
                    id: AREA.SHARED_WITH_ME,
                    name: AREA_TEXTS[AREA.SHARED_WITH_ME],
                },
            ];
        default:
            return [];
    }
};
const composeFolderData = (hrchOwners, items) => {
    var _a;
    try {
        const data = (_a = last(items)) === null || _a === void 0 ? void 0 : _a.data;
        return {
            ...data,
            owner: hrchOwners[data.hierarchyId] || data.owner,
        };
    }
    catch {
        return undefined;
    }
};
export const composeFolderPath = (area, items) => {
    const path = composeFolderPathStart(area);
    items.map((o) => {
        path.push({
            id: o.id,
            name: o.data.name,
        });
    });
    return path;
};
const mergeAggs = (oldAggs, newAggs) => {
    const aggregations = Array.from(oldAggs);
    newAggs.forEach((a) => {
        const agg = aggregations.find((agg) => agg.id === a.id);
        if (!!agg) {
            a.items.forEach((b) => {
                const aggItem = agg.items.find((aggItem) => aggItem.key === b.key);
                if (!!aggItem) {
                    aggItem.doc_count = aggItem.doc_count + b.doc_count;
                }
                else {
                    agg.items.push(b);
                }
            });
            agg.sum_other_doc_count = agg.sum_other_doc_count + a.sum_other_doc_count;
        }
        else {
            const agg = {
                id: a.id,
                items: a.items,
                sum_other_doc_count: a.sum_other_doc_count,
            };
            aggregations.push(agg);
        }
    });
    return aggregations;
};
export const SEARCH_FILES_RESULT_DEFAULT = {
    initSearch: Initialize.LOADING,
    folderData: undefined,
    folderPath: [],
    files: [],
    aggregations: [],
    filesTotal: {
        [FileType.FOLDER]: 0,
        [FileType.MEDIA]: 0,
        [FileType.TOUCHPOINT]: 0,
    },
    accessInherited: [],
};
export const SEARCH_FILES_RESULT_ERROR = {
    ...SEARCH_FILES_RESULT_DEFAULT,
    initSearch: Initialize.FAIL,
};
export const normalizeMediaLibrary = (area, res) => {
    var _a, _b, _c, _d;
    const error = ((_b = (_a = res.folder) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.root_cause[0].reason) ||
        ((_d = (_c = res.media) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.root_cause[0].reason);
    if (error) {
        return {
            ...SEARCH_FILES_RESULT_ERROR,
            error,
        };
    }
    const hrchOwners = res.hierarchiesOwners || {};
    const normM = normalizeElasticSrc(res.media);
    const normF = normalizeElasticSrc(res.folder);
    const normLocation = normalizeElasticSrc(res.folderLocation);
    const accessInherited = res.ownAndInheritedACL || [];
    const files = [];
    const filesTotal = {
        [FileType.FOLDER]: normF.itemsTotal,
        [FileType.MEDIA]: normM.itemsTotal,
        [FileType.TOUCHPOINT]: 0,
    };
    normF.items.map((o) => {
        const file = {
            id: o.id,
            type: FileType.FOLDER,
            data: {
                ...o.data,
                owner: hrchOwners[o.data.hierarchyId] || o.data.owner,
            },
        };
        files.push(file);
    });
    normM.items.map((o) => {
        const file = {
            id: o.id,
            type: FileType.MEDIA,
            data: {
                ...o.data,
                owner: hrchOwners[o.data.hierarchyId] || o.data.owner,
            },
        };
        files.push(file);
    });
    const folderData = composeFolderData(hrchOwners, normLocation.items);
    const folderPath = composeFolderPath(area, normLocation.items);
    if (!!(folderData === null || folderData === void 0 ? void 0 : folderData.deleted)) {
        return {
            initSearch: Initialize.FAIL,
            error: "deleted",
            folderData,
            folderPath,
            files,
            filesTotal,
            aggregations: [],
            accessInherited,
        };
    }
    const aggregations = [
        normM.aggregations,
        normF.aggregations,
    ].reduce(mergeAggs, []);
    return {
        initSearch: Initialize.SUCC,
        folderData,
        folderPath,
        files,
        filesTotal,
        aggregations,
        accessInherited,
    };
};
const searchFiles = async ({ area, hrch, prnt, searchMode, searchEsFolder, searchEsMedia, }) => {
    const url = apiUrls.getFiles.url(area);
    const method = apiUrls.getFiles.method;
    if (!url)
        throw "Area not defined";
    const payload = {
        searchMode,
        folder: searchEsFolder,
        media: searchEsMedia,
        parentFolder: undefined,
        hierarchyId: undefined,
    };
    if (searchMode === SearchMode.DEFAULT && !!hrch) {
        payload.parentFolder = {
            hierarchyId: hrch,
            folderId: prnt,
        };
    }
    if (searchMode === SearchMode.HIERARCHY) {
        payload.hierarchyId = hrch;
    }
    const res = await fetchCookieJwtWithRefreshToken({
        url,
        method,
        jsonBody: payload,
    });
    return normalizeMediaLibrary(area, res);
};
export const getFiles = async (permissions, conf) => {
    if (conf.area === AREA.TOUCHPOINTS_LIST) {
        return await getTouchpointList(permissions, conf);
    }
    try {
        const res = await searchFiles(conf);
        return res;
    }
    catch (err) {
        const err404 = err.status === 404;
        const err400 = err.status === 400;
        if (err404 && !conf.prnt && !conf.hrch && conf.area === AREA.MYFILES) {
            // primo accesso alla sezione privata - creo la mia cartella
            try {
                const folderData = await createMyMediaLibrary();
                return {
                    ...SEARCH_FILES_RESULT_ERROR,
                    initSearch: Initialize.SUCC,
                    folderData,
                    folderPath: composeFolderPathStart(conf.area),
                };
            }
            catch (err2) {
                const errMsg2 = await getErrorMsg(err2);
                return { ...SEARCH_FILES_RESULT_ERROR, error: errMsg2 };
            }
        }
        let errMsg = ERROR_GENERIC;
        if (err404) {
            errMsg =
                conf.area === AREA.TOUCHPOINT ? "Access denied" : "Directory not found";
        }
        if (err400) {
            errMsg = "Invalid query parameters";
        }
        return { ...SEARCH_FILES_RESULT_ERROR, error: errMsg };
    }
};
export const getTouchpointList = async (permissions, conf) => {
    try {
        const isManager = hasPerms([PERMS.medialibrary_touchpoint_manager], permissions);
        const res = await fetchCookieJwtWithRefreshToken({
            url: apiUrls.getFiles.url(conf.area),
            method: isManager ? "POST" : "GET",
            jsonBody: conf.searchEsTouch,
        });
        if (res.error) {
            return {
                ...SEARCH_FILES_RESULT_ERROR,
                error: res.error,
            };
        }
        const items = res.hits.hits.map((a) => ({
            id: a._id,
            type: FileType.TOUCHPOINT,
            data: a._source,
        }));
        return {
            initSearch: Initialize.SUCC,
            folderData: undefined,
            folderPath: [],
            files: items,
            filesTotal: {
                [FileType.FOLDER]: 0,
                [FileType.MEDIA]: 0,
                [FileType.TOUCHPOINT]: items.length,
            },
            aggregations: [],
            accessInherited: [],
        };
    }
    catch (err) {
        const errMsg = await getErrorMsg(err);
        return { ...SEARCH_FILES_RESULT_ERROR, error: errMsg };
    }
};
export const createFolders = async ({ hrch, prnt, tree, }) => {
    const { url, method } = apiUrls.createFolder;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            hierarchyId: hrch,
            containerFolderId: prnt,
            tree,
        },
    });
    return res;
};
export const createTouchpoint = async (value) => {
    const { url, method } = apiUrls.createTouchpoint;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            touchpointName: value,
        },
    });
    return res;
};
export const deleteMedia = async (fileId) => {
    const { url, method } = apiUrls.deleteMedia;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(fileId),
        method,
    });
    return res;
};
export const deleteFiles = async ({ hrch, idsFolder, idsMedia, }) => {
    if (idsMedia.length === 1 && idsFolder.length === 0) {
        const res = await deleteMedia(idsMedia[0]);
        return res;
    }
    else {
        const { url, method } = apiUrls.deleteFiles;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                hierarchyId: hrch,
                fileIds: idsMedia,
                folderIds: idsFolder,
            },
        });
        return res;
    }
};
export const mcrConfirm = async (fileId) => {
    const { url, method } = apiUrls.uploadConfirm;
    await fetchCookieJwtWithRefreshToken({
        url: url(fileId),
        method,
    });
};
export const getSignedUrl = () => async (ifile) => {
    const { hrch, prnt, file: { type, name }, } = ifile;
    const { url, method } = apiUrls.uploadNew;
    return await signUrl({
        url: url(),
        method,
        filename: name,
        mimeType: getMimeType(name, type),
        metadata: {
            location: {
                hierarchyId: hrch,
                folderId: prnt,
            },
            target: "MEDIA_LIBRARY",
        },
        deferTenantSpecificRenditions: true,
    });
};
export const getFilesByDocumentId = async ({ area, hrch, ids, search = "all", }) => {
    const { url, method } = apiUrls.getFiles;
    const res = await fetchCookieJwtWithRefreshToken({
        url: url(area),
        method,
        jsonBody: {
            hierarchyId: hrch,
            searchMode: SearchMode.HIERARCHY,
            folder: search === "all" || search === "folder"
                ? { query: { bool: { must: { terms: { _id: ids } } } } }
                : undefined,
            media: search === "all" || search === "media"
                ? { query: { bool: { must: { terms: { _id: ids } } } } }
                : undefined,
        },
    });
    const files = [];
    res.folder.hits.hits.map((o) => {
        const file = {
            id: o._id,
            type: FileType.FOLDER,
            data: o._source,
        };
        files.push(file);
    });
    res.media.hits.hits.map((o) => {
        const file = {
            id: o._id,
            type: FileType.MEDIA,
            data: o._source,
        };
        files.push(file);
    });
    return files;
};
export const getFilePath = async (area, file) => {
    const url = apiUrls.getFiles.url(area);
    const method = apiUrls.getFiles.method;
    const hrch = file.data.hierarchyId;
    const prnt = file.data.parentFolderId;
    const res = await fetchCookieJwtWithRefreshToken({
        url,
        method,
        jsonBody: {
            searchMode: SearchMode.DEFAULT,
            parentFolder: {
                hierarchyId: hrch,
                folderId: prnt,
            },
            folder: { from: 0, size: 0 },
            media: { from: 0, size: 0 },
        },
    });
    const normLocation = normalizeElasticSrc(res.folderLocation);
    const folderPath = composeFolderPath(area, normLocation.items);
    const accessInherited = res.ownAndInheritedACL || [];
    return { folderPath, accessInherited };
};
export const publicationMedia = async ({ fileId, toCreate, }) => {
    const { url } = apiUrls.publication;
    await fetchCookieJwtWithRefreshToken({
        url: url(fileId),
        method: toCreate ? "POST" : "DELETE",
    });
};
export const setSharepage = async ({ hrch, folderId, activate, }) => {
    const { url, method } = apiUrls.publicShare;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            hierarchyId: hrch,
            folderId,
            activate,
        },
    });
};
export const downloadMassiveML = async ({ correlationId, connectionId, hrch, idsMedia, idsFolder, }) => {
    if (!connectionId) {
        return { success: false, message: "Missing 'websocketId'" };
    }
    if (!correlationId) {
        return { success: false, message: "Missing 'correlationId'" };
    }
    try {
        const { url, method } = apiUrls.downloadMassive;
        const message = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                correlationId,
                connectionUUID: connectionId,
                hierarchyId: hrch,
                folders: idsFolder,
                files: idsMedia,
            },
        });
        return { success: true, message };
    }
    catch (err) {
        const message = await getErrorMsg(err);
        return { success: false, message };
    }
};
export const moveFiles = async ({ files, filesSlcId, destinationHrch, destinationPrnt, }) => {
    const { idsFolder, idsMedia } = getIdsFile(files, filesSlcId);
    const { url, method } = apiUrls.moveMedia;
    await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody: {
            hierarchyId: destinationHrch,
            folder: !idsFolder.length
                ? undefined
                : {
                    destinationFolder: destinationPrnt,
                    folderIds: idsFolder,
                },
            media: !idsMedia.length
                ? undefined
                : {
                    destinationFolder: destinationPrnt,
                    fileIds: idsMedia,
                },
        },
    });
};
export const updateMediaMetedataSet = async ({ idsMedia, setsToAdding, setsToRemove, }) => {
    if (idsMedia.length === 1) {
        const { url, method } = apiUrls.updateMediaMetedataSet;
        await fetchCookieJwtWithRefreshToken({
            url: url(idsMedia[0]),
            method,
            jsonBody: {
                setsToAdd: setsToAdding,
                setsToRemove: setsToRemove,
            },
        });
    }
    else {
        const { url, method } = apiUrls.updateMediaMetedataSetMassive;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                setsToAdd: setsToAdding,
                setsToRemove: setsToRemove,
                filesId: idsMedia,
            },
        });
    }
};
export const updateMediaMetadata = async ({ idsMedia, data, }) => {
    const { url, method } = apiUrls.updateMediaMetedata;
    if (idsMedia.length > 1)
        throw "Not implemented";
    await fetchCookieJwtWithRefreshToken({
        url: url(idsMedia[0]),
        method,
        jsonBody: data,
    });
};
export const accessFiles = async ({ hrch, accesses }) => {
    const accessesFolder = [];
    const accessesMedia = [];
    const accessesTouch = [];
    accesses.forEach((f) => {
        if (f.type === FileType.FOLDER) {
            accessesFolder.push({
                folderId: Number(f.id),
                accesses: f.accesses,
            });
        }
        if (f.type === FileType.MEDIA) {
            accessesMedia.push({
                fileId: f.id,
                accesses: f.accesses,
            });
        }
        if (f.type === FileType.TOUCHPOINT) {
            accessesTouch.push({
                hierarchyId: f.id,
                accesses: f.accesses,
            });
        }
    });
    if (!!accessesFolder.length || !!accessesMedia.length) {
        const { url, method } = apiUrls.accessFiles;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                filesAccess: accessesMedia,
                hierarchyAccess: !accessesFolder.length
                    ? undefined
                    : {
                        hierarchyId: hrch,
                        foldersAccess: accessesFolder,
                    },
            },
        });
    }
    if (accessesTouch.length === 1) {
        const { url, method } = apiUrls.accessTouch;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                hierarchyId: accessesTouch[0].hierarchyId,
                accesses: accessesTouch[0].accesses,
            },
        });
    }
    else {
        console.log("not implemented multi touchpoint");
    }
};
export const renameFile = async ({ hrch, files, filesSlcId, newName, }) => {
    const { idsFolder, idsMedia } = getIdsFile(files, filesSlcId);
    if (idsFolder.length + idsMedia.length > 1)
        throw "Not implemented";
    if (!!idsMedia.length) {
        const { url, method } = apiUrls.renameMedia;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                newName,
                fileId: idsMedia[0],
            },
        });
    }
    if (!!idsFolder.length) {
        const { url, method } = apiUrls.renameFolder;
        await fetchCookieJwtWithRefreshToken({
            url: url(),
            method,
            jsonBody: {
                newName,
                folderId: idsFolder[0],
                hierarchyId: hrch,
            },
        });
    }
};
