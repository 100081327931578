export var ACTION;
(function (ACTION) {
    ACTION["RESET"] = "RESET";
    ACTION["CHECKIN"] = "CHECKIN";
    ACTION["UPDATE_SUCC"] = "UPDATE_SUCC";
    ACTION["UPDATE_FAIL"] = "UPDATE_FAIL";
})(ACTION || (ACTION = {}));
export const reducerInitState = {
    checkin: false,
    updateTime: 0,
    itemsTotal: 0,
    itemsCount: 0,
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.CHECKIN:
            newState.checkin = true;
            return newState;
        case ACTION.UPDATE_FAIL:
            newState.updateTime = new Date().getTime();
            newState.checkin = false;
            return newState;
        case ACTION.UPDATE_SUCC:
            newState.updateTime = new Date().getTime();
            newState.itemsTotal = action.total;
            newState.itemsCount = action.count;
            newState.checkin = false;
            return newState;
        case ACTION.RESET:
            return reducerInitState;
        default:
            return state;
    }
};
