import * as React from "react";
import AssetFolder from "../AssetFolder_DEPRECATED";
import { ACT_VPORT } from "../reducer";
import BadgeGetLink from "../Content/BadgeGetLink";
import { useHistory } from "react-router-dom";
import { getUpdatedPath } from "../getUpdatedPath";
import { CtxDispatchViewport } from "../contexts";
export const getCellHeight = () => 50;
export const getCellHeader = (total) => `Folders (${total})`;
const CellFolder = ({ data, color, selected, disabled, cutted, contextmenu, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const history = useHistory();
    const { id, name, sharedWith } = data;
    const onClick = React.useCallback((p, { keyDownCtrl, keyDownMeta }) => {
        dispatchViewport({
            type: ACT_VPORT.ITEMS_SELECT,
            itemId: id,
            keyDownCtrl,
            keyDownMeta,
        });
    }, [dispatchViewport, id]);
    const onDoubleClick = React.useCallback(() => {
        history.push(getUpdatedPath(id));
    }, [history, id]);
    const onContextMenu = React.useCallback(() => {
        if (!selected) {
            dispatchViewport({ type: ACT_VPORT.ITEMS_SELECT, itemId: id });
        }
    }, [dispatchViewport, id, selected]);
    return (React.createElement(AssetFolder, { assetHeight: 45, assetWidth: 160, style: { borderStyle: cutted ? "dashed" : "solid" }, icon: !sharedWith.length ? "folder" : "folder_shared", label: name, color: color, selected: selected, disabled: disabled, onClick: onClick, onDoubleClick: onDoubleClick, onContextMenu: onContextMenu, contextmenu: contextmenu },
        React.createElement(BadgeGetLink, { data: data, style: { marginRight: 6 } })));
};
export default CellFolder;
